import { useEffect, useRef, useState } from 'react'
import { SketchPicker } from 'react-color'

interface Props {
  value: string
  onChange: (value: string) => void
}

export default function ColorPicker(props: Props) {
  const { value, onChange } = props

  const [isColorPickerOpen, setColorPickerOpen] = useState<boolean>(false)
  const colorPickerRef = useRef<any>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (colorPickerRef.current && !colorPickerRef.current.contains(event.target as Node)) {
      setColorPickerOpen(false)
    }
  }

  useEffect(() => {
    if (isColorPickerOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isColorPickerOpen])

  return (
    <div style={{ position: 'relative' }}>
      {isColorPickerOpen && (
        <div
          ref={colorPickerRef}
          style={{
            position: 'absolute',
            top: '50%',
            left: 'calc(100% + 8px)',
            transform: 'translateY(-50%)',
            zIndex: 1000,
          }}
        >
          <SketchPicker
            color={value}
            onChangeComplete={(value) => onChange(value.hex)}
            ref={colorPickerRef}
          />
        </div>
      )}

      <div
        style={{
          backgroundColor: value,
          borderRadius: '8px',
          width: '28px',
          height: '28px',
          cursor: 'pointer',
        }}
        onClick={() => setColorPickerOpen(true)}
      />
    </div>
  )
}
