import { CircularProgress } from '@mui/material'
import { useEffect, useRef } from 'react'

interface Props {
  htmlContent: string
  isLoading: boolean
  onClickField: (key: string) => void
}

export default function TemplateHtmlContent(props: Props) {
  const { htmlContent, isLoading, onClickField } = props

  const iframeRef = useRef<HTMLIFrameElement>(null)

  const highlightFields = () => {
    const iframeDocument = iframeRef.current?.contentDocument
    if (!iframeDocument || isLoading) {
      return
    }

    const elements = iframeDocument.querySelectorAll('[data-field]')
    elements.forEach((el: any) => {
      const fieldName = el.dataset.field
      const index = el.dataset.index

      el.style.position = 'relative'
      el.style.border = '2px solid #023b21'
      el.style.borderRadius = '8px'
      el.style.padding = '4px'
      el.style.cursor = 'pointer'
      el.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)'
      el.style.transition = 'all 0.3s ease'

      el.addEventListener('mouseenter', () => {
        el.style.border = '2px solid #02231c'
        el.style.backgroundColor = 'rgba(2, 59, 33, 0.1)'
      })

      el.addEventListener('mouseleave', () => {
        el.style.border = '2px solid #023b21'
        el.style.backgroundColor = 'transparent'
      })

      el.addEventListener('click', () => {
        onClickField(typeof index === 'undefined' ? fieldName : `${fieldName}[${index}]`)
      })
    })
  }

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.onload = () => {
        highlightFields()
      }
      highlightFields()
    }
  }, [htmlContent, isLoading])

  return (
    <div>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10,
          }}
        >
          <CircularProgress size='60px' style={{ color: '#023b21' }} />
        </div>
      )}

      <iframe
        srcDoc={htmlContent}
        ref={iframeRef}
        style={{
          width: '100%',
          maxWidth: '1000px',
          height: '100vh',
          border: '1px solid #ccc',
          ...(isLoading
            ? {
                opacity: 0.5,
                userSelect: 'none',
                pointerEvents: 'none',
              }
            : {}),
        }}
      ></iframe>
    </div>
  )
}
