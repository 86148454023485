import { useState } from 'react'
import { FloatingSelects } from '../../../../components/FloatingSelects'

interface Props {
  dateSelected: {
    month: number
    year: number
  }
  value: number
  onChange: (dueDate: number) => void
}

export default function DueDateSelect(props: Props) {
  const { dateSelected, value, onChange } = props

  const [dueDay, setDueDay] = useState<number>(value)

  const daysInMonth = new Date(dateSelected.year, dateSelected.month, 0).getDate()
  const dayFilterArray = [...new Array(daysInMonth)].map((_, index) => `${index + 1}`)

  return (
    <FloatingSelects
      isFilterActive={!!value}
      label='Dia de Vencimento'
      submitAction={() => {
        onChange(dueDay)
      }}
      selects={[
        {
          value: dueDay.toString(),
          setValue: (value) => setDueDay(Number(value)),
          datas: [
            {
              label: 'Nenhum',
              value: '0',
            },
            ...dayFilterArray.map((day) => ({
              label: day,
              value: day,
            })),
          ],
        },
      ]}
    />
  )
}
