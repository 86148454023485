import { Autocomplete, TextareaAutosize } from "@mui/material";
import styled from "styled-components";

export const Textarea = styled(TextareaAutosize)`
  resize: vertical;
  min-height: 225px;

  padding: 8px;
  
  font-family: inherit;
  font-size: 16px;
  
  background: #f1f1f1;
  border: 1px solid #f1f1f1;
  border-radius: 8px;

  transition: border-color 0.25s;

  &:hover {
    border-color: #000;
  }

  &:focus {
    border-color: #1976d2;
  }
`

export const ClientSelect = styled(Autocomplete)`
  background: #f1f1f1;
`
