import { Stack } from '@mui/material'
import Modal from '../../../../components/ModalV2'
import { Button } from '../../../../components/Button'
import ErrorIcon from '@mui/icons-material/Error';
import { ErrorHeader, ErrorText } from './styles';

interface OverdueModalProps {
  onClose: () => void
}

export const OverdueModal = ({ onClose }: OverdueModalProps) => {
  return (
    <Modal.Provider
      isOpen
      maxWidth={650}
      style={{ width: "100%" }}
      onClose={() => onClose()}
    >
      <Modal.Title>
        <ErrorIcon
          sx={{ fontSize: 36 }}
          color='error'
        />
        <ErrorHeader>
          Aviso importante: Inadimplência
        </ErrorHeader>
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      <Modal.Body>
        <Stack gap={2}>
          <Stack direction={"column"} width='100%' gap={2} paddingX={2} paddingY={1}>
            <ErrorText>
              O acesso à sua conta está temporariamente suspenso devido a pendências financeiras.
            </ErrorText>
            <ErrorText>
              Por favor, regularize sua situação para restabelecer o acesso. Para mais informações, entre em contato com nossa equipe de suporte.
            </ErrorText>
            <Stack direction={'row'} mt={3} width={'100%'} justifyContent={'flex-end'}>
              <Stack direction={'row'} width={'50%'}>
                <Button
                  text='Entendi'
                  onClick={() => onClose()}
                />
              </Stack>
            </Stack>
            
          </Stack>
        </Stack>
      </Modal.Body>
    </Modal.Provider>
  )
}
