import moment from 'moment'
import { ITemplate, ITests } from './interface'

export function formatPrice(data: string, isEdit?: boolean) {
  let valor = data
  if (!data.includes('.') && !isNaN(parseFloat(data)) && isEdit) {
    valor = `${data}.00`
  }

  if (valor === '') return ''

  valor = valor.replace(/\D/g, '')

  const parteInteira = valor.slice(0, -2)
  const parteDecimal = valor.slice(-2)

  const parteInteiraFormatada = parteInteira.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')

  const valorFormatado = `${parteInteiraFormatada},${parteDecimal}`

  return `R$ ${valorFormatado}`
}

export function formatString(val: any) {
  if (val) return String(val)
  return '0'
}

export function maxValue(value: string, max: number) {
  return value.slice(0, max)
}

export function formatDate(data: string, separator = '/') {
  if (data === '') return ''

  data = data.replace(/\D/g, '')

  if (data.length > 2) {
    data = data.slice(0, 2) + separator + data.slice(2)
  }
  if (data.length > 5) {
    data = data.slice(0, 5) + separator + data.slice(5, 9)
  }

  return data
}

export function formatDateRef(data: string) {
  data = data.replace(/\D/g, '')

  // Insere a barra (/) no lugar apropriado
  if (data.length > 2) {
    data = data.slice(0, 2) + '/' + data.slice(2)
  }

  return data
}

export function formatAcumulation(valor: string) {
  if (valor === '') return ''

  valor = valor.replace(/\D/g, '')

  valor = valor + ' kWh'

  return valor
}

export function calculateTar(
  tar: string | number,
  icms: string | number,
  cofins: string | number,
  pis: string | number,
) {
  try {
    const tarFormated = parseFloat(String(tar).replace(',', '.'))
    const icmsFormated = icms ? parseFloat(String(icms).replace(',', '.')) / 100 : 0
    const cofinsFormated = parseFloat(String(cofins).replace(',', '.')) / 100
    const pisFormated = parseFloat(String(pis).replace(',', '.')) / 100

    let calc = tarFormated / (pisFormated + cofinsFormated - 1) / (icmsFormated - 1)

    if (!icms) {
      calc = -(tarFormated / (pisFormated + cofinsFormated - 1))
    }

    return !isNaN(calc) ? `${String(calc).replace('.', ',').slice(0, 12)}` : '0'
  } catch {
    return '0'
  }
}

export function calculateVal(value: string, tar: string) {
  try {
    const valueFormated = parseFloat(value.replace(',', '.'))
    const tarFormated = parseFloat(tar.replace(',', '.'))

    const calc = valueFormated * tarFormated

    return !isNaN(calc) ? `${String(calc).replace('.', ',').slice(0, 12)}` : '0'
  } catch {
    return '0'
  }
}

export function transformDate(date2: string) {
  try {
    const partes = date2.split('/')
    const dia = partes[0].padStart(2, '0')
    const mes = partes[1].padStart(2, '0')
    const ano = partes[2]
    const date = `${dia}/${mes}/${ano}`
    const dateFormated = date.split('/').reverse()

    return moment(dateFormated.join('-')).format('YYYY-MM-DD')
  } catch {
    return ''
  }
}

export function formatNum(data: string): number {
  const value = String(data)
  try {
    if (value.includes('R$')) {
      return !isNaN(parseFloat(value.replaceAll('.', '').replace(',', '.').replace('R$ ', '')))
        ? parseFloat(value.replaceAll('.', '').replace(',', '.').replace('R$ ', ''))
        : 0
    }
    return !isNaN(parseFloat(String(value).replace(',', '.').replace('kWh', '')))
      ? parseFloat(String(value).replace(',', '.').replace('kWh', ''))
      : 0
  } catch {
    return 0
  }
}

export function sendFormat(data: ITemplate, extra?: any) {
  const valor_total_fatura = formatNum(data.valor_total_fatura)
  const consumo_spt_te_tar_crua = formatNum(data.consumo_spt_te_tar)
  const consumo_spt_tusd_tar_crua = formatNum(data.consumo_spt_tusd_tar)
  const consumo_spt_qt = formatNum(data.consumo_spt_qt)
  const tot_trib_pis_aliq = formatNum(data.tot_trib_pis_aliq)
  const tot_trib_icms_aliq = formatNum(data.tot_trib_icms_aliq)
  const tot_trib_cofins_aliq = formatNum(data.tot_trib_cofins_aliq)
  const cosip = formatNum(data.cosip)
  const creditos_estoque_spt = formatNum(data.creditos_estoque_spt)
  const energia_comp_spt_qt = formatNum(data.energia_comp_spt_qt)
  const energia_comp_spt_qt_opt = formatNum(data.energia_comp_spt_qt_opt)

  const te = formatNum(data.ouc_te)
  const tusd = formatNum(data.ouc_tusd)
  const te_opt = formatNum(data.ouc_te_opt)
  const tusd_opt = formatNum(data.ouc_tusd_opt)
  const energia_comp_spt_tar = te + tusd
  const energia_comp_spt_tar_opt = te_opt + tusd_opt
  const consumo_spt_te_tar = formatNum(
    calculateTar(
      data.consumo_spt_te_tar,
      data.tot_trib_icms_aliq,
      data.tot_trib_cofins_aliq,
      data.tot_trib_pis_aliq,
    ),
  )
  const consumo_spt_tusd_tar = formatNum(
    calculateTar(
      data.consumo_spt_tusd_tar,
      data.tot_trib_icms_aliq,
      data.tot_trib_cofins_aliq,
      data.tot_trib_pis_aliq,
    ),
  )

  const consumo_spt_tar = consumo_spt_te_tar + consumo_spt_tusd_tar
  const consumo_spt_val = consumo_spt_tar * consumo_spt_qt
  const energia_comp_spt_crua = consumo_spt_te_tar_crua + consumo_spt_tusd_tar_crua

  const energia_comp_spt_val = te * energia_comp_spt_qt + tusd * energia_comp_spt_qt
  const energia_comp_spt_val_opt =
    te_opt * energia_comp_spt_qt_opt + tusd_opt * energia_comp_spt_qt_opt

  let energia_comp_spt_qt_num = energia_comp_spt_qt + energia_comp_spt_qt_opt
  let energia_comp_spt_val_num = energia_comp_spt_val_opt + energia_comp_spt_val

  if (extra) {
    for (const e of extra) {
      energia_comp_spt_qt_num += formatNum(e.energia_comp_spt_qt)
      energia_comp_spt_val_num += formatNum(calculateVal(e.energia_comp_spt_qt, e.te))
      energia_comp_spt_val_num += formatNum(calculateVal(e.energia_comp_spt_qt, e.tusd))
    }
  }

  const allData = {
    id: data.id,
    nome: data.nome,
    fases: data.fases,
    grupo: data.grupo,
    subgrupo: data.subgrupo,
    classe: data.classe,
    modalidade: data.modalidade,
    doc: data.doc,
    endereco: data.endereco,
    cep: data.cep,
    cidade: data.cidade,
    uf: data.uf,
    uc: data.uc,
    data_vencimento: transformDate(data.data_vencimento),
    mes_referencia: transformDate(`01/${data.mes_referencia}`),
    data_emissao: transformDate(data.data_emissao),
    data_leitura_anterior: transformDate(data.data_leitura_anterior),
    data_leitura_atual: transformDate(data.data_leitura_atual),
    data_proxima_leitura: transformDate(data.data_proxima_leitura),
    dias_faturados: formatNum(data.dias_faturados),
    consumo_spt_tar_crua: energia_comp_spt_crua,
    consumo_spt_te_tar_crua,
    consumo_spt_tusd_tar_crua,
    consumo_spt_te_tar,
    consumo_spt_tusd_tar,
    valor_total_fatura,
    consumo_spt_qt,
    consumo_total_faturado_qt: consumo_spt_qt,
    tot_trib_icms_aliq,
    tot_trib_cofins_aliq,
    tot_trib_pis_aliq,
    cosip,
    creditos_estoque_spt,
    creditos_estoque_tot: creditos_estoque_spt,
    energia_comp_spt_qt: energia_comp_spt_qt_num,
    energia_comp_total_qt: energia_comp_spt_qt_num,
    energia_comp_spt_tar: energia_comp_spt_tar_opt || energia_comp_spt_tar,
    energia_comp_total_tar: energia_comp_spt_tar_opt || energia_comp_spt_tar,
    consumo_spt_tar,
    consumo_total_faturado_tar: consumo_spt_tar,
    consumo_spt_val,
    consumo_total_faturado_val: consumo_spt_val,
    energia_comp_spt_crua,
    energia_comp_spt_val: energia_comp_spt_val_num,
    energia_comp_total_val: energia_comp_spt_val_num,
    med_ger_tot_quant:
      formatNum(data.med_ger_spt_quant) +
      formatNum(data.med_ger_p_quant) +
      formatNum(data.med_ger_fp_quant),
    med_ger_spt_quant: formatNum(data.med_ger_spt_quant),
    med_ger_p_quant: formatNum(data.med_ger_p_quant),
    med_ger_fp_quant: formatNum(data.med_ger_fp_quant),
  }

  return allData
}

export function sendFormatEnergisa(data: ITemplate, extra?: any) {
  const valor_total_fatura = formatNum(data.valor_total_fatura)

  const consumo_spt_qt = formatNum(data.consumo_spt_qt)
  const tot_trib_pis_aliq = formatNum(data.tot_trib_pis_aliq)
  const tot_trib_icms_aliq = formatNum(data.tot_trib_icms_aliq)
  const tot_trib_cofins_aliq = formatNum(data.tot_trib_cofins_aliq)
  const cosip = formatNum(data.cosip)
  const creditos_estoque_spt = formatNum(data.creditos_estoque_spt)
  const energia_comp_spt_qt = formatNum(data.energia_comp_spt_qt)

  const energia_comp_spt_tar = formatNum(data.energia_comp_spt_tar) - formatNum(data.ajusteGD_tar)

  const consumo_spt_tar = formatNum(
    calculateTar(
      data.consumo_spt_tar_crua,
      data.tot_trib_icms_aliq,
      data.tot_trib_cofins_aliq,
      data.tot_trib_pis_aliq,
    ),
  )

  const consumo_spt_val = consumo_spt_tar * consumo_spt_qt
  const energia_comp_spt_crua = formatNum(data.consumo_spt_tar_crua)

  const energia_comp_spt_val = Number(energia_comp_spt_tar) * Number(energia_comp_spt_qt)

  let energia_comp_spt_qt_num = energia_comp_spt_qt
  let energia_comp_spt_val_num = energia_comp_spt_val

  if (extra) {
    for (const e of extra) {
      energia_comp_spt_qt_num += formatNum(e.energia_comp_spt_qt)
      energia_comp_spt_val_num += formatNum(
        calculateVal(e.energia_comp_spt_qt, e.energia_comp_spt_tar),
      )
    }
  }

  const allData = {
    id: data.id,
    nome: data.nome,
    fases: data.fases,
    grupo: data.grupo,
    subgrupo: data.subgrupo,
    classe: data.classe,
    modalidade: data.modalidade,
    doc: data.doc,
    endereco: data.endereco,
    cep: data.cep,
    cidade: data.cidade,
    uf: data.uf,
    uc: data.uc,
    data_vencimento: transformDate(data.data_vencimento),
    mes_referencia: transformDate(`01/${data.mes_referencia}`),
    data_emissao: transformDate(data.data_emissao),
    data_leitura_anterior: transformDate(data.data_leitura_anterior),
    data_leitura_atual: transformDate(data.data_leitura_atual),
    data_proxima_leitura: transformDate(data.data_proxima_leitura),
    dias_faturados: formatNum(data.dias_faturados),
    consumo_spt_tar_crua: energia_comp_spt_crua,
    valor_total_fatura,
    consumo_spt_qt,
    consumo_total_faturado_qt: consumo_spt_qt,
    tot_trib_icms_aliq,
    tot_trib_cofins_aliq,
    tot_trib_pis_aliq,
    cosip,
    creditos_estoque_spt,
    creditos_estoque_tot: +creditos_estoque_spt,
    energia_comp_spt_qt: +energia_comp_spt_qt_num,
    energia_comp_total_qt: +energia_comp_spt_qt_num,
    energia_comp_spt_tar: +energia_comp_spt_tar,
    energia_comp_total_tar: +energia_comp_spt_tar,
    consumo_spt_tar,
    consumo_total_faturado_tar: +consumo_spt_tar,
    consumo_spt_val,
    consumo_total_faturado_val: +consumo_spt_val,
    energia_comp_spt_crua,
    energia_comp_spt_val: +energia_comp_spt_val_num,
    energia_comp_total_val: +energia_comp_spt_val_num,
    med_ger_tot_quant:
      formatNum(data.med_ger_spt_quant) +
      formatNum(data.med_ger_p_quant) +
      formatNum(data.med_ger_fp_quant),
    med_ger_spt_quant: formatNum(data.med_ger_spt_quant),
    med_ger_p_quant: formatNum(data.med_ger_p_quant),
    med_ger_fp_quant: formatNum(data.med_ger_fp_quant),
  }

  return allData
}

export function calculateDefault(val: ITests) {
  let formData = val

  if (formData.data_leitura_atual.length > 9 && formData.data_leitura_anterior.length > 9) {
    const data1 = moment(transformDate(formData.data_leitura_atual))
    const data2 = moment(transformDate(formData.data_leitura_anterior))

    formData.dias_faturados = data1.diff(data2, 'days')
  }

  if (formData.consumo_spt_tar_crua && formatNum(String(formData.consumo_spt_tar_crua)) !== 0) {
    if (formData.consumo_spt_qt !== 0)
      formData.consumo_spt_tar_crua = formatNum(String(formData.consumo_spt_tar_crua))
    if (formData.consumo_p_qt !== 0)
      formData.consumo_p_tar_crua = formatNum(String(formData.consumo_spt_tar_crua))
    if (formData.consumo_fp_qt !== 0)
      formData.consumo_fp_tar_crua = formatNum(String(formData.consumo_spt_tar_crua))
  } else {
    if (formData.consumo_spt_qt !== 0)
      formData.consumo_spt_tar_crua =
        formatNum(String(formData.consumo_spt_tusd_tar_crua)) +
        formatNum(String(formData.consumo_spt_te_tar_crua))
    if (formData.consumo_p_qt !== 0)
      formData.consumo_p_tar_crua =
        formatNum(String(formData.consumo_spt_tusd_tar_crua)) +
        formatNum(String(formData.consumo_spt_te_tar_crua))
    if (formData.consumo_fp_qt !== 0)
      formData.consumo_fp_tar_crua =
        formatNum(String(formData.consumo_spt_tusd_tar_crua)) +
        formatNum(String(formData.consumo_spt_te_tar_crua))
  }

  formData.energia_comp_spt_crua =
    formatNum(String(formData.consumo_spt_tusd_tar_crua)) +
    formatNum(String(formData.consumo_spt_te_tar_crua))

  formData.grupo = formData.subgrupo?.toLowerCase().includes('b') ? 'B' : 'A'

  formData.consumo_spt_tar = formatNum(
    calculateTar(
      formData.consumo_spt_tar_crua,
      formData.tot_trib_icms_aliq,
      formData.tot_trib_cofins_aliq,
      formData.tot_trib_pis_aliq,
    ),
  )
  formData.consumo_p_tar =
    formData.consumo_p_tar !== 0
      ? formData.consumo_p_tar
      : formatNum(
          calculateTar(
            formData.consumo_p_tar_crua,
            formData.tot_trib_icms_aliq,
            formData.tot_trib_cofins_aliq,
            formData.tot_trib_pis_aliq,
          ),
        )
  formData.consumo_fp_tar =
    formData.consumo_fp_tar !== 0
      ? formData.consumo_fp_tar
      : formatNum(
          calculateTar(
            formData.consumo_fp_tar_crua,
            formData.tot_trib_icms_aliq,
            formData.tot_trib_cofins_aliq,
            formData.tot_trib_pis_aliq,
          ),
        )

  formData.consumo_spt_te_tar = formatNum(
    calculateTar(
      formData.consumo_spt_te_tar_crua,
      formData.tot_trib_icms_aliq,
      formData.tot_trib_cofins_aliq,
      formData.tot_trib_pis_aliq,
    ),
  )

  formData.consumo_spt_tusd_tar = formatNum(
    calculateTar(
      formData.consumo_spt_tusd_tar_crua,
      formData.tot_trib_icms_aliq,
      formData.tot_trib_cofins_aliq,
      formData.tot_trib_pis_aliq,
    ),
  )

  formData.consumo_int_val = Number(formData.consumo_int_qt) * Number(formData.consumo_int_tar) 

  formData.energia_comp_int_val = Number(formData.energia_comp_int_qt) * Number(formData.energia_comp_int_tar)

  formData.energia_comp_fp_val = Number(formData.energia_comp_fp_tar) * Number(formData.energia_comp_fp_qt)
  formData.energia_comp_p_val = Number(formData.energia_comp_p_tar) * Number(formData.energia_comp_p_qt)
  formData.energia_comp_spt_val = Number(formData.energia_comp_spt_tar) * Number(formData.energia_comp_spt_qt)

  formData.consumo_spt_val = Number(formData.consumo_spt_qt) * Number(formData.consumo_spt_tar)
  formData.consumo_fp_val = Number(formData.consumo_fp_qt) * Number(formData.consumo_fp_tar)
  formData.consumo_p_val = Number(formData.consumo_p_qt) * Number(formData.consumo_p_tar) 

  formData.creditos_estoque_tot =
    Number(formData.creditos_estoque_spt) + Number(formData.creditos_estoque_fp) + Number(formData.creditos_estoque_p)

  formData.consumo_total_faturado_val =
    +formData.consumo_p_val +
    +formData.consumo_fp_val +
    +formData.consumo_spt_val +
    +formData.consumo_int_val

  formData.consumo_total_faturado_qt =
    /* eslint-disable */
    !!formData.consumo_total_faturado_qt ?
      +formData.consumo_total_faturado_qt :
      +formData.consumo_p_qt +
      +formData.consumo_fp_qt +
      +formData.consumo_spt_qt +
      +formData.consumo_int_qt

  formData.consumo_total_faturado_tar =
    +formData.consumo_p_tar +
    +formData.consumo_fp_tar +
    +formData.consumo_spt_tar +
    +formData.consumo_int_tar

  formData.energia_comp_total_qt =
    +formData.energia_comp_fp_qt +
    +formData.energia_comp_p_qt +
    +formData.energia_comp_spt_qt +
    +formData.energia_comp_int_qt

  formData.energia_comp_total_val =
    +formData.energia_comp_fp_val +
    +formData.energia_comp_p_val +
    +formData.energia_comp_spt_val +
    +formData.energia_comp_int_val

  formData.energia_comp_total_tar =
    +formData.energia_comp_fp_tar +
    +formData.energia_comp_p_tar +
    +formData.energia_comp_spt_tar +
    +formData.energia_comp_int_tar

  formData = {
    ...formData,
    data_vencimento: transformDate(formData.data_vencimento),
    mes_referencia: transformDate(
      formData.mes_referencia.length > 9
        ? formData.mes_referencia
        : `01/${formData.mes_referencia}`,
    ),
    data_emissao: transformDate(formData.data_emissao),
    data_leitura_anterior: transformDate(formData.data_leitura_anterior),
    data_leitura_atual: transformDate(formData.data_leitura_atual),
    data_proxima_leitura: transformDate(formData.data_proxima_leitura),
    consumo_spt_te_tar_crua: formatNum(String(formData.consumo_spt_te_tar_crua)),
    consumo_spt_tusd_tar_crua: formatNum(String(formData.consumo_spt_tusd_tar_crua)),
    med_ger_spt_quant: formatNum(String(formData.med_ger_spt_quant)),
    med_ger_tot_quant: formatNum(String(formData.med_ger_tot_quant)),
    med_ger_p_quant: formatNum(String(formData.med_ger_p_quant)),
    med_ger_fp_quant: formatNum(String(formData.med_ger_fp_quant)),
  }

  return formData
}
