import {
  Button,
  Buttons,
  Container,
  FileContainer,
  FilesList,
  FileStatusMessage,
  FilesWrapper,
  HeaderButton,
  Infos,
  Return,
  UploadContainer,
  Wrapper,
} from './style'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded'
import { CircularProgress } from '@mui/material'
import { Theme } from '../../../styles/theme'
import { useEffect, useState } from 'react'
import ArrowBack from '@mui/icons-material/ArrowBack'
import ReplayIcon from '@mui/icons-material/Replay';
import EditIcon from '@mui/icons-material/Edit';
import { useToast } from '../../../hooks/useToast'
import { useDropzone } from 'react-dropzone'
import { checkStatusInvoicesOpenAI, persistInvoicesOpenAI, uploadInvoicesOpenAI } from '../../../services/requests/user-requests'
import { UploadFile } from '../../../services/requests/types'
import { v4 } from 'uuid'
import { EditCopelOpenAIModal } from './components/edit-modal'

const MAX_FILE_COUNT = 25

interface ReportCopelOpenAIReaderProps {
  setPage: (value: string) => void
}

export function ReportCopelOpenAIReader({ setPage }: ReportCopelOpenAIReaderProps) {
  const { toast } = useToast()

  const [isExtractLoading, setIsExtractLoading] = useState(false)
  const [isCheckStatusLoading, setIsCheckStatusLoading] = useState(false)
  const [isPersistLoading, setIsPersistLoading] = useState(false)
  const [editInvoiceId, setEditInvoiceId] = useState<string | null>(null)

  const [files, setFiles] = useState<UploadFile[]>([])
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'text/html': ['.pdf'],
    },
    onDrop: (items: File[]) => {
      const pdfs = items.filter(
        (item) => item?.name?.toLowerCase()?.endsWith('.pdf')
          && !files.find(fi => fi.file.size === item.size && item.name === fi.file.name)
      )

      if (pdfs.length > MAX_FILE_COUNT) {
        toast({
          message: `O limite de arquivos para upload é de ${MAX_FILE_COUNT}`,
          type: 'warning'
        })

        return
      }

      const newPdfs = pdfs.map(pdf => ({
        id: v4(),
        file: pdf,
        status: 'DEFAULT'
      }))

      setFiles(previous => ([
        ...previous,
        ...newPdfs
      ]))
    },
  })

  const handleCheckStatus = async () => {
    if (!files?.length) {
      toast({
        type: 'warning',
        message: 'Nenhum PDF encontrado, faça a leitura antes de atualizar os status',
      })

      return
    }

    try {
      const invoicesToCheck = files.filter(file => file.pollingId?.length)

      if (invoicesToCheck?.length === 0) {
        toast({
          type: 'warning',
          message: 'Nenhum PDF foi extraido, faça a extração de dados antes de atualizar os status',
        })

        return
      }

      setIsCheckStatusLoading(true)

      const response = await checkStatusInvoicesOpenAI({
        invoices: invoicesToCheck,
        reader: 'COPEL',
        uploadOrigin: 'ADMIN'
      })

      setFiles(previous => {
        const newFiles = previous.map(item => {
          const updatedItem = response?.find(
            newFile => newFile?.id === item?.id
          )

          if (!updatedItem)
            return item

          const status = updatedItem?.status === 'FINISHED' ?
            'INFO' : updatedItem?.status

          return {
            id: item.id,
            file: item.file,
            data: updatedItem?.data,
            error: updatedItem?.error,
            status,
            message: updatedItem?.message,
            pollingId: updatedItem?.pollingId,
          } as UploadFile
        })

        return newFiles
      })
    } catch (err) {
      const error = err as Error
      const message = error?.message ?? "Não foi possivel atualizar os status das faturas"

      toast({
        type: 'error',
        message
      })
    } finally {
      setIsCheckStatusLoading(false)
    }
  }

  const handleExtractSingleInvoice = async (index: number) => {
    if (!files?.at(index)) {
      toast({
        type: 'warning',
        message: 'PDF não encontrado para fazer a releitura',
      })

      return
    }

    try {
      const invoiceToCheck = files?.at(index)

      if (!invoiceToCheck) {
        toast({
          type: 'warning',
          message: 'PDF não foi encontrado',
        })

        return
      }

      toast({
        type: 'info',
        message: 'A extração dos dados toma em torno de 1 minuto para finalizar.'
      })

      setIsExtractLoading(true)

      const response = await uploadInvoicesOpenAI({
        invoices: [invoiceToCheck],
        reader: 'COPEL',
        uploadOrigin: 'ADMIN'
      })

      setFiles(previous => {
        const newFiles = previous.map(item => {
          const updatedItem = response?.find(
            newFile => newFile?.id === item?.id
          )

          if (!updatedItem)
            return item

          return {
            id: item.id,
            file: item.file,
            data: updatedItem?.data,
            error: updatedItem?.error,
            status: updatedItem?.status,
            message: updatedItem?.message,
            pollingId: updatedItem?.pollingId,
          } as UploadFile
        })

        return newFiles
      })


    } catch (err) {
      const error = err as Error
      const message = error?.message ?? "Não foi possivel extrair os dados das faturas"

      toast({
        type: 'error',
        message
      })
    } finally {
      setIsExtractLoading(false)
    }
  }

  const handleExtractData = async () => {
    if (!files?.length) {
      toast({
        type: 'warning',
        message: 'Insira pelo menos um PDF para a leitura',
      })

      return
    }

    try {
      const invoicesToCheck = files.filter(file => !file.pollingId?.length)

      if (invoicesToCheck?.length === 0) {
        toast({
          type: 'warning',
          message: 'Nenhum PDF novo foi inserido, selecione novos PDFs para extrair os dados',
        })

        return
      }

      toast({
        type: 'info',
        message: 'A extração dos dados toma em torno de 1 minuto para finalizar.'
      })

      setIsExtractLoading(true)

      const response = await uploadInvoicesOpenAI({
        invoices: files,
        reader: 'COPEL',
        uploadOrigin: 'ADMIN'
      })

      setFiles(previous => {
        const newFiles = previous.map(item => {
          const updatedItem = response?.find(
            newFile => newFile?.id === item?.id
          )

          if (!updatedItem)
            return item

          return {
            id: item.id,
            file: item.file,
            data: updatedItem?.data,
            error: updatedItem?.error,
            status: updatedItem?.status,
            message: updatedItem?.message,
            pollingId: updatedItem?.pollingId,
          } as UploadFile
        })

        return newFiles
      })


    } catch (err) {
      const error = err as Error
      const message = error?.message ?? "Não foi possivel extrair os dados das faturas"

      toast({
        type: 'error',
        message
      })
    } finally {
      setIsExtractLoading(false)
    }
  }

  const handlePersistInvoices = async () => {
    if (!files?.length) {
      toast({
        type: 'warning',
        message: 'Nenhum PDF encontrado, faça a leitura antes de cadastras as faturas',
      })

      return
    }

    try {
      const invoicesToPersist = files.filter(file => file.pollingId?.length)

      if (invoicesToPersist?.length === 0) {
        toast({
          type: 'warning',
          message: 'Nenhum PDF foi extraido, faça a extração de dados antes de persistir os dados',
        })

        return
      }

      setIsPersistLoading(true)

      const response = await persistInvoicesOpenAI({
        invoices: invoicesToPersist,
        reader: 'COPEL',
        uploadOrigin: 'ADMIN'
      })

      setFiles(previous => {
        const newFiles = previous.map(item => {
          const updatedItem = response?.find(
            newFile => newFile?.pollingId === item?.pollingId
          )

          if (!updatedItem)
            return item

          return {
            id: item.id,
            file: item.file,
            data: updatedItem?.data,
            error: updatedItem?.error,
            status: updatedItem?.status,
            message: updatedItem?.message,
            pollingId: updatedItem?.pollingId,
          } as UploadFile
        })

        return newFiles
      })
    } catch (err) {
      const error = err as Error
      const message = error?.message ?? "Não foi possivel cadastras as faturas"

      toast({
        type: 'error',
        message
      })
    } finally {
      setIsPersistLoading(false)
    }
  }

  const handleDeleteAll = () => {
    setFiles([])
  }

  const handleDelete = (index: number) => {
    setFiles(prev => {
      const items = [...prev]
      items.splice(index, 1)

      return items
    })
  }

  const handleResetNavigation = () => {
    handleDeleteAll()
    setPage('')
  }

  const handleOpenEditModal = (id?: string) => {
    if (!id?.length)
      return

    setEditInvoiceId(id)
  }

  const handleUpdateItem = (id: string, data: Record<string, any>) => {
    setFiles(previous => {
      const newArr = [...previous]
      const itemIdx = previous.findIndex(row => row.id === id)

      if (itemIdx < 0)
        return previous

      const newItem = newArr?.at(itemIdx)
      if (!newItem)
        return previous

      newItem.data = data
      newArr[itemIdx] = newItem

      return newArr
    })
  }
  const handleCloseEditModal = () => setEditInvoiceId(null)

  useEffect(() => {
    if (!files.length)
      return

    if (isExtractLoading || !files.every(file => file.pollingId?.length))
      return

    let interval: NodeJS.Timeout | undefined = undefined;

    interval = setInterval(
      async () => {
        await handleCheckStatus()

        clearInterval(interval as NodeJS.Timeout)
      },
      1000 * 60 // 1 minute
    )

    return () => clearInterval(interval)
  }, [isExtractLoading])

  const fileComponents = files?.map((row, index: number) => (
    <Wrapper key={index}>
      <FileContainer key={row.file.name}>
        <DescriptionRoundedIcon />
        <span>{row?.file?.name ?? "Arquivo"}</span>
      </FileContainer>
      {(() => {
        switch (row?.status) {
          case 'PENDING':
            return (
              <FileStatusMessage color={Theme.colors.white} bgColor={Theme.colors.grayDark}>
                Carregando...
              </FileStatusMessage>
            )
          case 'INFO': {
            const message = row?.message ?? "Carregando..."
            const isPending = message === 'Extraindo dados...'

            return (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '4px' }}>
                {!isPending && (
                  <>
                    <Button id='file-reload-btn' onClick={() => handleOpenEditModal(row.id)}>
                      <EditIcon />
                    </Button>

                    <Button id='file-reload-btn' onClick={() => handleExtractSingleInvoice(index)}>
                      <ReplayIcon />
                    </Button>
                  </>
                )}

                <FileStatusMessage color={Theme.colors.white} bgColor={Theme.colors.orange}>
                  {message}
                </FileStatusMessage>
              </div>
            )
          }
          case 'FINISHED':
            return (
              <FileStatusMessage color={Theme.colors.white} bgColor={Theme.colors.darkishGreen}>
                Sucesso.
              </FileStatusMessage>
            )
          case 'ERROR': {
            const message = row?.message ?? "Fatura em processamento"
            const isPending = message === 'Fatura em analise'

            return (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '4px' }}>
                <Button id='file-reload-btn' onClick={() => handleExtractSingleInvoice(index)}>
                  <ReplayIcon />
                </Button>

                <FileStatusMessage
                  color={Theme.colors.white}
                  bgColor={isPending ? Theme.colors.green : Theme.colors.suportError}
                >
                  {message}
                </FileStatusMessage>
              </div>

            )
          }

          default: {
            const hasDuplicate = files?.filter((item) => item?.file?.name === row?.file?.name)?.length > 1

            return hasDuplicate ? (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <FileStatusMessage color={Theme.colors.white} bgColor={Theme.colors.orange}>
                  Arquivo duplicado
                </FileStatusMessage>

                <Button id='file-delete-btn' onClick={() => handleDelete(index)}>
                  <DeleteRoundedIcon />
                </Button>
              </div>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <FileStatusMessage color={Theme.colors.white} bgColor={Theme.colors.darkGreen}>
                  {row?.file?.name}
                </FileStatusMessage>

                <Button id='file-delete-btn' onClick={() => handleDelete(index)}>
                  <DeleteRoundedIcon />
                </Button>
              </div>
            )
          }
        }
      })()}
    </Wrapper>
  ))

  return (
    <>
      {editInvoiceId?.length && (<EditCopelOpenAIModal updateItem={handleUpdateItem} items={files} itemId={editInvoiceId} onClose={handleCloseEditModal}  />)}

      <Container>
        <Infos>
          <Return onClick={handleResetNavigation}>
            <ArrowBack fontSize='small' />

            <p>Voltar</p>
          </Return>

          <Buttons>
            <HeaderButton disabled={isExtractLoading || isCheckStatusLoading} onClick={handleDeleteAll} style={{ gap: '5px' }}>
              Limpar arquivos
            </HeaderButton>

            <HeaderButton disabled={isExtractLoading} onClick={handleExtractData}>
              {isExtractLoading ? <CircularProgress size={16} /> : 'Extrair dados'}
            </HeaderButton>

            <HeaderButton disabled={isCheckStatusLoading} onClick={handleCheckStatus}>
              {isCheckStatusLoading ? <CircularProgress size={16} /> : 'Atualizar status'}
            </HeaderButton>

            <HeaderButton disabled={isPersistLoading} onClick={handlePersistInvoices}>
              {isPersistLoading ? <CircularProgress size={16} /> : 'Cadastrar'}
            </HeaderButton>
          </Buttons>
        </Infos>

        <FilesWrapper>
          <UploadContainer {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
            <input {...getInputProps()} />

            <p>Arraste o arquivo ou clique para fazer o upload</p>
          </UploadContainer>

          <FilesList>{fileComponents}</FilesList>
        </FilesWrapper>
      </Container>
    </>
  )
}
