import { ArrowBack } from '@mui/icons-material'
import { Button } from '../../../../../components/Button'
import Modal from '../../../../../components/ModalV2'
import { IconButton } from '@mui/material'
import { Dispatch, SetStateAction, useState } from 'react'
import InvoiceTemplateFields from '../TemplateFields'
import _ from 'lodash'
import { getNestedLevelAndObject } from '../../../../../utils/obj-utils'
import { StickyFooter } from '../../../InvoiceValidation/components/InvoiceModal/styles'
import { TemplateConfig } from '../../../../../services/payment-api'
import CreateTemplateFieldModal from '../CreateTemplateFieldModal'
import CustomerImageGalleryModal from '../CustomerImageGalleryModal'

interface Props {
  selectedFieldKey?: string
  setSelectedFieldKey: Dispatch<SetStateAction<string | undefined>>
  templateConfig: TemplateConfig
  onSave: (templateFields: Record<string, any>) => void
  onClose: () => void
}

export default function TemplateFieldModal(props: Props) {
  const {
    templateConfig,
    selectedFieldKey,
    setSelectedFieldKey,
    onSave,
    onClose,
  } = props

  const [temporaryFields, setTemporaryFields] = useState(templateConfig.configs)

  const [addNewFieldKey, setAddNewFieldKey] = useState<string>()
  const [isImageGalleryOpen, setImageGalleryOpen] = useState<boolean>(false)

  const setFieldValue = (key: string, value: unknown) => {
    setTemporaryFields((oldTemplateFields) => {
      const updatedTemplateFields = { ...oldTemplateFields }
      _.set(updatedTemplateFields, key, value)
      return updatedTemplateFields
    })
  }

  const saveChanges = () => {
    onSave(temporaryFields)
    onClose()
  }

  const nestedLevelInfo = selectedFieldKey
    ? getNestedLevelAndObject(templateConfig.configs, selectedFieldKey)
    : undefined

  const canGoBack = Boolean(nestedLevelInfo)

  const goBack = () => {
    if (nestedLevelInfo) {
      if (nestedLevelInfo.level === 1 && nestedLevelInfo.parentKey === selectedFieldKey) {
        setSelectedFieldKey('')
      } else {
        setSelectedFieldKey(nestedLevelInfo.parentKey)
      }
    }
  }

  if (isImageGalleryOpen) {
    return (
      <CustomerImageGalleryModal
        managerId={templateConfig.clientId}
        goBack={() => setImageGalleryOpen(false)}
        onClose={() => setImageGalleryOpen(false)}
      />
    )
  }

  if (typeof addNewFieldKey !== 'undefined') {
    return (
      <CreateTemplateFieldModal
        onCreate={(params) => {
          const { key, value } = params
          setFieldValue(addNewFieldKey ? `${addNewFieldKey}.${key}` : key, value)
        }}
        goBack={() => setAddNewFieldKey(undefined)}
        onClose={() => setAddNewFieldKey(undefined)}
      />
    )
  }

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        width: '100%',
        position: 'relative',
        height: '100%',
        maxHeight: '750px',
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        {canGoBack && (
          <IconButton style={{ marginRight: '8px' }} onClick={goBack}>
            <ArrowBack />
          </IconButton>
        )}
        Editar Campo
        <Modal.CloseButton alignLeft />
      </Modal.Title>
      <Modal.Body style={{ overflowY: 'auto' }}>
        <InvoiceTemplateFields
          openImageGalleryModal={() => setImageGalleryOpen(true)}
          activeItem={selectedFieldKey}
          setActiveItem={setSelectedFieldKey}
          setAddingNewItem={(key) => setAddNewFieldKey(key)}
          templateInfo={{
            ...templateConfig,
            templateConfig: temporaryFields,
          }}
          setFieldValue={setFieldValue}
        />
      </Modal.Body>
      <StickyFooter style={{ padding: '16px', marginTop: 'auto' }}>
        <Button containerStyle={{ margin: 0 }} text='Salvar Alterações' onClick={saveChanges} />
      </StickyFooter>
    </Modal.Provider>
  )
}
