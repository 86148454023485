import { Box, Grid, Skeleton, Stack } from '@mui/material'

export default function PreviewInvoiceSkeleton() {
  return (
    <Box>
      <Stack gap={0.5} marginBottom={0.5}>
        <Skeleton variant="rectangular" width='60%' height={40} />
        <Skeleton variant="rectangular" width='40%' height={20} />
      </Stack>

      <Box padding={2} bgcolor='#eee' borderRadius={2}>
        <Stack marginBottom={2}>
          <Skeleton width='30%' height={24} />
          <Skeleton width='25%' height={20} />
        </Stack>

        <Box display='flex' gap={2}>
          <Box width='20%' borderRadius={1} display='flex' flexDirection='column' gap={0.5}>
            {Array.from({ length: 5 }).map((_, index) => (
              <Box key={index}>
                <Skeleton width='60%' height={20} />
                <Skeleton width='100%' height={30} />
              </Box>
            ))}
          </Box>

          <Box
            width='80%'
            padding={2}
            bgcolor='#fff'
            borderRadius={2}
            display='flex'
            flexDirection='column'
          >
            <Box display='flex' flexWrap='wrap' gap={1} justifyContent='space-between'>
              {Array.from({ length: 6 }).map((_, index) => (
                <Box key={index} width='48%'>
                  {' '}
                  <Skeleton width='70%' height={20} />
                  <Skeleton width='100%' height={32} />
                </Box>
              ))}
            </Box>
            <Box marginTop={2}>
              <Skeleton variant='rounded' width='100%' height={40} />
            </Box>
          </Box>
        </Box>
      </Box>

      <Grid container spacing={2} marginTop={0.5}>
        <Grid item xs={12} md={4}>
          <Box marginBottom={1}>
            <Skeleton width='70%' height={24} />
          </Box>
          {Array.from({ length: 8 }).map((_, index) => (
            <Skeleton key={index} width='100%' height={30} />
          ))}
        </Grid>
        <Grid item xs={12} md={8}>
          <Skeleton variant='rectangular' width='100%' height={300} />
        </Grid>
      </Grid>

      <Grid container spacing={2} marginTop={3}>
        {Array.from({ length: 3 }).map((_, index) => (
          <Grid item xs={12} md={4} key={`cards-${index}`}>
            <Box
              padding={2}
              bgcolor='#fff'
              borderRadius={1}
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <Box>
                <Skeleton width='100px' height={18} />
                <Skeleton width='60px' height={24} />
              </Box>
              <Skeleton variant='circular' width={40} height={40} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
