import React, { ReactNode, useEffect, useState } from 'react'
import { FloatingFilterWrapper } from '../FloatingFilterWrapper'

import { Checkbox, Label, ListContainer, Select, SelectItem, Title } from './styles'
import { SearchInput } from '../SearchInput'
import { CheckboxType } from '../../interfaces/checkbox'
import { InputLabel } from '@mui/material'

interface Props {
  label?: string
  customActionComponent?: ReactNode
  checkbox?: boolean
  submitBtnText?: string
  title?: string
  searchable?: boolean
  pagination?: {
    selected: number
    setSelected: (selected: number) => void
    options: number[]
  }
  selectableAll?: boolean
  options?: CheckboxType[]
  isFilterActive: boolean
  styles?: React.CSSProperties

  submitAction?: (values: CheckboxType[]) => void
}

export const FloatingCheckboxes: React.FC<Props> = ({
  options,
  title,
  checkbox,
  searchable,
  pagination,
  isFilterActive,
  selectableAll,
  submitAction,
  ...rest
}) => {
  const [boxes, setBoxes] = useState(options ?? [])
  const [selectAll, setSelectAll] = useState(false)
  const [search, setSearch] = useState('')
  const [contentHeight, setContentHeight] = useState(0)

  useEffect(() => {
    setBoxes(options ?? [])
    const isNotSelectedAll = options?.find((item) => !item.checked)
    setSelectAll(!isNotSelectedAll)
  }, [options])

  const filteredBoxes =
    search.length > 0 ? boxes.filter(({ label }) => !!label.match(new RegExp(search, 'i'))) : []

  const handleCheck = (label: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    if (!checked) setSelectAll(false)

    const updatedBoxes = boxes.map((box) => (box.label === label ? { ...box, checked } : box))
    setBoxes(updatedBoxes)
  }

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    setSelectAll(checked)

    const updatedBoxes = boxes.map((box) => ({ ...box, checked }))
    setBoxes(updatedBoxes)
  }

  return (
    <FloatingFilterWrapper
      {...rest}
      checkbox={checkbox}
      isFilterActive={isFilterActive}
      submitAction={() => submitAction && submitAction(boxes)}
      setContentHeight={setContentHeight}
      header={
        <>
          {title && <Title>{title}</Title>}
          {searchable && (
            <div style={{ paddingBottom: 24 }}>
              <SearchInput
                value={search}
                setValue={setSearch}
                style={{ marginLeft: 25, marginRight: 25 }}
              />
            </div>
          )}
          {pagination && (
            <div style={{ paddingBottom: 24, display: 'flex', justifyContent: 'center' }}>
              {/* <FloatingFilterWrapper
                isFilterActive={false}
                label='Itens por página'
                modalStyles={{ padding: 4 }}
                hideSubmitButton
              >
                <OptionList showDropDown={showDropDown}>
                  {pagination.options.map((item, index) => (
                    <OptionItem
                      active={pagination.selected === item}
                      key={index}
                      onClick={() => {
                        setShowDropDown(!showDropDown)
                        pagination.setSelected(item)
                      }}
                    >
                      {item.toString()}
                    </OptionItem>
                  ))}
                </OptionList>
              </FloatingFilterWrapper> */}
              <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                <InputLabel id='page-itens'>Itens por página</InputLabel>
                <Select
                  style={{ height: '35px' }}
                  labelId='page-itens'
                  value={pagination.selected}
                  onChange={(e) => pagination.setSelected(e.target.value as number)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                        width: 140,
                        marginLeft: 20,
                        borderRadius: 8,
                      },
                    },
                  }}
                >
                  {pagination.options.map((item, index) => (
                    <SelectItem
                      defaultChecked={index === 0}
                      key={index}
                      value={item}
                      onMouseDown={(e) => e.stopPropagation()}
                      onTouchStart={(e) => e.stopPropagation()}
                    >
                      {item}
                    </SelectItem>
                  ))}
                </Select>
              </div>
            </div>
          )}

          {selectableAll && (
            <div style={{ paddingBottom: 24 }}>
              <Label
                key='seletAllBox'
                label='Selecionar todas'
                control={
                  <Checkbox
                    checked={selectAll}
                    onChange={handleCheckAll}
                    color='default'
                    style={{ marginLeft: 30 }}
                  />
                }
              />
            </div>
          )}
        </>
      }
    >
      {options && (
        <ListContainer row sx={{ maxHeight: contentHeight }}>
          {(search.length > 0 ? filteredBoxes : boxes).map((box) => (
            <Label
              key={box.id}
              label={box.label}
              control={
                <Checkbox
                  checked={!!box.checked}
                  onChange={(event) => handleCheck(box.label, event)}
                  color='default'
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
            />
          ))}
        </ListContainer>
      )}
    </FloatingFilterWrapper>
  )
}
