import { Alert, Snackbar } from '@mui/material'
import { Card, Container } from './styles'
import { useEffect, useState } from 'react'
import { ITests } from '../utils/interface'
import axios from 'axios'
import { formatDate, formatDateRef } from '../utils/format'
import { InputV2 } from '../../InputV2'
import { Form, FormikProvider } from 'formik'
import { FIELDS_TO_TRANSFORM } from '../../../pages/Report/Default'

export function DefaultTemplate({
  form,
  formData,
  setFormData
}: {
  form: any
  formData: ITests
  setFormData: (value: ITests) => void
}) {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    const getDataFromCep = async () => {
      if (formData.cep && formData.cep.length === 9) {
        const cep = (await axios
          .get(`https://viacep.com.br/ws/${formData.cep.replace('-', '')}/json`)
          .then((res) => res.data)) as any

        if (cep) {
          setFormData({
            ...formData,
            cidade: cep.localidade,
            uf: cep.uf,
          })
        }
      }
    }

    getDataFromCep()
  }, [formData?.cep])

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target

    setFormData({
      ...formData,
      [id]: value,
    })
  }

  function handleChangeDate(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target

    setFormData({
      ...formData,
      [id]: formatDate(value),
    })
  }

  function handleChangeDateRef(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target

    setFormData({
      ...formData,
      [id]: formatDateRef(value),
    })
  }

  return (
    <Container>
      <div className='content'>
        {success ? (
          <div className='success'>
            <p>Fatura enviada com Sucesso</p>
            <button onClick={() => setSuccess(false)}>Enviar outra</button>
          </div>
        ) : (
          <FormikProvider value={form}>
            <Form id="primary-input-form-update">
              <Card>
                <strong>Dados da UC</strong>

                <InputV2
                  required
                  label='Número de instalação'
                  placeholder='Ex: 988765432-1'
                  value={formData.uc}
                  onChange={handleChange}
                  id='uc'
                />

                <InputV2
                  label='Grupo'
                  placeholder='Ex: B'
                  value={formData.subgrupo}
                  onChange={handleChange}
                  id='grupo'
                />

                <InputV2
                  label='Subgrupo'
                  placeholder='Ex: B1'
                  value={formData.subgrupo}
                  onChange={handleChange}
                  id='subgrupo'
                />

                <InputV2
                  label='Classe'
                  placeholder='Ex: Trifasico'
                  value={formData.classe}
                  onChange={handleChange}
                  id='classe'
                />
              </Card>

              <Card>
                <strong>Datas da fatura</strong>

                <InputV2
                  label='Mês de Referência'
                  required
                  id='mes_referencia'
                  placeholder='Ex: 12/2024'
                  value={formData.mes_referencia}
                  onChange={handleChangeDateRef}
                />

                <InputV2
                  required
                  label='Data de Leitura Anterior'
                  value={formData.data_leitura_anterior}
                  onChange={handleChangeDate}
                  id='data_leitura_anterior'
                  placeholder='Ex: 01/11/2024'
                />

                <InputV2
                  required
                  label='Data de Leitura Atual'
                  value={formData.data_leitura_atual}
                  onChange={handleChangeDate}
                  id='data_leitura_atual'
                  placeholder='Ex: 01/12/2024'
                />

                <InputV2
                  required
                  label='Data da Próxima Leitura'
                  value={formData.data_proxima_leitura}
                  onChange={handleChangeDate}
                  id='data_proxima_leitura'
                  placeholder='Ex: 01/01/2025'
                />

                <InputV2
                  required
                  label='Data de Emissão'
                  value={formData.data_emissao}
                  onChange={handleChangeDate}
                  id='data_emissao'
                  placeholder='Ex: 26/11/2024'
                />

                <InputV2
                  required
                  label='Data de Vencimento'
                  value={formData.data_vencimento}
                  onChange={handleChangeDate}
                  id='data_vencimento'
                  placeholder='Ex: 13/02/2025'
                />

                <InputV2
                  required
                  label='Dias faturados'
                  value={formData.dias_faturados}
                  onChange={handleChangeDate}
                  id='dias_faturados'
                  placeholder='Ex: 31'
                />

              </Card>

              <Card>
                <strong>Dados do consumidor</strong>

                <InputV2
                  label='CEP'
                  placeholder='Ex: 98765432'
                  value={formData.cep}
                  onChange={handleChange}
                  id='cep'
                />

                <InputV2
                  label='UF'
                  placeholder='Ex: SP'
                  value={formData.uf}
                  onChange={handleChange}
                  id='uf'
                />

                <InputV2
                  label='Cidade'
                  placeholder='Ex: Sao Paulo'
                  value={formData.cidade}
                  onChange={handleChange}
                  id='cidade'
                />

                <InputV2
                  label='Endereço'
                  placeholder='Ex: Av. John doe'
                  value={formData.endereco}
                  onChange={handleChange}
                  id='endereco'
                />
              </Card>

              <Card>
                <strong>Resumos da fatura</strong>

                <InputV2
                  label='Valor Total da Fatura'
                  onChange={handleChange}
                  value={formData.valor_total_fatura}
                  placeholder='Ex: 13210'
                  id='valor_total_fatura'
                />

                <InputV2
                  label='COSIP'
                  value={formData.cosip}
                  placeholder='Ex: 12'
                  onChange={handleChange}
                  id='cosip'
                />

                <InputV2
                  id='tot_band_val'
                  label="Total Bandeira Valor"
                  value={formData.tot_band_val}
                  onChange={handleChange}
                  placeholder='Ex: 1.2042'
                />

                <InputV2
                  id='tot_dev_band_val'
                  label="Total Devolucao Bandeira Valor"
                  value={formData.tot_dev_band_val}
                  onChange={handleChange}
                  placeholder='Ex: 0.32376'
                />
              </Card>

              <Card>
                <strong>Consumo</strong>

                <InputV2
                  label='Consumo SPT Quantidade'
                  value={formData.consumo_spt_qt}
                  placeholder='Ex: 0'
                  onChange={handleChange}
                  id='consumo_spt_qt'
                />

                <InputV2
                  label='Consumo FP Quantidade'
                  value={formData.consumo_fp_qt}
                  placeholder='Ex: 321'
                  onChange={handleChange}
                  id='consumo_fp_qt'
                />

                <InputV2
                  label='Consumo P Quantidade'
                  value={formData.consumo_p_qt}
                  placeholder='Ex: 321'
                  onChange={handleChange}
                  id='consumo_p_qt'
                />

                <InputV2
                  label='Consumo intermediário Quantidade'
                  value={formData.consumo_int_qt}
                  placeholder='Ex: 321'
                  onChange={handleChange}
                  id='consumo_int_qt'
                />

                <InputV2
                  label='Consumo Total Faturado Quantidade'
                  value={formData.consumo_total_faturado_qt}
                  placeholder='Ex: 4323.23'
                  onChange={handleChange}
                  id='consumo_total_faturado_qt'
                />
                <div />

                <InputV2
                  label='Consumo SPT Tarifa'
                  value={formData.consumo_spt_tar}
                  placeholder='Ex: 0.31'
                  onChange={handleChange}
                  id='consumo_spt_tar'
                />

                <InputV2
                  label='Consumo FP Tarifa'
                  value={formData.consumo_fp_tar}
                  placeholder='Ex: 0.31'
                  onChange={handleChange}
                  id='consumo_fp_tar'
                />

                <InputV2
                  label='Consumo P Tarifa'
                  value={formData.consumo_p_tar}
                  placeholder='Ex: 0.321'
                  onChange={handleChange}
                  id='consumo_p_tar'
                />


                <InputV2
                  label="Consumo intermediário Tarifa"
                  value={formData.consumo_int_tar}
                  placeholder='Ex: 0.321'
                  onChange={handleChange}
                  id='consumo_int_tar'
                />
              </Card>

              <Card>
                <strong>Compensado</strong>

                <InputV2
                  label='Energia Compensada SPT Quantidade'
                  value={formData.energia_comp_spt_qt}
                  placeholder='Ex: 321'
                  onChange={handleChange}
                  id='energia_comp_spt_qt'
                />

                <InputV2
                  label='Energia Compensada FP Quantidade'
                  value={formData.energia_comp_fp_qt}
                  placeholder='Ex: 321'
                  onChange={handleChange}
                  id='energia_comp_fp_qt'
                />

                <InputV2
                  label='Energia Compensada P Quantidade'
                  value={formData.energia_comp_p_qt}
                  placeholder='Ex: 321'
                  onChange={handleChange}
                  id='energia_comp_p_qt'
                />

                <InputV2
                  label="Energia Compensada INT Quantidade"
                  value={formData.energia_comp_int_qt}
                  placeholder='Ex: 321'
                  onChange={handleChange}
                  id='energia_comp_int_qt'
                />

                <InputV2
                  label="Energia Compensada Total Quantidade"
                  value={formData.energia_comp_total_qt}
                  placeholder='Ex: -3004.42'
                  onChange={handleChange}
                  id='energia_comp_total_qt'
                />

                <div />

                <InputV2
                  label='Energia Compensada SPT Tarifa'
                  value={formData.energia_comp_spt_tar}
                  placeholder='Ex: 0.321'
                  onChange={handleChange}
                  id='energia_comp_spt_tar'
                />

                <InputV2
                  label='Energia Compensada FP Tarifa'
                  value={formData.energia_comp_fp_tar}
                  placeholder='Ex: 0.321'
                  onChange={handleChange}
                  id='energia_comp_fp_tar'
                />

                <InputV2
                  label='Energia Compensada P Tarifa'
                  value={formData.energia_comp_p_tar}
                  placeholder='Ex: 0.321'
                  onChange={handleChange}
                  id='energia_comp_p_tar'
                />

                <InputV2
                  label="Energia Compensada INT Tarifa"
                  value={formData.energia_comp_int_tar}
                  placeholder='Ex: 0.321'
                  onChange={handleChange}
                  id='energia_comp_int_tar'
                />
              </Card>

              <Card>
                <strong>PIS</strong>

                <InputV2
                  label='Base'
                  onChange={handleChange}
                  value={formData.tot_trib_pis_base}
                  placeholder='Ex: 321'
                  id='tot_trib_pis_base'
                />

                <InputV2
                  label='Aliquota'
                  onChange={handleChange}
                  value={formData.tot_trib_pis_aliq}
                  placeholder='Ex: 12'
                  id='tot_trib_pis_aliq'
                />

                <InputV2
                  label='Valor'
                  onChange={handleChange}
                  value={formData.tot_trib_pis_val}
                  placeholder='Ex: 32'
                  id='tot_trib_pis_val'
                />
              </Card>

              <Card>
                <strong>ICMS</strong>

                <InputV2
                  label='Base'
                  onChange={handleChange}
                  value={formData.tot_trib_icms_base}
                  placeholder='Ex: 204.28'
                  id='tot_trib_icms_base'
                />

                <InputV2
                  label='Aliquota'
                  onChange={handleChange}
                  value={formData.tot_trib_icms_aliq}
                  placeholder='Ex: 15'
                  id='tot_trib_icms_aliq'
                />

                <InputV2
                  label='Valor'
                  onChange={handleChange}
                  value={formData.tot_trib_icms_val}
                  placeholder='Ex: 12.23'
                  id='tot_trib_icms_val'
                />
              </Card>

              <Card>
                <strong>COFINS</strong>

                <InputV2
                  label='Base'
                  onChange={handleChange}
                  value={formData.tot_trib_pis_base}
                  placeholder='Ex: 43.05'
                  id='tot_trib_cofins_base'
                />

                <InputV2
                  label='Aliquota'
                  onChange={handleChange}
                  value={formData.tot_trib_cofins_aliq}
                  placeholder='Ex: 20'
                  id='tot_trib_cofins_aliq'
                />

                <InputV2
                  label='Valor'
                  onChange={handleChange}
                  value={formData.tot_trib_cofins_val}
                  placeholder='Ex: 10'
                  id='tot_trib_cofins_val'
                />
              </Card>

              <Card>
                <strong>Gerador</strong>

                <InputV2
                  id='med_ger_spt_quant'
                  label="Medição geração SPT quantidade"
                  value={formData.med_ger_spt_quant}
                  placeholder='Ex: 321'
                  onChange={handleChange}
                />

                <InputV2
                  id='med_ger_fp_quant'
                  label="Medição geração FP quantidade"
                  value={formData.med_ger_fp_quant}
                  placeholder='Ex: 321'
                  onChange={handleChange}
                />

                <InputV2
                  id='med_ger_p_quant'
                  label="Medição geração P quantidade"
                  value={formData.med_ger_p_quant}
                  placeholder='Ex: 430'
                  onChange={handleChange}
                />
              </Card>

              <Card>
                <strong>Saldo</strong>

                <InputV2
                  label='Saldo Acumulado SPT'
                  value={formData.creditos_estoque_spt}
                  placeholder='Ex: 320'
                  id='creditos_estoque_spt'
                  onChange={handleChange}
                />

                <InputV2
                  label="Saldo Acumulado FP"
                  value={formData.creditos_estoque_fp}
                  placeholder='Ex: 120'
                  id='creditos_estoque_fp'
                  onChange={handleChange}
                />

                <InputV2
                  label="Saldo Acumulado P"
                  value={formData.creditos_estoque_p}
                  placeholder='Ex: 0'
                  id='creditos_estoque_p'
                  onChange={handleChange}
                />
              </Card>

              <Card>
                <strong>Tarifas</strong>

                <InputV2
                  label='Tarifa Crua - TE'
                  onChange={handleChange}
                  value={formData.consumo_spt_te_tar_crua}
                  placeholder='Ex: 0.321'
                  id='consumo_spt_te_tar_crua'
                />

                <InputV2
                  label='Tarifa Crua - TUSD'
                  onChange={handleChange}
                  value={formData.consumo_spt_tusd_tar_crua}
                  placeholder='Ex: 0.321'
                  id='consumo_spt_tusd_tar_crua'
                />

                <InputV2
                  label='Tarifa Crua - Total'
                  onChange={handleChange}
                  value={formData.consumo_spt_tar_crua}
                  placeholder='Ex: 0.321'
                  id='consumo_spt_tar_crua'
                />
              </Card>
            </Form>
          </FormikProvider>
        )}
      </div>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(undefined)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='error'
          onClose={() => setErrorMessage(undefined)}
          sx={{
            width: 400,
          }}
        >
          <p>{errorMessage}</p>
        </Alert>
      </Snackbar>
    </Container >
  )
}
