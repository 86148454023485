import { useEffect, useState } from 'react'
import { Button } from '../../../../../components/Button'
import { InputV2 } from '../../../../../components/InputV2'
import ManagerSelect from '../../../../../components/ManagerSelect'
import Modal from '../../../../../components/ModalV2'
import { StickyFooter } from '../../../InvoiceValidation/components/InvoiceModal/styles'
import { paymentApi, TemplateConfig } from '../../../../../services/payment-api'
import { useToast } from '../../../../../hooks/useToast'
import { SelectV2 } from '../../../../../components/SelectV2'
import { useInvoiceTemplate } from '../../hooks/useInvoiceTemplate'

interface Props {
  onClose: () => void
}

export default function CreateTemplateModal(props: Props) {
  const { onClose } = props

  const [parentConfigs, setParentConfigs] = useState<TemplateConfig[]>([])
  const [selectedParentConfigId, setSelectedParentConfigId] = useState<number>()
  const [managerId, setManagerId] = useState<number>()

  const [isCreatingTemplate, setCreatingTemplate] = useState<boolean>()

  const { toast } = useToast()

  const { setTemplateConfig, setTemplateData } = useInvoiceTemplate()

  const fetchParentConfigs = async () => {
    try {
      const response = await paymentApi.getParentTemplateConfigs()
      setParentConfigs(response)
    } catch (error) {
      toast({
        message: error instanceof Error ? error?.message : 'Erro ao buscar modelos',
        type: 'error',
      })
    }
  }

  const createTemplate = async () => {
    if (!selectedParentConfigId || !managerId || isCreatingTemplate) {
      return
    }
    try {
      setCreatingTemplate(true)
      const templateInfo = await paymentApi.createTemplate({
        managerId,
        parentConfigId: selectedParentConfigId,
      })
      const { templateConfig, templateData } = await paymentApi.getTemplateData({
        templateConfigId: templateInfo.templateConfig.id,
        managerId,
      })
      setTemplateConfig(templateConfig)
      setTemplateData(templateData)
      onClose()
    } catch (error) {
      toast({
        message: error instanceof Error ? error?.message : 'Erro ao criar template',
        type: 'error',
      })
    } finally {
      setCreatingTemplate(false)
    }
  }

  useEffect(() => {
    fetchParentConfigs()
  }, [])

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        width: '100%',
        position: 'relative',
        height: '100%',
        maxHeight: '750px',
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        Novo Template
        <Modal.CloseButton alignLeft />
      </Modal.Title>
      <Modal.Body style={{ overflowY: 'auto' }}>
        <ManagerSelect
          value={managerId}
          onChange={(_, val) => setManagerId(val ? val : 0)}
          renderInput={(params) => <InputV2 {...params} required formik={false} label='Gestor' />}
        />
        <SelectV2
          label='Modelo de Template'
          formik={false}
          value={selectedParentConfigId}
          required
          onChange={(e) => setSelectedParentConfigId(Number(e.target.value))}
          items={parentConfigs.map((templateConfig, index) => ({
            id: (templateConfig as any).id,
            displayName: `Modelo #${index + 1}`,
          }))}
        />
      </Modal.Body>
      <StickyFooter style={{ padding: '16px', marginTop: 'auto' }}>
        <Button
          containerStyle={{ margin: 0 }}
          text='Criar Template'
          onClick={createTemplate}
          isLoading={isCreatingTemplate}
        />
      </StickyFooter>
    </Modal.Provider>
  )
}
