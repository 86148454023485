import _ from 'lodash'
import { SelectV2 } from '../../../../../components/SelectV2'
import { IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { InputV2 } from '../../../../../components/InputV2'
import { Button } from '../../../../../components/Button'
import ColorPicker from '../ColorPicker'
import SvgViewer from '../SvgViewer'
import ImageViewer from '../ImageViewer'
import { Add, DataObject, Delete, Folder, InsertPhoto } from '@mui/icons-material'
import { useMemo } from 'react'
import { Card, CardActions } from './styles'
import TextInput from '../TextInput'
import { GetTemplateConfigResponse } from '../../../../../services/payment-api'

const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
const svgRegex = /^<svg[^>]*>[\s\S]*<\/svg>$/

interface Props {
  templateInfo: GetTemplateConfigResponse
  activeItem?: string
  setActiveItem: (key: string) => void
  setFieldValue: (key: string, value: unknown) => void
  setAddingNewItem: (key?: string) => void
  openImageGalleryModal: () => void
}

export default function InvoiceTemplateFields(props: Props) {
  const {
    templateInfo,
    activeItem,
    setFieldValue,
    setActiveItem,
    setAddingNewItem,
    openImageGalleryModal,
  } = props

  const renderField = (key: string) => {
    const value = _.get(templateInfo.templateConfig, key)

    if (_.isBoolean(value)) {
      return (
        <SelectV2
          formik={false}
          label={key}
          value={value ? 1 : 0}
          items={[
            { displayName: 'Sim', id: 1 },
            { displayName: 'Não', id: 0 },
          ]}
          onChange={(e) => setFieldValue(key, e.target.value === 1 ? true : false)}
        />
      )
    }

    if (_.isArray(value)) {
      if (activeItem) {
        return (
          <Stack gap={2}>
            {value.map((item, index) => (
              <Stack key={`array-item-${key}-${index}`} gap={2}>
                {renderField(`${key}[${index}]`)}
              </Stack>
            ))}
            <Button
              style={{ margin: 0, width: 'fit-content', paddingLeft: 16, paddingRight: 16 }}
              onClick={() => setAddingNewItem(key)}
              text='Adicionar Item'
            />
          </Stack>
        )
      }

      return (
        <Stack
          direction='row'
          alignItems='center'
          sx={{
            cursor: 'pointer',
            p: 1,
            backgroundColor: '#f1f1f1',
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: 'action.hover',
            },
            height: '45px',
          }}
          onClick={() => setActiveItem(key)}
        >
          <Folder sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography sx={{ fontWeight: 'bold', color: 'text.primary' }}>{key}</Typography>
        </Stack>
      )
    }

    if (_.isObject(value)) {
      if (!activeItem) {
        return (
          <Stack
            direction='row'
            alignItems='center'
            sx={{
              cursor: 'pointer',
              p: 1,
              backgroundColor: '#f1f1f1',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
              height: '45px',
            }}
            onClick={() => setActiveItem(key)}
          >
            <DataObject sx={{ mr: 1, color: 'text.secondary' }} />
            <Typography sx={{ fontWeight: 'bold', color: 'text.primary' }}>{key}</Typography>
          </Stack>
        )
      }

      const uri = _.get(value, 'url')
      const width = _.get(value, 'width')

      if (uri && width) {
        return (
          <Card>
            <strong style={{ display: 'flex', alignItems: 'center' }}>
              <InsertPhoto style={{ marginRight: 8 }} />
              {key}
            </strong>
            <ImageViewer
              openImageGalleryModal={openImageGalleryModal}
              value={{ fileName: key, uri, width }}
              onChange={(width) => {
                const updatedValue = { ...value }
                _.set(updatedValue, 'width', width)
                setFieldValue(key, updatedValue)
              }}
            />
          </Card>
        )
      }

      return (
        <Card>
          <strong onClick={() => setActiveItem(key)}>{key}</strong>
          <CardActions>
            <Tooltip
              title={<p style={{ fontSize: 15 }}>Adicionar Propriedade</p>}
              placement='bottom'
            >
              <IconButton onClick={() => setAddingNewItem(key)}>
                <Add />
              </IconButton>
            </Tooltip>
            <Tooltip title={<p style={{ fontSize: 15 }}>Excluir Campo</p>} placement='bottom'>
              <IconButton>
                <Delete />
              </IconButton>
            </Tooltip>
          </CardActions>
          <Stack gap={2}>{_.keys(value).map((subKey) => renderField(`${key}.${subKey}`))}</Stack>
        </Card>
      )
    }

    if (_.isString(value)) {
      if (hexColorRegex.test(value)) {
        return (
          <InputV2
            formik={false}
            label={key}
            defaultValue={value}
            onChange={(e: any) => setFieldValue(key, e.target.value)}
            rightIcon={
              <ColorPicker
                value={value}
                onChange={(updatedValue) => setFieldValue(key, updatedValue)}
              />
            }
          />
        )
      }

      if (svgRegex.test(value)) {
        return (
          <Stack direction='column'>
            <Stack direction='row' mb={1}>
              <InsertPhoto style={{ marginRight: 8 }} />
              <Typography style={{ color: '#4B4B4B' }}>{key}</Typography>
            </Stack>
            <SvgViewer
              value={value}
              onChange={(updatedValue) => setFieldValue(key, updatedValue)}
            />
          </Stack>
        )
      }

      return (
        <TextInput
          label={key}
          value={value}
          variables={templateInfo.validationSchema}
          onChange={(updatedValue) => setFieldValue(key, updatedValue)}
        />
      )
    }

    return (
      <TextInput
        label={key}
        value={String(value)}
        variables={templateInfo.validationSchema}
        onChange={(updatedValue) => setFieldValue(key, updatedValue)}
      />
    )
  }

  const templateFieldsToRender = !activeItem
    ? templateInfo.templateConfig
    : _.get(templateInfo.templateConfig, activeItem)

  const sortedTemplateFields = activeItem
    ? [activeItem]
    : _.sortBy(
        _.isArray(templateFieldsToRender)
          ? templateFieldsToRender
          : _.entries(templateFieldsToRender as Record<string, unknown>),
        ([, value]) => {
          if (_.isString(value) || _.isNumber(value) || _.isBoolean(value)) {
            return 0
          }
          if (_.isArray(value)) {
            return 2
          }
          if (_.isObject(value)) {
            return 1
          }
          return 3
        },
      ).map(([key]) => key)

  const fields = useMemo(
    () => sortedTemplateFields.map(renderField),
    [templateInfo.templateConfig, activeItem],
  )

  return (
    <>
      <Stack gap={2}>{fields}</Stack>
    </>
  )
}
