import { Pagination as MUIPagination } from '@mui/material'
import { PaginationContainer } from './styles'
import { Pagination } from '../../../../interfaces/conference'
import { CSSProperties } from 'react'

interface Props {
  currentPageItemCount: number
  pageInfo: Pagination<unknown>['pageInfo']
  onChange: (page: number) => void
  style?: CSSProperties
}

export function TablePagination(props: Props) {
  const { pageInfo, currentPageItemCount, style, onChange } = props

  const { currentPage, totalPages, totalCount } = pageInfo

  return (
    <PaginationContainer style={style}>
      <p>{`Mostrando ${currentPageItemCount} de ${totalCount} ítens`}</p>
      <MUIPagination
        defaultPage={currentPage}
        count={totalPages}
        color='primary'
        onChange={(_, page) => {
          onChange(page)
        }}
      />
    </PaginationContainer>
  )
}
