import { MenuItem, SelectProps, Typography } from '@mui/material'
import { CustomSelect } from './styles'
import { Field, FieldProps } from 'formik'
import { Label } from '../InputV2/styles'
import { forwardRef } from 'react'

type Props = {
  formik?: boolean
  items: {
    id: number | string
    displayName: string
  }[]
  isSecondaryColor?: boolean
} & SelectProps

/* eslint-disable react/display-name */
export const SelectV2 = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { formik = true, items, label, id, isSecondaryColor = false, ...rest  } = props

  const buildInput = (fieldProps?: FieldProps) => {
    const hasError = !!(fieldProps && fieldProps.meta.touched && !!fieldProps.meta.error)

    return (
      <div style={{ width: '100%' }}>
        {label && (
          <div style={{ display: 'flex', gap: 8 }}>
            <Label required={rest.required} htmlFor={id}>
              {label}
            </Label>
            {/* {tooltip && <Tooltip title='Random message!' />} */}
          </div>
        )}

        <CustomSelect ref={ref} id={id} error={hasError} isSecondaryColor={isSecondaryColor} {...rest} {...fieldProps?.field}>
          {items.map((item) => (
            <MenuItem key={`select-item-${item.id}-${item.displayName}`} value={item.id}>
              {item.displayName}
            </MenuItem>
          ))}
        </CustomSelect>

        {hasError && (
          <Typography variant='subtitle2' color='red'>
            {fieldProps?.meta?.error}
          </Typography>
        )}
      </div>
    )
  }

  if (formik) {
    return <Field name={id}>{(fieldProps: FieldProps) => buildInput(fieldProps)}</Field>
  }

  return buildInput()
})
