import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'
import {
  GetTemplatePreviewParams,
  paymentApi,
  TemplateConfig,
} from '../../../../services/payment-api'

interface InvoiceTemplateContextData {
  previewTemplateContent: () => Promise<string | null>

  templateConfig?: TemplateConfig
  templateData?: TemplateData

  setTemplateConfig: Dispatch<SetStateAction<TemplateConfig | undefined>>
  setTemplateData: Dispatch<SetStateAction<TemplateData | undefined>>
}

export type TemplateData = GetTemplatePreviewParams['templateData']

const InvoiceTemplateContext = createContext({} as InvoiceTemplateContextData)

export function InvoiceTemplateProvider(props: PropsWithChildren) {
  const { children } = props

  const [templateConfig, setTemplateConfig] = useState<TemplateConfig>()
  const [templateData, setTemplateData] = useState<TemplateData>()

  const previewTemplateContent = async () => {
    if (!templateConfig || !templateData || !templateConfig?.clientId) {
      return null
    }
    const templateContent = await paymentApi.getTemplatePreview({
      templateConfig,
      templateData,
      managerId: templateConfig.clientId,
    })
    return templateContent
  }

  return (
    <InvoiceTemplateContext.Provider
      value={{
        previewTemplateContent,
        templateConfig,
        templateData,
        setTemplateConfig,
        setTemplateData,
      }}
    >
      {children}
    </InvoiceTemplateContext.Provider>
  )
}

export function useInvoiceTemplate() {
  const context = useContext(InvoiceTemplateContext)
  if (!context) {
    throw new Error('Provider not found')
  }
  return context
}
