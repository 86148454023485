import { Button, Buttons, Container, Infos, Return, Status } from './styles'
import { useEffect, useState } from 'react'
import ArrowBack from '@mui/icons-material/ArrowBack'
import {
  changeManual,
  createManual,
  createManualPdf,
  getClosure,
  getOneInvoice,
  getReportValues,
  listAllUcsCodes,
} from '../../../services/requests/user-requests'
import { SendInvoice } from '../../../components/Pdfs/SendInvoice'
import {
  formatDate,
  formatPrice,
  formatString,
  sendFormat,
} from '../../../components/Pdfs/utils/format'
import { useLocation, useNavigate } from 'react-router-dom'
import { CopelTemplate } from '../../../components/Pdfs/Copel'
import { initialState, resetState } from '../utils'
import { InvoicePreviewer } from '../InvoicePreviewer'
import { readers } from '../invoicesUrlPerDistributor'

export function ReportCopel({ setPage }: { setPage: (value: '') => void }) {
  const location = useLocation()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(location.search)
  const updateStatus = queryParams.get('update')

  const [data, setData] = useState(initialState)
  const [extra, setExtra] = useState<any[]>([])
  const [allUcs, setAllUcs] = useState<string[]>([])
  const [file, setFile] = useState<File>()
  const [status, setStatus] = useState('')

  useEffect(() => {
    ;(async () => {
      if (updateStatus && !isNaN(parseInt(updateStatus))) {
        const fatura = await getOneInvoice(updateStatus)
        const [codigo, monthRef, yearRef] = fatura.chave.split('-')
        const [yearVenc, monthVenc, dayVenc] = fatura.data_vencimento.split('-')
        const [yearEmi, monthEmi, dayEmi] = fatura.data_emissao.split('-')
        const [yearAnt, monthAnt, dayAnt] = fatura.data_leitura_anterior.split('-')
        const [yearAtual, monthAtual, dayAtual] = fatura.data_leitura_atual.split('-')
        let data_proxima_leitura = ''
        if (fatura.data_proxima_leitura) {
          const [yearNext, monthNext, dayNext] = fatura.data_proxima_leitura.split('-')
          data_proxima_leitura = `${dayNext}/${monthNext}/${yearNext}`
        }

        setData({
          ...data,
          id: fatura.id,
          mes_referencia: `${monthRef}/${yearRef}`,
          uc: codigo,
          data_vencimento: `${dayVenc}/${monthVenc}/${yearVenc}`,
          data_emissao: `${dayEmi}/${monthEmi}/${yearEmi}`,
          data_leitura_anterior: `${dayAnt}/${monthAnt}/${yearAnt}`,
          data_leitura_atual: `${dayAtual}/${monthAtual}/${yearAtual}`,
          data_proxima_leitura,
          nome: fatura.nome,
          endereco: fatura.endereco,
          cep: fatura.cep,
          med_ger_tot_quant: formatString(fatura.med_ger_tot_quant),
          med_ger_spt_quant: formatString(fatura.med_ger_spt_quant),
          med_ger_p_quant: formatString(fatura.med_ger_p_quant),
          med_ger_fp_quant: formatString(fatura.med_ger_fp_quant),
          bairro: '',
          cidade: '',
          doc: fatura.doc,
          uf: '',
          numero: '',
          grupo: fatura.grupo,
          subgrupo: fatura.subgrupo,
          classe: fatura.classe,
          inscricaoEstadual: '',
          fases: fatura.fases,
          modalidade: fatura.modalidade,
          bandeira: '',
          cosip: formatString(fatura.cosip),
          consumo_spt_qt: formatString(fatura.consumo_spt_qt),
          consumo_spt_tar: '',
          consumo_spt_tar_crua: '',
          consumo_spt_val: '',
          energia_comp_spt_qt: '',
          energia_comp_spt_qt_opt: '',
          energia_comp_spt_tar: '',
          energia_comp_spt_val: '',
          energia_comp_spt_crua: '',
          energia_comp_total_qt: '',
          energia_comp_total_tar: '',
          energia_comp_total_val: '',
          consumo_total_faturado_val: '',
          consumo_total_faturado_tar: '',
          consumo_total_faturado_qt: '',
          valor_total_fatura: formatPrice(formatString(fatura.valor_total_fatura), true),
          creditos_estoque_spt: formatString(fatura.creditos_estoque_spt),
          creditos_estoque_tot: '',
          consumo_spt_te_tar: formatString(fatura.consumo_spt_te_tar_crua),
          consumo_spt_tusd_tar: formatString(fatura.consumo_spt_tusd_tar_crua),
          tot_trib_pis_aliq: formatString(fatura.tot_trib_pis_aliq),
          tot_trib_cofins_aliq: formatString(fatura.tot_trib_cofins_aliq),
          tot_trib_icms_aliq: formatString(fatura.tot_trib_icms_aliq),
          dias_faturados: formatString(fatura.dias_faturados),
          ouc_te: '',
          ouc_tusd: '',
          ouc_te_opt: '',
          ouc_tusd_opt: '',
          exist: false,
          autoconsumo: false,
        })
      }
    })()
  }, [updateStatus])

  function changeInfo(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target

    setData({
      ...data,
      [id]: value,
    })
  }

  function changeInfoPrice(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target

    setData({
      ...data,
      [id]: formatPrice(value),
    })
  }

  function changeInfoNegative(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target

    setData({
      ...data,
      [id]: `${parseFloat(value.replace('-', '')) > 0 ? '-' : ''}${value.replace('-', '')}`,
    })
  }

  function changeInfoDate(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target

    setData({
      ...data,
      [id]: formatDate(value),
    })
  }

  function reset() {
    setData(resetState)
  }

  function sendData() {
    const formattedValue = sendFormat(data, extra)

    console.log(formattedValue)

    if (
      data.uc &&
      data.mes_referencia &&
      data.data_emissao &&
      data.data_leitura_anterior &&
      data.data_leitura_atual &&
      data.data_vencimento &&
      data.data_proxima_leitura &&
      (updateStatus || (file !== undefined && file !== null))
    ) {
      ;(async () => {
        setStatus('Enviando')

        if (updateStatus) {
          setStatus('Enviando')

          try {
            await changeManual(formattedValue)
              .catch(() => {
                alert(
                  'Ocorreu um erro ao tentar subir a fatura, por favor contate os desenvolvedores!',
                )
              })
              .finally(() => {
                setStatus('')
              })
          } catch {
            setStatus('')

            alert('Ocorreu um erro ao enviar a fatura!')
          }
        } else {
          if (file && formattedValue) {
            const formDatas = new FormData()
            formDatas.append('files', file)

            const result = await createManualPdf(formDatas).catch(() => {
              alert('Ocorreu um erro ao tentar subir o pdf, por favor contate os desenvolvedores!')
            })

            await createManual({ ...formattedValue, drive_id: result.id })
              .then(() => reset())
              .catch(() => {
                alert(
                  'Ocorreu um erro ao tentar subir a fatura, por favor contate os desenvolvedores!',
                )
              })
              .finally(() => setStatus(''))
          } else {
            setStatus('')

            alert('Ocorreu um erro ao enviar os dados!')
          }
        }
      })()
    } else {
      alert(
        'Certifique-se de adicionar o número de instalação, data emissão, conta mês, data de leitura anterior, data de leitura atual, data de vencimento e enviar a fatura',
      )
    }
  }

  useEffect(() => {
    ;(async () => {
      setStatus('Carregando Ucs')
      const data = await listAllUcsCodes().finally(() => setStatus(''))

      setAllUcs(data as unknown as string[])
    })()
  }, [])

  function fechamento() {
    ;(async () => {
      const formattedValue = sendFormat(data, extra)

      console.log(formattedValue)
      await getClosure(formattedValue)
        .catch(() => {
          alert('Ocorreu um erro ao adquirir o fechamento')
        })
        .then((res: any) => {
          if (res.error) {
            alert('Ocorreu um erro ao adquirir o fechamento')
          } else {
            let text = ''

            for (const r of Object.keys(res)) {
              text += `${r}: ${res[r]}
            `
            }
            alert(text)
          }
        })
    })()
  }

  function autoPreencher() {
    ;(async () => {
      if (allUcs.length && allUcs.includes(data.uc) && data.mes_referencia.length > 5) {
        setStatus('Auto completando')

        const [month, year] = data.mes_referencia.split('/')
        const mes_referencia = `${year}-${month}-01`

        const replacements = (await getReportValues({ codigo: data.uc, mes_referencia }).finally(
          () => setStatus(''),
        )) as any

        setData({
          ...data,
          doc: replacements.documento_numero,
          cep: data.cep || replacements.cep,
          endereco: data.endereco || replacements.endereco,
          subgrupo: data.subgrupo || replacements.subgrupo,
          nome: replacements.nome_razao_social || replacements.nome,
          consumo_spt_te_tar: data.consumo_spt_te_tar || replacements.te,
          consumo_spt_tusd_tar: data.consumo_spt_tusd_tar || replacements.tusd,
          classe: data.classe || replacements.classe,
          modalidade: data.modalidade || replacements.modalidade,
          fases: data.fases || replacements.fases,
          tot_trib_pis_aliq: data.tot_trib_pis_aliq || replacements.pis,
          tot_trib_cofins_aliq: data.tot_trib_cofins_aliq || replacements.cofins,
          tot_trib_icms_aliq: data.tot_trib_icms_aliq || replacements.icms,
          exist: replacements.existFat,
        })

        if (replacements.existFat) alert('Essa fatura já existe')
      } else {
        alert('Adicione o conta mês e número de inscrição válido para avaliar')
      }
    })()
  }

  return (
    <Container>
      {!status ? (
        <Infos>
          <Return
            onClick={() => {
              if (updateStatus) {
                navigate('/report/edit')
              } else setPage('')
            }}
          >
            <ArrowBack fontSize='small' />
            <p>Voltar</p>
          </Return>
          <Buttons>
            {data.exist ? <Status>Esta fatura já existe</Status> : null}
            {updateStatus ? null : <Button onClick={reset}>Reiniciar</Button>}
            <Button onClick={autoPreencher}>Avaliar</Button>
            <Button onClick={fechamento}>Fechamento</Button>
            <Button onClick={sendData}>{updateStatus ? 'Atualizar' : 'Enviar'}</Button>
          </Buttons>
        </Infos>
      ) : null}
      {!updateStatus ? <SendInvoice setFile={setFile} /> : null}

      {!status ? (
        <CopelTemplate
          data={data}
          setData={setData}
          changeInfoPrice={changeInfoPrice}
          changeInfo={changeInfo}
          changeInfoDate={changeInfoDate}
          changeInfoNegative={changeInfoNegative}
          ucs={allUcs}
          extra={extra}
          setExtra={setExtra}
        />
        
      ) : (
        status
      )}
      <InvoicePreviewer reader={readers.COPEL_B}  />

    </Container>
  )
}
