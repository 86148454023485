import React, { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'

import { getAbsoluteHeight } from '../../utils/getDimensionsElements'
import { ActionButton, Content, DefaultActionContent, FloatingModal, SubmitButton } from './styles'
import { GlobalContext } from '../../context/global/global'
interface Props {
  label?: string
  customActionComponent?: ReactNode
  submitBtnText?: string
  checkbox?: boolean
  children: ReactNode
  header?: ReactNode
  isFilterActive: boolean
  customIcon?: boolean
  buttonColor?: { bg: string; text: string }
  /* eslint-disable-next-line */
  submitAction?: () => any
  styles?: React.CSSProperties
  modalStyles?: React.CSSProperties
  setContentHeight?: Dispatch<SetStateAction<number>>
  hideSubmitButton?: boolean
}

export const FloatingFilterWrapper: React.FC<Props> = ({
  label,
  customActionComponent,
  children,
  checkbox,
  header,
  submitBtnText,
  submitAction,
  styles,
  modalStyles,
  setContentHeight,
  isFilterActive,
  customIcon,
  buttonColor,
  hideSubmitButton,
}) => {
  const [isModalOpened, setIsModalOpened] = useState(false)
  const floatingModalRef = useRef<HTMLInputElement>(null)
  const headerRef = useRef<HTMLInputElement>(null)
  const [modalHeight, setModalHeight] = useState<number>(0)
  const [headerHeight, setHeaderHeight] = useState<number>(0)
  const [sideToRender, setSideToRender] = useState<'left' | 'right'>('right')

  const { Theme } = React.useContext(GlobalContext)

  const listener = (event: Event) => {
    const modal = floatingModalRef?.current

    if (!modal || modal.contains((event?.target as Node) || null)) {
      return
    }

    setIsModalOpened(false)
    document.removeEventListener('mousedown', listener)
    document.removeEventListener('touchstart', listener)
  }

  useEffect(() => {
    if (isModalOpened) {
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
    }
  }, [isModalOpened])

  const handleSubmit = () => {
    setIsModalOpened(false)
    submitAction && submitAction()
  }

  useEffect(() => {
    if (!floatingModalRef.current) return

    const pos = floatingModalRef?.current?.getBoundingClientRect()
    if (pos === undefined) return

    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

    if (headerRef.current) {
      const hHeight = getAbsoluteHeight(headerRef.current)
      setHeaderHeight(hHeight)
    }

    const calcModalHeight = vh - pos.top - 20

    setModalHeight(calcModalHeight >= 500 ? calcModalHeight : 500)

    setSideToRender(vw - pos.right < 0 ? 'left' : 'right')
  }, [headerRef, floatingModalRef, isModalOpened])

  setContentHeight &&
    useEffect(() => {
      setContentHeight(modalHeight - headerHeight - 80)
    }, [modalHeight])

  return (
    <div style={{ position: 'relative', ...styles }}>
      <ActionButton
        onClick={() => {
          setIsModalOpened(true)
        }}
      >
        {customActionComponent ?? (
          <DefaultActionContent
            style={{ height: '34px' }}
            checkbox={checkbox}
            isModalOpened={isModalOpened}
            backgroundColor={isFilterActive ? Theme.colors.darkishGreen : undefined}
            textColor={isFilterActive ? Theme.colors.white : undefined}
          >
            {customIcon ? (
              <DescriptionOutlinedIcon fontSize='small' />
            ) : (
              <FilterAltOutlinedIcon fontSize='small' />
            )}

            {label && <span style={{fontSize: '15px'}}>{label}</span>}
          </DefaultActionContent>
        )}
      </ActionButton>
      <FloatingModal
        ref={floatingModalRef}
        isModalOpened={isModalOpened}
        sideToRender={sideToRender}
        style={{
          maxHeight: modalHeight,
          ...modalStyles,
        }}
      >
        {header && <div ref={headerRef}>{header}</div>}
        <Content height={modalHeight - headerHeight - 80}>{children}</Content>
        {!hideSubmitButton && (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <SubmitButton onClick={handleSubmit}>{submitBtnText || 'Aplicar'}</SubmitButton>
          </div>
        )}
      </FloatingModal>
    </div>
  )
}
