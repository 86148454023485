import WidgetInfoV2 from '../../../components/WidgetInfoV2'
import { ReactComponent as BoltIcon } from '../../../assets/bolt.svg'
import { ReactComponent as WalletIcon } from '../../../assets/wallet.svg'
import { PowerOutlined, StorefrontOutlined } from '@mui/icons-material'
import { useCallback, useEffect, useState } from 'react'
import { DateFilter } from '../../../components/Filter/DateFilter'
import { TableV2 } from '../../../components/TableV2'
import { IconButton, Stack, Tooltip } from '@mui/material'
import DistributorFilter from '../../AggregatedPayments/components/DistributorFilter'
import MultiManagerFilter from '../Orchestrator/components/MultiManagerFilter'
import { TicketsCount } from '../../Tickets/components/TicketsTable/styles'
import SearchInput from '../../Tickets/components/SearchInput'
import DownloadSheet from './components/DownloadSheet'
import TableConfig from './components/TableConfig'
import { GridColDef } from '@mui/x-data-grid'
import { PaginationQuery, Sort } from '../../../interfaces/pagination'
import { DashboardSuperCards, DashboardSuperList, dashboardSuperService } from '../../../services/dashboard-super'
import { useToast } from '../../../hooks/useToast'
import { TablePagination } from '../../../components/TableV2/components/TablePagination'
import Loading from '../../../components/Loading'
import { CheckboxType } from '../../../interfaces/checkbox'
import { DashboardHeader as Header, WidgetGroup} from './style'
import EditIcon from '../../../assets/edit.svg'
import { EditGestorModal } from './components/EditGestorModal'
import { clientService } from '../../../services/client'
import { SelectV2 } from '../../../components/SelectV2'

interface SelectedClient {
  clientId: number
  clientStatus: 'ACTIVE' | 'INACTIVE' | 'OVERDUE'
}

export default function AdminDashboard() {
  const { toast } = useToast()
  const [dateSelected, setDateSelected] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })

  const [isLoading, setLoading] = useState<boolean>(false)
  const [cards, setCards] = useState<DashboardSuperCards['result']>()
  const [tablePagination, setTablePagination] = useState<DashboardSuperList['result']>()
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({
    itemsPerPage: 15,
    page: 1,
  })
  const [sort, setSort] = useState<Sort | undefined>({field:'clientId',order:'asc'})
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [selectedClient, setSelectedClient] = useState<SelectedClient>()

  const statusList = [
    { id: 'ACTIVE', displayName: 'Ativo' },
    { id: 'INACTIVE', displayName: 'Inativo' },
    { id: 'OVERDUE', displayName: 'Inadimplente' },
  ]

  const [tableColumns, setTableColumns] = useState<GridColDef[]>([
    { field: 'clientId', headerName: 'ID', sortable: true, hideable: true },
    { field: 'clientName', headerName: 'Cliente', sortable: true, hideable: true },
    { field: 'totalUcsCount', headerName: 'Total de UCs', sortable: true, hideable: true },
    { field:'powerPlantsCount', headerName:'Total de Usinas', sortable: true, hideable: true},
    { field: 'totalPower', headerName: 'Potência', sortable: true, hideable: true },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      hideable: false,
      maxWidth: 180,
      renderCell(params) {
        return (
          <Stack width={'100%'}>
            <SelectV2
              required
              formik={false}
              value={params.row.status}
              items={statusList}
              isSecondaryColor={true}
              onChange={({ target }) => {
                if(target?.value === params.row.status) return
                handleSubmit({
                  gestorId: params.row.clientId as number,
                  selectedStatus: target?.value as 'ACTIVE' | 'INACTIVE' | 'OVERDUE',
                })
              }}
            />
          </Stack>
        )
      },
    }
  ])

  const handleSubmit = useCallback(async ({
    gestorId,
    selectedStatus,
  }: {
    gestorId: number
    selectedStatus: 'ACTIVE' | 'INACTIVE' | 'OVERDUE'
  }) => {
    try {
      setLoading(true)
      await clientService.updateStatus({
        clientId: gestorId,
        status: selectedStatus,
      })
      toast({
        message: 'Gestor atualizado com sucesso',
        type: 'success',
      })
      await onSuccess()
      setPaginationQuery((previous) => ({
        ...previous,
        page: 1,
      }))
    } catch (error) {
      toast({
        message: 'Erro ao atualizar gestor',
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }, [ toast ])

  const fetchTablePagination = async () => {
    try {
      const response = await dashboardSuperService.list({
        monthReference: `${dateSelected.year}-${dateSelected.month}`,
        page:paginationQuery.page,
        itemsPerPage:paginationQuery.itemsPerPage,
        filter:paginationQuery.filter,
        sort:sort
      })

      setTablePagination(response)
    } catch (error: any) {
      toast({
        message: error.message ? error.message : 'Erro ao listar itens da tabela',
        type: 'error',
      })
    }
  }

  const fetchOverview = async () => {
    try {
      setLoading(true)
      const response = await dashboardSuperService.cards({
        ...paginationQuery,
        monthReference: `${dateSelected.year}-${dateSelected.month}`,
          })
      setLoading(false)
      setCards(response)
    } catch (error: any) {
      toast({
        message: error.message ? error.message : 'Erro ao carregar dados dos cards',
        type: 'error',
      })
    }
  }

  const handleSpreadsheetDownload = async (referenceMonths: string[]) => {
    try {
       const spreadSheetBlob = await dashboardSuperService.downloadSpreadSheet({
         filter:paginationQuery.filter,
        referenceMonths,
      })
      
      const downloadUrl = window.URL.createObjectURL(spreadSheetBlob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = 'tabela.xlsx';
      document.body.appendChild(link);
      
      link.click();
  
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
      
    } catch (error: any) {
      toast({
        message: error.message ? error.message : 'Erro ao baixar planilha',
        type: 'error',
      })
    }
  }

  const fetchData = async () => {
    setLoading(true)
    await Promise.all([fetchOverview(), fetchTablePagination()])
    setLoading(false)
  }
  const handleDistributorFilterChange = (values:CheckboxType[]) => {
    
    const distributorsValuesMapped =  values
    .filter((value) => (values.length === 0 ? false : value.checked))
    .map((value) => Number(value.id))
    
    setPaginationQuery((previous) => ({
      ...previous,
      page: 1,
      filter:{
        ...previous.filter,
        distributorsId: distributorsValuesMapped
      }
    }))
  }
  const handleManagerFilterChange =   (values:CheckboxType[]) => {
    
    const managersValuesMapped = values
    .filter((value) => (values.length === 0 ? false : value.checked))
    .map((value) => Number(value.id))
    
    setPaginationQuery((previous) => ({
      ...previous,
      page: 1,
      filter:{
        ...previous.filter,
        gestorsId: managersValuesMapped
      }
    }))
  }

  const handleOpenEditModal = ({
    clientId,
    clientStatus,
  }: SelectedClient) => {
    setSelectedClient({ clientId, clientStatus })
    setEditModalOpen(true)
  }

  const onSuccess = async () => {
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [dateSelected, paginationQuery, sort])

  if (!tablePagination || !cards || isLoading) {
    return <Loading />
  }

  return (
    <>
      <DateFilter onChange={(value) => setDateSelected(value)} currentValue={dateSelected} />
      <Header>
        <WidgetGroup>
          <WidgetInfoV2
            icon={StorefrontOutlined}
            title='Total de UCs'
            measurementText=""
            value={Math.trunc(cards.totalUcs.count)}
            inRelationToText='em relação ao mês anterior'
            variation={parseFloat(cards.totalUcs.percentage.toFixed(2))}
          />
          <WidgetInfoV2
            icon={PowerOutlined}
            title='Total de Usinas'
            measurementText=""
            value={Math.trunc(cards.totalPowerPlants.count)}
            inRelationToText='em relação ao mês anterior'
            variation={parseFloat(cards.totalPowerPlants.percentage.toFixed(2))}
          />
        </WidgetGroup>
        <WidgetGroup>
          <WidgetInfoV2
            icon={BoltIcon}
            title='Total de Geração (kW)'
            measurementText="kW"
            value={Math.trunc(cards.totalPower.count)}
            inRelationToText='em relação ao mês anterior'
            variation={parseFloat(cards.totalPower.percentage.toFixed(2))}
          />
          <WidgetInfoV2
            icon={WalletIcon}
            title='Total de Valores Pagos'
            measurementText=""
            value={`R$ ${
              Number(
                Math.trunc(cards.totalPastValueDue.count)
                  ? Math.trunc(cards.totalPastValueDue.count).toFixed(2)
                  : '0,00',
              ).toLocaleString('pt-BR') === 'NaN'
                ? '0,00'
                : Number(
                    Math.trunc(cards.totalPastValueDue.count)
                      ? Math.trunc(cards.totalPastValueDue.count).toFixed(2)
                      : '0,00',
                  ).toLocaleString('pt-BR')
            }`}
            inRelationToText='em relação ao mês anterior'
            variation={parseFloat(cards.totalPastValueDue.percentage.toFixed(2))}
          />
        </WidgetGroup>
      </Header>
      <Stack direction='row' marginLeft='auto' gap={2} marginBottom={4} marginTop={4}>
        <DistributorFilter
          isFilterActive 
          values={(paginationQuery?.filter?.distributorsId as number[]) ?? []}
          onFilter={handleDistributorFilterChange}
        />
        <MultiManagerFilter
          values={(paginationQuery?.filter?.gestorsId as number[]) ?? []}
          onFilter={handleManagerFilterChange}
        />
        <TicketsCount>Total de Clientes: {tablePagination?.pageInfo?.totalCount ?? 0}</TicketsCount>

        <SearchInput
          defaultValue={paginationQuery.filter?.search as string}
          onSearch={(search) => {
            setPaginationQuery((previous) => ({
              ...previous,
              page: 1,
              filter: {
                ...(previous.filter ?? {}),
                search,
              },
            }))
          }}
        />

        <DownloadSheet dateSelected={dateSelected} onDownload={handleSpreadsheetDownload} />

        <TableConfig
          itemsPerPage={paginationQuery.itemsPerPage ?? 15}
          setItemsPerPage={(itemsPerPage) => {
            setPaginationQuery((previous) => ({
              ...previous,
              page: 1,
              itemsPerPage,
            }))
          }}
          tableColumns={tableColumns}
          setTableColumns={setTableColumns}
        />
      </Stack>
      <TableV2
        alignText='center'
        rows={tablePagination.data.map((item) => ({ ...item, id: item.clientId, totalPower: item.totalPower.toFixed(0) }))}
        columns={tableColumns}
        onSort={setSort}
        sort={sort}
      >
        <TablePagination
          currentPageItemCount={tablePagination.data.length}
          pageInfo={tablePagination.pageInfo}
          
          onChange={(page) => {
            setPaginationQuery((previous) => ({
              ...previous,
              page,
            }))
          }}
        />
      </TableV2>

      {/* {editModalOpen && (
        <EditGestorModal
          gestorId={selectedClient?.clientId}
          gestorStatus={selectedClient?.clientStatus}
          onSuccess={onSuccess}
          onClose={() => setEditModalOpen(false)}
        />
      )} */}
    </>
  )
}
