import { useEffect, useState } from 'react'
import { useToast } from '../../../hooks/useToast'
import { IconButton, Stack, Tooltip } from '@mui/material'
import TemplateFieldModal from './components/TemplateFieldModal'
import { Add, Edit, Rule, Save, Upload } from '@mui/icons-material'
import LoadTemplateModal from './components/LoadTemplateModal'
import Loading from '../../../components/Loading'
import ValidationSchemaModal from './components/ValidationSchemaModal'
import CreateTemplateModal from './components/CreateTemplateModal'
import TemplateHtmlContent from './components/TemplateHtmlContent'
import { useInvoiceTemplate } from './hooks/useInvoiceTemplate'
import ModalDelete from '../../../components/Modal/ModalDelete'
import { paymentApi } from '../../../services/payment-api'
import * as _ from 'lodash'
import DownloadTemplatePreviewButton from './components/DownloadTemplatePreviewButton'

export default function InvoiceTemplatePage() {
  const [templateHtmlContent, setTemplateHtmlContent] = useState<string>()

  const [isLoadTemplateModalOpen, setLoadTemplateModalOpen] = useState<boolean>(false)
  const [isCreateTempalteModalOpen, setCreateTempalteModalOpen] = useState<boolean>(false)
  const [isValidationSchemaModalOpen, setValidationSchemaModalOpen] = useState<boolean>(false)
  const [isLoadingTemplate, setLoadingTemplate] = useState<boolean>(false)
  const [isSavingChanges, setSavingChanges] = useState<boolean>(false)

  const { toast } = useToast()

  const {
    templateConfig,
    templateData,
    setTemplateConfig,
    setTemplateData,
    previewTemplateContent,
  } = useInvoiceTemplate()

  const fetchHtmlTemplate = async () => {
    try {
      setLoadingTemplate(true)
      const templateContent = await previewTemplateContent()
      if (templateContent) {
        setTemplateHtmlContent(templateContent)
      }
    } catch (error) {
      toast({
        type: 'error',
        message: (error as Error)?.message ?? 'Falha ao buscar preview do template',
      })
    } finally {
      setLoadingTemplate(false)
    }
  }

  const updateInvoiceTemplate = async () => {
    if (!templateConfig) {
      return
    }
    try {
      const templateConfigCopy = _.cloneDeep(templateConfig)
      if (templateConfigCopy.configs.assets) {
        _.forEach(templateConfigCopy.configs.assets, (asset) => {
          _.unset(asset, 'url')
        })
      }
      await paymentApi.updateTemplate({
        templateConfig: templateConfigCopy,
        templateConfigId: templateConfig.id,
      })
      const templateDataResponse = await paymentApi.getTemplateData({
        templateConfigId: templateConfig.id,
        managerId: templateConfig.clientId,
      })
      setTemplateConfig(templateDataResponse.templateConfig)
      setTemplateData(templateDataResponse.templateData)
      toast({
        type: 'success',
        message: 'Template atualizado com sucesso',
      })
    } catch (error) {
      toast({
        type: 'error',
        message: (error as Error)?.message ?? 'Erro ao atualizar template',
      })
    }
  }

  useEffect(() => {
    fetchHtmlTemplate()
  }, [templateConfig, templateData])

  const [activeField, setActiveField] = useState<string>()

  return (
    <>
      {templateConfig && typeof activeField !== 'undefined' && (
        <TemplateFieldModal
          selectedFieldKey={activeField}
          setSelectedFieldKey={setActiveField}
          templateConfig={templateConfig}
          onSave={(templateConfig) =>
            setTemplateConfig((previous) =>
              previous
                ? {
                    ...previous,
                    configs: templateConfig,
                  }
                : previous,
            )
          }
          onClose={() => setActiveField(undefined)}
        />
      )}

      {isLoadTemplateModalOpen && (
        <LoadTemplateModal
          onClose={() => setLoadTemplateModalOpen(false)}
          onLoadTemplate={(params) => {
            const { templateConfig, templateData } = params
            setTemplateConfig(templateConfig)
            setTemplateData(templateData)
          }}
        />
      )}

      {isCreateTempalteModalOpen && (
        <CreateTemplateModal onClose={() => setCreateTempalteModalOpen(false)} />
      )}

      {isValidationSchemaModalOpen && templateConfig && (
        <ValidationSchemaModal
          value={templateConfig.validationSchema}
          setValue={(validationSchema) =>
            setTemplateConfig((previous) => ({
              ...(previous as any),
              validationSchema,
            }))
          }
          onClose={() => setValidationSchemaModalOpen(false)}
        />
      )}

      {isSavingChanges && (
        <ModalDelete
          openModal
          setModalOpen={setSavingChanges}
          buttonColor='darkGreen'
          text='Salvar alterações?'
          confirmButtonText='Salvar'
          textStyle={{ fontWeight: 'bold', fontSize: '22px' }}
          description='Ao salvar as alterações, o template do cliente será atualizado imediatamente.'
          descriptionStyle={{ color: 'red', marginBottom: '20px' }}
          action={updateInvoiceTemplate}
        />
      )}

      <Stack alignItems='center' justifyContent='center'>
        <div style={{ width: '1000px', position: 'relative' }}>
          <div
            style={{
              position: 'sticky',
              backgroundColor: '#e6e6e6',
              padding: '8px',
              border: '1px solid #ccc',
            }}
          >
            <Stack gap={2} direction='row'>
              <Tooltip title={<p style={{ fontSize: 15 }}>Novo Template</p>} placement='bottom'>
                <IconButton onClick={() => setCreateTempalteModalOpen(true)}>
                  <Add />
                </IconButton>
              </Tooltip>
              <Tooltip title={<p style={{ fontSize: 15 }}>Carregar Template</p>} placement='bottom'>
                <IconButton onClick={() => setLoadTemplateModalOpen(true)}>
                  <Upload />
                </IconButton>
              </Tooltip>
              <Tooltip title={<p style={{ fontSize: 15 }}>Editar Template</p>} placement='bottom'>
                <IconButton onClick={() => setActiveField('')} disabled={!templateHtmlContent}>
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={<p style={{ fontSize: 15 }}>Formatação de Dados</p>}
                placement='bottom'
              >
                <IconButton onClick={() => setValidationSchemaModalOpen(true)} disabled={!templateHtmlContent}>
                  <Rule />
                </IconButton>
              </Tooltip>
              <DownloadTemplatePreviewButton disabled={!templateHtmlContent} />
              <Tooltip title={<p style={{ fontSize: 15 }}>Salvar Alterações</p>} placement='bottom'>
                <IconButton sx={{ marginLeft: 'auto' }} onClick={() => setSavingChanges(true)} disabled={!templateHtmlContent}>
                  <Save />
                </IconButton>
              </Tooltip>
            </Stack>
          </div>

          {isLoadingTemplate && !templateHtmlContent && <Loading style={{ marginTop: '16px' }} />}

          {templateHtmlContent && (
            <TemplateHtmlContent
              htmlContent={templateHtmlContent}
              isLoading={isLoadingTemplate}
              onClickField={(key) => setActiveField(key)}
            />
          )}
        </div>
      </Stack>
    </>
  )
}
