import _ from 'lodash'

export function removeEmptyFields(obj: Record<string, unknown>) {
  const clonedObj = _.cloneDeep(obj)
  function clean(item: any) {
    _.forEach(item, (value, key) => {
      if (_.isNull(value) || _.isUndefined(value)) {
        delete item[key]
      } else if (_.isObject(value)) {
        clean(value)
      }
    })
  }
  clean(clonedObj)
  return clonedObj
}

export function countDecimals(num: number) {
  if (!isNaN(num) && Math.floor(num) !== num) {
    const numParts = num.toString().split('.')
    return numParts.length > 1 ? numParts[1].length : 0
  }
  return 0
}

export function parseNumbers(obj: Record<string, unknown>) {
  const clonedObj = _.cloneDeep(obj)
  function clean(item: any) {
    _.forEach(item, (value, key) => {
      if (typeof value === 'string' && !isNaN(Number(value))) {
        const decimals = countDecimals(parseFloat(value))
        const updatedValue = !isNaN(Number(value))
          ? parseFloat(Number(value).toFixed(decimals))
          : value
        item[key] = updatedValue
      }

      if (_.isNull(value) || _.isUndefined(value)) {
        delete item[key]
      } else if (_.isObject(value)) {
        clean(value)
      }
    })
  }
  clean(clonedObj)
  return clonedObj
}

export function getNestedLevelAndObject(obj: any, key: string) {
  const parts = key.split('.')
  let current = obj
  let level = 0
  let parentObject = null
  let parentKey = ''

  for (let i = 0; i < parts.length; i++) {
    const part = parts[i]

    const arrayMatch = part.match(/(\w+)\[(\d+)\]/)
    if (arrayMatch) {
      const arrayKey = arrayMatch[1]
      const index = parseInt(arrayMatch[2], 10)

      if (_.has(current, arrayKey) && Array.isArray(current[arrayKey])) {
        parentObject = current[arrayKey]
        parentKey = arrayKey
        current = current[arrayKey][index]
        level++
        continue
      }
    }

    if (_.has(current, part)) {
      parentObject = current
      parentKey = part
      current = current[part]
      level++
    }
  }

  if (level === 1 && _.isArray(_.get(obj, key))) {
    return {
      level,
      parentKey,
    }
  }

  return {
    level,
    parentKey,
    parentObject,
  }
}
