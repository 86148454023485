import React, { createContext, useContext, useEffect, useReducer, useState } from 'react'
import { DefaultTheme } from 'styled-components'
import { IRoute, userRoutes } from '../../components/Sidebar/utils'
import { LocalStorageKeys } from '../../interfaces/local-storage'
import { GlobalActionType, GlobalStateType } from '../../interfaces/states/global-state'
import { Theme as AppDefaultTheme } from './../../styles/theme'
import { globalReducer } from './reducers/reducer'
import { themes } from './themeForUsers'
import { Alert, alertsService } from '../../services/alerts'

export type GlobalContextType = {
  state: GlobalStateType
  dispatch: React.Dispatch<GlobalActionType>
  Theme: DefaultTheme
  setTheme: React.Dispatch<React.SetStateAction<DefaultTheme>>
  routes: Array<IRoute>
  setRoutes: React.Dispatch<React.SetStateAction<Array<IRoute>>>
  locationLoading: boolean
  setLocationLoading: (value: boolean) => void
  locationError: string
  setLocationError: (value: string) => void
}

export const GlobalContext = createContext<GlobalContextType>({} as GlobalContextType)
export const userDashboard = {
  GESTOR: '/dashboard/generator',
  CONSUMIDOR: '/dashboard/consumer',
  MASTER: '/dashboard/super',
}

export function GlobalContextProvider({ children }: { children: React.ReactNode }) {
  const auth = JSON.parse(localStorage.getItem(LocalStorageKeys.AUTH) as string)
  const [globalState, dispatch] = useReducer(globalReducer, {
    auth: auth
      ? {
        id: auth.id,
        email: auth.email,
        token: auth.token,
        isProvisoryPassword: auth.isProvisoryPassword,
        customer: auth.customer
          ? {
            id: auth.customer.id,
            type: auth.customer.type,
            canUpdatePayment: auth.customer.canUpdatePayment,
            status: auth.customer.status,
          }
          : undefined,
      }
      : undefined,
  })
  const [Theme, setTheme] = useState<DefaultTheme>(AppDefaultTheme)
  const typeUser = globalState.auth?.customer?.type
  const [routes, setRoutes] = useState<Array<IRoute>>(
    userRoutes[typeUser as keyof typeof userRoutes],
  )
  const [locationLoading, setLocationLoading] = useState(false)
  const [locationError, setLocationError] = useState('')

  useEffect(() => {
    if (globalState.auth?.customer?.id === 3594 || globalState.auth?.customer?.id === 6050) setRoutes(userRoutes['NO_PAYMENTS'])
    else setRoutes(userRoutes[typeUser as keyof typeof userRoutes])
  }, [typeUser])

  useEffect(() => {
    const userId = globalState.auth?.customer?.id

    if (userId) {
      const searchUserTheme = themes.find(({ for: forUsers }) => forUsers.includes(userId))

      if (searchUserTheme) {
        setTheme({
          ...AppDefaultTheme,
          colors: {
            ...AppDefaultTheme.colors,
            ...searchUserTheme.theme.colors,
          },
          logos: {
            ...AppDefaultTheme.logos,
            ...searchUserTheme.theme.logos,
          },
        })
        document.title = searchUserTheme.theme.logos.tabTitle
        // change Tab icon
        let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
        if (!link) {
          link = document.createElement('link')
          link.rel = 'icon'
          document.getElementsByTagName('head')[0].appendChild(link)
        }
        link.href = searchUserTheme.theme.logos.icon.toString()
      } else {
        setTheme(AppDefaultTheme)
      }
    } else {
      setTheme(AppDefaultTheme)
    }
  }, [globalState.auth?.email])

  useEffect(() => {
    const userId = globalState.auth?.customer?.id

    if (userId) {
      alertsService.findManyPerUser().then(items => {
        dispatch({
          type: 'SET_ALERTS',
          payload: items?.map(item => ({
            ...item,
            visible: true
          }))
        })
      })
    }
  },
    [globalState.auth?.email]
  )

  return (
    <GlobalContext.Provider
      value={{
        state: globalState,
        locationLoading,
        setLocationLoading,
        dispatch,
        Theme,
        setTheme,
        routes,
        setRoutes,
        locationError,
        setLocationError,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalContext = () => {
  const context = useContext(GlobalContext)

  if (!context) {
    throw new Error('Provider not found')
  }

  return context
}
