import { useEffect, useState } from 'react'
import { CheckboxType } from '../../../../interfaces/checkbox'
import { useToast } from '../../../../hooks/useToast'
import { powerPlantListGet } from '../../../../services/requests/user-requests'
import { FloatingCheckboxes } from '../../../../components/FloatingCheckboxes'

interface Props {
  value: number[]
  onChange: (value: number[]) => void
}

export default function PowerPlantSelect(props: Props) {
  const { value, onChange } = props

  const [powerPlants, setPowerPlants] = useState<CheckboxType[]>([])

  const { toast } = useToast()

  const fetchPowerPlants = async () => {
    try {
      const response = await powerPlantListGet()
      const powerPlantPayload = response.data.map((powerPlant: { id: number; nome: string }) => {
        return {
          id: powerPlant.id,
          label: powerPlant.nome,
          checked: value.includes(powerPlant.id),
        }
      })
      setPowerPlants(powerPlantPayload)
    } catch (error: any) {
      toast({
        type: 'error',
        message: error.message ?? 'Erro ao buscar distribuidoras',
      })
    }
  }

  useEffect(() => {
    fetchPowerPlants()
  }, [])

  return (
    <FloatingCheckboxes
      isFilterActive={powerPlants.some(({ checked }) => checked === true)}
      label='Usinas'
      options={powerPlants}
      selectableAll
      searchable
      submitAction={(checkTypes) => {
        onChange(
          checkTypes
            .filter((distributor) => distributor.checked)
            .map((distributor) => Number(distributor.id)),
        )
      }}
    />
  )
}
