import { Select } from '@mui/material';
import styled from 'styled-components';

export const CustomSelect = styled(Select)<{isSecondaryColor: boolean}>`
  width: 100%;
  border-radius: 8px !important;
  
  & .MuiSelect-select {
    display: flex;
    align-items: center;
    padding: 0px 14px !important;
    height: 45px !important;
    background: ${props => props.isSecondaryColor ? '#E4E4E4' : '#f1f1f1'};
    border-radius: 8px !important;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: #f1f1f1; // Default border color
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #f1f1f1; // Keep the same color on hover
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.colors.darkishGreen}; // Color when the select is focused
  }
  &.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #d32f2f; // Color when there is an error
  }
  & .MuiSelect-icon {
    margin-right: 18px;
  }
`;
