import { SchemaObject } from '../../../../../services/payment-api'
import Modal from '../../../../../components/ModalV2'
import { StickyFooter } from '../../../InvoiceValidation/components/InvoiceModal/styles'
import { Button } from '../../../../../components/Button'
import { useState } from 'react'
import { Stack } from '@mui/material'
import { Card } from '../TemplateFields/styles'
import _ from 'lodash'
import ValidationSchemaObject from '../ValidationSchemaObject'
import CreateValidationSchemaObjectModal from '../CreateValidationSchemaObjectModal'

type ValidationSchema = Record<string, SchemaObject>

interface Props {
  value: ValidationSchema
  setValue: (value: ValidationSchema) => void
  onClose: () => void
}

export default function ValidationSchemaModal(props: Props) {
  const { value, setValue, onClose } = props

  const [temporaryValidationSchema, setTemporaryValidationSchema] =
    useState<ValidationSchema>(value)

  const [isCreatingObject, setCreatingObject] = useState<boolean>(false)

  const setFieldValue = (key: string, value: unknown) => {
    setTemporaryValidationSchema((previous) => {
      const updatedSchema = { ...previous }
      _.set(updatedSchema, key, value)
      return updatedSchema
    })
  }

  const saveChanges = () => {
    setValue(temporaryValidationSchema)
    onClose()
  }

  if (isCreatingObject) {
    return (
      <CreateValidationSchemaObjectModal
        onCreate={(data) => setFieldValue(data.key, data.schema)}
        onClose={() => setCreatingObject(false)}
      />
    )
  }

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        width: '100%',
        position: 'relative',
        height: '100%',
        maxHeight: '750px',
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        Formatação de Dados
        <Modal.CloseButton alignLeft />
      </Modal.Title>
      <Modal.Body
        style={{ overflowY: 'auto', display: 'flex', flexDirection: 'column', gap: '16px' }}
      >
        {Object.entries(temporaryValidationSchema).map(([key, value]) => (
          <Card key={`validation-schema-item-${key}`}>
            <strong>{key}</strong>
            <ValidationSchemaObject
              value={value}
              setFieldValue={(fieldKey, value) => setFieldValue(`${key}.${fieldKey}`, value)}
            />
          </Card>
        ))}
      </Modal.Body>
      <StickyFooter
        style={{
          padding: '16px',
          marginTop: 'auto',
        }}
      >
        <Stack direction='column' gap={2} width='100%'>
          <Button
            containerStyle={{ margin: 0, border: '2px solid #023b21' }}
            mode='white'
            text='Adicionar Campo'
            onClick={() => setCreatingObject(true)}
          />
          <Button containerStyle={{ margin: 0 }} text='Salvar Alterações' onClick={saveChanges} />
        </Stack>
      </StickyFooter>
    </Modal.Provider>
  )
}
