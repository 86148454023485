import { FormikProvider, useFormik, Form } from "formik"
import {
  Container,
  Card,
} from "./styles"
import { InputV2 } from "../../../../components/InputV2"
import { ITests } from "../../../../components/Pdfs/utils/interface"
import { useMemo, useState } from "react"
import { useToast } from "../../../../hooks/useToast"
import Modal from "../../../../components/ModalV2"
import { UploadFile } from "../../../../services/requests/types"
import * as Yup from 'yup'
import { Button } from "../../../../components/Button"

interface EditCopelOpenAIModalProps {
  items: UploadFile[]
  itemId: string | null
  onClose: () => void
  updateItem: (id: string, data: Omit<ITests, 'id' | 'fatura_pdf'>) => void
}

const NUMBER_LABELS = [
  "cosip",
  "consumo_spt_qt",
  "consumo_spt_tar",
  "consumo_spt_tar_crua",
  "consumo_spt_val",
  "consumo_p_qt",
  "consumo_p_tar",
  "consumo_p_tar_crua",
  "consumo_p_val",
  "consumo_fp_qt",
  "consumo_fp_tar",
  "consumo_fp_tar_crua",
  "consumo_fp_val",
  "energia_comp_spt_qt",
  "energia_comp_spt_tar",
  "energia_comp_spt_val",
  "energia_comp_spt_crua",
  "energia_comp_fp_qt",
  "energia_comp_fp_tar",
  "energia_comp_fp_val",
  "energia_comp_p_qt",
  "energia_comp_p_tar",
  "energia_comp_p_val",
  "creditos_recebidos",
  "energia_comp_total_qt",
  "energia_comp_total_tar",
  "energia_comp_total_val",
  "consumo_total_faturado_val",
  "consumo_total_faturado_tar",
  "consumo_total_faturado_qt",
  "valor_total_fatura",
  "creditos_estoque_p",
  "creditos_estoque_fp",
  "creditos_estoque_spt",
  "creditos_estoque_tot",
  "consumo_spt_te_tar_crua",
  "consumo_spt_tusd_tar_crua",
  "energia_comp_int_tar",
  "energia_comp_int_qt",
  "energia_comp_int_val",
  "consumo_int_tar",
  "consumo_int_qt",
  "consumo_int_val",
  "tot_band_val",
  "tot_dev_band_val",
]

export const validationSchema = Yup.object({
  consumo_spt_te_tar: Yup.number().typeError('A tarifa TE do consumo deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_spt_tusd_tar: Yup.number().typeError('A tarifa TUSD do consumo deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  tot_trib_icms_aliq: Yup.number().typeError('A aliquota do ICMS deve ser um numero valido, Ex: 0.25')
    .test("isPercentage", "As aliquotas sao cadastradas em numero quebrado, Ex: 0.12 seria cadastrado como 0.12", (item) => {
      const value = Number(item)
      if (!value || Number.isNaN(value) || value === 0) return true

      return value <= 1
    })
    .required("Aliquota do ICMS precisa ser informada"),
  tot_trib_cofins_aliq: Yup.number().typeError('A aliquota do COFINS deve ser um numero valido, Ex: 0.25')
    .test("isPercentage", "As aliquotas sao cadastradas em numero quebrado, Ex: 0.25 seria cadastrado como 0.25", (item) => {
      const value = Number(item)
      if (!value || Number.isNaN(value) || value === 0) return true

      return value <= 1
    })
    .required("Aliquota do COFINS precisa ser informada"),
  tot_trib_pis_aliq: Yup.number().typeError('A aliquota do PIS deve ser um numero valido, Ex: 0.76')
    .test("isPercentage", "As aliquotas sao cadastradas em numeros quebrados, Ex: 0.0075 seria cadastrado como 0.0075", (item) => {
      const value = Number(item)
      if (!value || Number.isNaN(value) || value === 0) return true

      return value <= 1
    })
    .required("Aliquota do PIS precisa ser informada"),
  tot_trib_icms_val: Yup.number().typeError('O valor do ICMS deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  tot_trib_icms_base: Yup.number().typeError('A base do ICMS deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  tot_trib_pis_val: Yup.number().typeError('O valor do PIS deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  tot_trib_cofins_val: Yup.number().typeError('O valor do COFINS deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  tot_trib_pis_base: Yup.number().typeError('A base do PIS deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  tot_trib_cofins_base: Yup.number().typeError('A base do COFINS deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  med_ger_spt_quant: Yup.number().typeError('A quantidade SPT de geracao deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  med_ger_fp_quant: Yup.number().typeError('A quantidade FP de geracao deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  med_ger_p_quant: Yup.number().typeError('A quantidade P de gerecao deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  med_ger_tot_quant: Yup.number().typeError('A quantidade total de geracao deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  dias_faturados: Yup.number().typeError('A quantidade de dias faturados deve ser um numero valido, Ex: 2500.25').required("A quantidade de dias faturados precisa ser informada"),
  mes_referencia: Yup.string().typeError('O mes de referencia deve ser um texto no formato ano-mes-dia').required("O mes de referencia precisa ser informado")
    .test("isValidDate", "O formato do mes de referencia deve ser 2024-01-01 (ANO-MES-01)", (item) => {
      const regex = /^[0-9]{4}-[0-9]{2}-01$/
      if (!item) return true

      return regex.test(item)
    }),
  uc: Yup.string().typeError('O codigo da UC deve ser um texto').required('O codigo da UC precisa ser informado'),
  data_vencimento: Yup.string().typeError('A data de vencimento deve ser um texto no formato ano-mes-dia').required('A data de vencimento precisa ser informado')
    .test("isValidDate", "O formato da data de vencimento deve ser 2025-01-15 (ANO-MES-DIA)", (item) => {
      const regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/
      if (!item) return true

      return regex.test(item)
    }),
  data_emissao: Yup.string().typeError('A data de emissao deve ser um texto no formato ano-mes-dia').required('A data de emissao e obrigatoria')
    .test("isValidDate", "O formato da data de emissao deve ser 2025-01-15 (ANO-MES-DIA)", (item) => {
      const regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/
      if (!item) return true

      return regex.test(item)
    }),
  data_leitura_anterior: Yup.string().typeError('A data de leitura anterior deve ser um texto no formato ano-mes-dia').required('A data de leitura anterior e obrigatoria')
    .test("isValidDate", "O formato da data de leitura anterior deve ser 2025-01-15 (ANO-MES-DIA)", (item) => {
      const regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/
      if (!item) return true

      return regex.test(item)
    }),
  data_leitura_atual: Yup.string().typeError('A data de leitura atual deve ser um texto no formato ano-mes-dia').required('A data de leitura atual e obrigatoria')
    .test("isValidDate", "O formato da data de leitura atual deve ser 2025-01-15 (ANO-MES-DIA)", (item) => {
      const regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/
      if (!item) return true

      return regex.test(item)
    }),
  data_proxima_leitura: Yup.string().typeError('A data da proxima leitura deve ser um texto no formato ano-mes-dia').default('').required('A data da proxima leitura e obrigatoria')
    .test("isValidDate", "O formato da data de proxima leitura deve ser 2025-01-15 (ANO-MES-DIA)", (item) => {
      const regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/
      if (!item) return true

      return regex.test(item)
    }),
  nome: Yup.string().typeError('O nome deve ser um texto valido').default('').nullable(),
  endereco: Yup.string().typeError('O endereco deve ser um texto valido').default('').nullable(),
  cep: Yup.string().typeError('O CEP deve ser um texto valido').default('').nullable(),
  bairro: Yup.string().typeError('O bairro deve ser um texto valido').default('').nullable(),
  cidade: Yup.string().typeError('A cidade deve ser um texto valido').default('').nullable(),
  doc: Yup.string().typeError('O documento deve ser um texto valido').default('').nullable(),
  uf: Yup.string().typeError('O UF deve ser um texto valido').default('').nullable(),
  numero: Yup.string().typeError('O numero deve ser um valor valido').default('').nullable(),
  grupo: Yup.string().typeError('O grupo deve ser uma letra valida').max(1, 'O grupo e indicado por apenas uma letra').default('').nullable(),
  subgrupo: Yup.string().typeError('O subgrupo deve ser um texto validonumero').max(3, 'O subgrupo e indicado por apenas 2 para 3 letras').default('').nullable(),
  classe: Yup.string().typeError('A classe deve ser um texto valido').default('').nullable(),
  inscricaoEstadual: Yup.string().typeError('O X deve ser um numero valido, Ex: 2500.25').default('').nullable(),
  demandaFP: Yup.string().typeError('A demanda FP deve ser um numero valido, Ex: 2500.25 valido').default('').nullable(),
  tensaoMin: Yup.string().typeError('A tensao minima deve ser um numero valido, Ex: 2500.25 valido').default('').nullable(),
  medidor: Yup.string().typeError('O medidor deve ser um valor valido').default('').nullable(),
  fases: Yup.string().typeError('O tipo de fases deve ser um texto').default('').nullable(),
  equipamento: Yup.string().typeError('O equipamento deve ser um texto').default('').nullable(),
  cosip: Yup.number().typeError('O COSIP deve ser um numero valido, Ex: 2500.25 valido').default(0).nullable(),
  consumo_spt_qt: Yup.number().typeError('A quantidade do consumo SPT deve ser um numero valido, Ex: 2500.25').default(0).nullable()
    .test("isPositive", "Os valores de consumo devem ser positivos", (item) => {
      if (!item || item === 0) return true

      return Number(item) >= 0
    }),
  consumo_spt_tar: Yup.number().typeError('A tarifa do consumo SPT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_spt_tar_crua: Yup.number().typeError('A tarifa crua do consumo SPT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_spt_val: Yup.number().typeError('O valor do consumo SPT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_p_qt: Yup.number().typeError('A quantidade do consumo P deve ser um numero valido, Ex: 2500.25').default(0).nullable()
    .test("isPositive", "Os valores de consumo devem ser positivos", (item) => {
      if (!item || item === 0) return true

      return Number(item) >= 0
    }),
  consumo_p_tar: Yup.number().typeError('A tarifa do consumo P deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_p_tar_crua: Yup.number().typeError('A tarifa crua do consumo P deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_p_val: Yup.number().typeError('O valor do consumo P deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_fp_qt: Yup.number().typeError('A quantidade do consumo FP deve ser um numero valido, Ex: 2500.25').default(0).nullable()
    .test("isPositive", "Os valores de consumo devem ser positivos", (item) => {
      if (!item || item === 0) return true

      return Number(item) >= 0
    }),
  consumo_fp_tar: Yup.number().typeError('A tarifa do consumo FP deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_fp_tar_crua: Yup.number().typeError('A tarifa crua do consumo FP deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_fp_val: Yup.number().typeError('O valor do consumo FP deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_spt_qt: Yup.number().typeError('A quantidade da energia compensada SPT deve ser um numero valido, Ex: 2500.25').default(0).nullable()
    .test("isNegative", "Os valores de compensado devem ser negativos", (item) => {
      if (!item || item === 0) return true

      return Number(item) <= 0
    })
    .test("smallerThanOrEquals", "O compensado SPT deve ser menor que o consumo SPT", (item, context) => {
      if (
        !item ||
        !context.parent.consumo_spt_qt
      ) return true

      const consumption = Number(context.parent.consumo_spt_qt)
      const compensated = Number(item)

      const isValid = compensated <= consumption
      return isValid
    }),
  energia_comp_spt_tar: Yup.number().typeError('A tarifa da energia compensada SPT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_spt_val: Yup.number().typeError('O valor da energia compensada SPT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_spt_crua: Yup.number().typeError('A tarifa crua da energia compensada SPT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_fp_qt: Yup.number().typeError('A quantidade da energia compensada FP deve ser um numero valido, Ex: 2500.25').default(0).nullable()
    .test("isNegative", "Os valores de compensado devem ser negativos", (item) => {
      if (!item || item === 0) return true

      return Number(item) <= 0
    })
    .test("smallerThanOrEquals", "O compensado FP deve ser menor que o consumo FP", (item, context) => {
      if (
        !item ||
        !context.parent.consumo_fp_qt
      ) return true

      const consumption = Number(context.parent.consumo_fp_qt)
      const compensated = Number(item)

      const isValid = compensated <= consumption
      return isValid
    }),
  energia_comp_fp_tar: Yup.number().typeError('A tarifa da energia compensada FP deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_fp_val: Yup.number().typeError('O valor da energia compensada FP deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_p_qt: Yup.number().typeError('A quantidade da energia compensada P deve ser um numero valido, Ex: 2500.25').default(0).nullable()
    .test("isNegative", "Os valores de compensado devem ser negativos", (item) => {
      if (!item || item === 0) return true

      return Number(item) <= 0
    })
    .test("smallerThanOrEquals", "O compensado P deve ser menor que o consumo P", (item, context) => {
      if (
        !item ||
        !context.parent.consumo_p_qt
      ) return true

      const consumption = Number(context.parent.consumo_p_qt)
      const compensated = Number(item)

      const isValid = compensated <= consumption
      return isValid
    }),
  energia_comp_p_tar: Yup.number().typeError('A tarifa da energia compensada P deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_p_val: Yup.number().typeError('O valor da energia compensada P deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  creditos_recebidos: Yup.number().typeError('Os creditos recebidos deve ser um numero valido, Ex: 2500.25 valido').default(0).nullable(),
  energia_comp_total_qt: Yup.number().typeError('A quantidade da energia compensada total deve ser um numero valido, Ex: 2500.25').default(0).nullable()
    .test("smallerThanOrEquals", "O compensado total deve ser menor que o consumo total", (item, context) => {
      if (
        !item ||
        !context.parent.consumo_total_faturado_qt
      ) return true

      const consumption = Number(context.parent.consumo_total_faturado_qt)
      const compensated = Number(item)

      const isValid = compensated <= consumption
      return isValid
    }),
  energia_comp_total_tar: Yup.number().typeError('A tarifa da energia compensada total deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_total_val: Yup.number().typeError('O valor da energia compensada total deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_total_faturado_val: Yup.number().typeError('O valor do consumo total faturado deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_total_faturado_tar: Yup.number().typeError('A tarifa do consumo total faturado deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_total_faturado_qt: Yup.number().typeError('A quantidade do consumo total faturado deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  valor_total_fatura: Yup.number().typeError('O valor total da fatura deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  creditos_estoque_p: Yup.number().typeError('Os creditos em estoque P devem ser um numero').default(0).nullable(),
  creditos_estoque_fp: Yup.number().typeError('Os creditos em estoque FP devem ser um numero').default(0).nullable(),
  creditos_estoque_spt: Yup.number().typeError('Os creditos em estoque SPT devem ser um numero').default(0).nullable(),
  creditos_estoque_tot: Yup.number().typeError('Os creditos em estoque totais devem ser um numero').default(0).nullable(),
  consumo_spt_te_tar_crua: Yup.number().typeError('A tarifa crua do consumo SPT TE deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_spt_tusd_tar_crua: Yup.number().typeError('A tarifa crua do consumo SPT TUSD deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_int_tar: Yup.number().typeError('A tarifa da energia compensada INT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_int_qt: Yup.number().typeError('A quantidade da energia compensada INT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_int_val: Yup.number().typeError('O valor da energia compensada INT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_int_tar: Yup.number().typeError('A tarifa do consumo INT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_int_qt: Yup.number().typeError('A quantidade do consumo INT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_int_val: Yup.number().typeError('O valor do consumo INT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  tot_band_val: Yup.number().typeError('O valor da bandeira deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  tot_dev_band_val: Yup.number().typeError('O valor da devolucao da bandeira deve ser um numero valido, Ex: 2500.25').default(0).nullable()
    .test("isNegative", "Os valores de devolucao devem ser negativos", (item) => {
      if (!item || item === 0) return true

      return Number(item) <= 0
    })
})


export function EditCopelOpenAIModal({ items, itemId, updateItem, onClose }: EditCopelOpenAIModalProps) {
  const { toast } = useToast()

  const item = useMemo(() => items.find(row => row.id === itemId)?.data ?? {}, [itemId])
  const [data, setData] = useState<Omit<ITests, 'id' | 'fatura_pdf'>>({
    consumo_spt_te_tar: 0,
    consumo_spt_tusd_tar: 0,
    tot_trib_icms_aliq: 0,
    tot_trib_cofins_aliq: 0,
    tot_trib_pis_aliq: 0,
    tot_trib_icms_val: 0,
    tot_trib_icms_base: 0,
    tot_trib_pis_val: 0,
    tot_trib_cofins_val: 0,
    tot_trib_pis_base: 0,
    tot_trib_cofins_base: 0,
    med_ger_spt_quant: 0,
    med_ger_tot_quant: 0,
    med_ger_fp_quant: 0,
    med_ger_p_quant: 0,
    dias_faturados: 0,
    mes_referencia: '',
    uc: '',
    data_vencimento: '',
    data_emissao: '',
    data_leitura_anterior: '',
    data_leitura_atual: '',
    data_proxima_leitura: '',
    nome: '',
    endereco: '',
    cep: '',
    bairro: '',
    cidade: '',
    doc: '',
    uf: '',
    numero: '',
    grupo: '',
    subgrupo: '',
    classe: '',
    inscricaoEstadual: '',
    demandaFP: '',
    tensaoMin: '',
    medidor: '',
    fases: '',
    equipamento: '',
    cosip: 0,
    consumo_spt_qt: 0,
    consumo_spt_tar: 0,
    consumo_spt_tar_crua: 0,
    consumo_spt_val: 0,
    consumo_p_qt: 0,
    consumo_p_tar: 0,
    consumo_p_tar_crua: 0,
    consumo_p_val: 0,
    consumo_fp_qt: 0,
    consumo_fp_tar: 0,
    consumo_fp_tar_crua: 0,
    consumo_fp_val: 0,
    energia_comp_spt_qt: 0,
    energia_comp_spt_tar: 0,
    energia_comp_spt_val: 0,
    energia_comp_spt_crua: 0,
    energia_comp_fp_qt: 0,
    energia_comp_fp_tar: 0,
    energia_comp_fp_val: 0,
    energia_comp_p_qt: 0,
    energia_comp_p_tar: 0,
    energia_comp_p_val: 0,
    creditos_recebidos: 0,
    energia_comp_total_qt: 0,
    energia_comp_total_tar: 0,
    energia_comp_total_val: 0,
    consumo_total_faturado_val: 0,
    consumo_total_faturado_tar: 0,
    consumo_total_faturado_qt: 0,
    valor_total_fatura: 0,
    creditos_estoque_p: 0,
    creditos_estoque_fp: 0,
    creditos_estoque_spt: 0,
    creditos_estoque_tot: 0,
    exist: false,
    consumo_spt_te_tar_crua: 0,
    consumo_spt_tusd_tar_crua: 0,
    energia_comp_int_tar: 0,
    energia_comp_int_qt: 0,
    energia_comp_int_val: 0,
    consumo_int_tar: 0,
    consumo_int_qt: 0,
    consumo_int_val: 0,
    tot_band_val: 0,
    tot_dev_band_val: 0,
    ...item
  })

  const sendDataForm = useFormik({
    onSubmit: () => {
      if (!itemId?.length)
        return

      const updatedFields: Record<string, number> = {}

      for (const label of NUMBER_LABELS) {
        const value = (data as Record<string, unknown>)?.[label]
        if (!value) continue


        updatedFields[label] = Number.isNaN(Number(value)) ? 0 : Number(value)
      }

      updateItem(itemId, {
        ...data,
        ...updatedFields
      })
      toast({
        type: "success",
        message: "Campos atualizados"
      })
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: data,
  })

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target

    setData(previous => ({
      ...previous,
      [id]: value,
    }))
  }

  return (
    <Modal.Provider onClose={onClose} isOpen>
      <Container>
        <Modal.Title style={{ marginBottom: 24 }}>Atualizar os dados da fatura</Modal.Title>
        <Modal.CloseButton style={{ position: 'absolute', top: 5, right: 0 }} />

        <FormikProvider value={sendDataForm}>
          <Form>
            <Card>
              <strong>Dados da UC</strong>

              <InputV2
                required
                label='Número de instalação'
                placeholder='Ex: 988765432-1'
                value={data.uc}
                onChange={handleChange}
                id='uc'
              />

              <InputV2
                label='Grupo'
                placeholder='Ex: B'
                value={data.subgrupo}
                onChange={handleChange}
                id='grupo'
              />


              <InputV2
                label='Subgrupo'
                placeholder='Ex: B1'
                value={data.subgrupo}
                onChange={handleChange}
                id='subgrupo'
              />

              <InputV2
                label='Classe'
                placeholder='Ex: Trifasico'
                value={data.classe}
                onChange={handleChange}
                id='classe'
              />
            </Card>

            <Card>
              <strong>Datas da fatura</strong>

              <InputV2
                label='Mês de Referência'
                required
                id='mes_referencia'
                placeholder='Ex: 12/2024'
                value={data.mes_referencia}
                onChange={handleChange}
              />

              <InputV2
                required
                label='Data de Leitura Anterior'
                value={data.data_leitura_anterior}
                onChange={handleChange}
                id='data_leitura_anterior'
                placeholder='Ex: 01/11/2024'
              />

              <InputV2
                required
                label='Data de Leitura Atual'
                value={data.data_leitura_atual}
                onChange={handleChange}
                id='data_leitura_atual'
                placeholder='Ex: 01/12/2024'
              />

              <InputV2
                required
                label='Data da Próxima Leitura'
                value={data.data_proxima_leitura}
                onChange={handleChange}
                id='data_proxima_leitura'
                placeholder='Ex: 01/01/2025'
              />

              <InputV2
                required
                label='Data de Emissão'
                value={data.data_emissao}
                onChange={handleChange}
                id='data_emissao'
                placeholder='Ex: 26/11/2024'
              />

              <InputV2
                required
                label='Data de Vencimento'
                value={data.data_vencimento}
                onChange={handleChange}
                id='data_vencimento'
                placeholder='Ex: 13/02/2025'
              />

              <InputV2
                required
                label='Dias faturados'
                value={data.dias_faturados}
                onChange={handleChange}
                id='dias_faturados'
                placeholder='Ex: 31'
              />

            </Card>

            <Card>
              <strong>Dados do consumidor</strong>

              <InputV2
                label='CEP'
                placeholder='Ex: 98765432'
                value={data.cep}
                onChange={handleChange}
                id='cep'
              />

              <InputV2
                label='UF'
                placeholder='Ex: SP'
                value={data.uf}
                onChange={handleChange}
                id='uf'
              />

              <InputV2
                label='Cidade'
                placeholder='Ex: Sao Paulo'
                value={data.cidade}
                onChange={handleChange}
                id='cidade'
              />

              <InputV2
                label='Endereço'
                placeholder='Ex: Av. John doe'
                value={data.endereco}
                onChange={handleChange}
                id='endereco'
              />
            </Card>

            <Card>
              <strong>Consumo</strong>

              <InputV2
                label='Consumo SPT Quantidade'
                value={data.consumo_spt_qt}
                placeholder='Ex: 0'
                onChange={handleChange}
                id='consumo_spt_qt'
              />

              <InputV2
                label='Consumo FP Quantidade'
                value={data.consumo_fp_qt}
                placeholder='Ex: 321'
                onChange={handleChange}
                id='consumo_fp_qt'
              />

              <InputV2
                label='Consumo P Quantidade'
                value={data.consumo_p_qt}
                placeholder='Ex: 321'
                onChange={handleChange}
                id='consumo_p_qt'
              />

              <InputV2
                label='Consumo intermediário Quantidade'
                value={data.consumo_int_qt}
                placeholder='Ex: 321'
                onChange={handleChange}
                id='consumo_int_qt'
              />

              <InputV2
                label='Consumo Total Faturado Quantidade'
                value={data.consumo_total_faturado_qt}
                placeholder='Ex: 4323.23'
                onChange={handleChange}
                id='consumo_total_faturado_qt'
              />
              <div />

              <InputV2
                label='Consumo SPT Tarifa'
                value={data.consumo_spt_tar}
                placeholder='Ex: 0.31'
                onChange={handleChange}
                id='consumo_spt_tar'
              />

              <InputV2
                label='Consumo FP Tarifa'
                value={data.consumo_fp_tar}
                placeholder='Ex: 0.31'
                onChange={handleChange}
                id='consumo_fp_tar'
              />

              <InputV2
                label='Consumo P Tarifa'
                value={data.consumo_p_tar}
                placeholder='Ex: 0.321'
                onChange={handleChange}
                id='consumo_p_tar'
              />


              <InputV2
                label="Consumo intermediário Tarifa"
                value={data.consumo_int_tar}
                placeholder='Ex: 0.321'
                onChange={handleChange}
                id='consumo_int_tar'
              />
            </Card>

            <Card>
              <strong>Compensado</strong>

              <InputV2
                label='Energia Compensada SPT Quantidade'
                value={data.energia_comp_spt_qt}
                placeholder='Ex: 321'
                onChange={handleChange}
                id='energia_comp_spt_qt'
              />

              <InputV2
                label='Energia Compensada FP Quantidade'
                value={data.energia_comp_fp_qt}
                placeholder='Ex: 321'
                onChange={handleChange}
                id='energia_comp_fp_qt'
              />

              <InputV2
                label='Energia Compensada P Quantidade'
                value={data.energia_comp_p_qt}
                placeholder='Ex: 321'
                onChange={handleChange}
                id='energia_comp_p_qt'
              />

              <InputV2
                label="Energia Compensada INT Quantidade"
                value={data.energia_comp_int_qt}
                placeholder='Ex: 321'
                onChange={handleChange}
                id='energia_comp_int_qt'
              />

              <InputV2
                label="Energia Compensada Total Quantidade"
                value={data.energia_comp_total_qt}
                placeholder='Ex: -3004.42'
                onChange={handleChange}
                id='energia_comp_total_qt'
              />

              <div />

              <InputV2
                label='Energia Compensada SPT Tarifa'
                value={data.energia_comp_spt_tar}
                placeholder='Ex: 0.321'
                onChange={handleChange}
                id='energia_comp_spt_tar'
              />

              <InputV2
                label='Energia Compensada FP Tarifa'
                value={data.energia_comp_fp_tar}
                placeholder='Ex: 0.321'
                onChange={handleChange}
                id='energia_comp_fp_tar'
              />

              <InputV2
                label='Energia Compensada P Tarifa'
                value={data.energia_comp_p_tar}
                placeholder='Ex: 0.321'
                onChange={handleChange}
                id='energia_comp_p_tar'
              />

              <InputV2
                label="Energia Compensada INT Tarifa"
                value={data.energia_comp_int_tar}
                placeholder='Ex: 0.321'
                onChange={handleChange}
                id='energia_comp_int_tar'
              />
            </Card>

            <Card>
              <strong>PIS</strong>

              <InputV2
                label='Base'
                onChange={handleChange}
                value={data.tot_trib_pis_base}
                placeholder='Ex: 321'
                id='tot_trib_pis_base'
              />

              <InputV2
                label='Aliquota'
                onChange={handleChange}
                value={data.tot_trib_pis_aliq}
                placeholder='Ex: 12'
                id='tot_trib_pis_aliq'
              />

              <InputV2
                label='Valor'
                onChange={handleChange}
                value={data.tot_trib_pis_val}
                placeholder='Ex: 32'
                id='tot_trib_pis_val'
              />
            </Card>

            <Card>
              <strong>ICMS</strong>

              <InputV2
                label='Base'
                onChange={handleChange}
                value={data.tot_trib_icms_base}
                placeholder='Ex: 204.28'
                id='tot_trib_icms_base'
              />

              <InputV2
                label='Aliquota'
                onChange={handleChange}
                value={data.tot_trib_icms_aliq}
                placeholder='Ex: 15'
                id='tot_trib_icms_aliq'
              />

              <InputV2
                label='Valor'
                onChange={handleChange}
                value={data.tot_trib_icms_val}
                placeholder='Ex: 12.23'
                id='tot_trib_icms_val'
              />
            </Card>

            <Card>
              <strong>COFINS</strong>

              <InputV2
                label='Base'
                onChange={handleChange}
                value={data.tot_trib_pis_base}
                placeholder='Ex: 43.05'
                id='tot_trib_cofins_base'
              />

              <InputV2
                label='Aliquota'
                onChange={handleChange}
                value={data.tot_trib_cofins_aliq}
                placeholder='Ex: 20'
                id='tot_trib_cofins_aliq'
              />

              <InputV2
                label='Valor'
                onChange={handleChange}
                value={data.tot_trib_cofins_val}
                placeholder='Ex: 10'
                id='tot_trib_cofins_val'
              />
            </Card>

            <Card>
              <strong>Gerador</strong>

              <InputV2
                id='med_ger_spt_quant'
                label="Medição geração SPT quantidade"
                value={data.med_ger_spt_quant}
                placeholder='Ex: 321'
                onChange={handleChange}
              />

              <InputV2
                id='med_ger_fp_quant'
                label="Medição geração FP quantidade"
                value={data.med_ger_fp_quant}
                placeholder='Ex: 321'
                onChange={handleChange}
              />

              <InputV2
                id='med_ger_p_quant'
                label="Medição geração P quantidade"
                value={data.med_ger_p_quant}
                placeholder='Ex: 430'
                onChange={handleChange}
              />
            </Card>

            <Card>
              <strong>Saldo</strong>

              <InputV2
                label='Saldo Acumulado SPT'
                value={data.creditos_estoque_spt}
                placeholder='Ex: 320'
                id='creditos_estoque_spt'
                onChange={handleChange}
              />

              <InputV2
                label="Saldo Acumulado FP"
                value={data.creditos_estoque_fp}
                placeholder='Ex: 120'
                id='creditos_estoque_fp'
                onChange={handleChange}
              />

              <InputV2
                label="Saldo Acumulado P"
                value={data.creditos_estoque_p}
                placeholder='Ex: 0'
                id='creditos_estoque_p'
                onChange={handleChange}
              />
            </Card>

            <Card>
              <strong>Tarifas</strong>

              <InputV2
                label='Tarifa Crua - TE'
                onChange={handleChange}
                value={data.consumo_spt_te_tar_crua}
                placeholder='Ex: 0.321'
                id='consumo_spt_te_tar_crua'
              />

              <InputV2
                label='Tarifa Crua - TUSD'
                onChange={handleChange}
                value={data.consumo_spt_tusd_tar_crua}
                placeholder='Ex: 0.321'
                id='consumo_spt_tusd_tar_crua'
              />

              <InputV2
                label='Tarifa Crua - Total'
                onChange={handleChange}
                value={data.consumo_spt_tar_crua}
                placeholder='Ex: 0.321'
                id='consumo_spt_tar_crua'
              />
            </Card>

            <Card>
              <strong>Outros</strong>

              <InputV2
                label='Valor Total da Fatura'
                onChange={handleChange}
                value={data.valor_total_fatura}
                placeholder='Ex: 13210'
                id='valor_total_fatura'
              />

              <InputV2
                label='COSIP'
                value={data.cosip}
                placeholder='Ex: 12'
                onChange={handleChange}
                id='cosip'
              />

              <InputV2
                id='tot_band_val'
                label="Total Bandeira Valor"
                value={data.tot_band_val}
                onChange={handleChange}
                placeholder='Ex: 1.2042'
              />

              <InputV2
                id='tot_dev_band_val'
                label="Total Devolucao Bandeira Valor"
                value={data.tot_dev_band_val}
                onChange={handleChange}
                placeholder='Ex: 0.32376'
              />
            </Card>

            <Button type="submit" text={"Salvar"} onClick={sendDataForm.submitForm} />
          </Form>
        </FormikProvider>
      </Container >
    </Modal.Provider>
  )
}
