import ArrowBack from '@mui/icons-material/ArrowBack'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { DefaultTemplate } from '../../../components/Pdfs/Default'
import { SendInvoice } from '../../../components/Pdfs/SendInvoice'
import { calculateDefault, formatNum } from '../../../components/Pdfs/utils/format'
import { ITests } from '../../../components/Pdfs/utils/interface'
import {
  changeManual,
  createManual,
  createManualPdf,
  getClosure,
  getOneInvoice,
  getReportValues,
  listAllUcsCodes,
} from '../../../services/requests/user-requests'
import { Button, Buttons, Container, Infos, Return, Status } from './styles'
import { InvoicePreviewer } from '../InvoicePreviewer'
import { readers } from '../invoicesUrlPerDistributor'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { isNaN } from 'lodash'
import { useToast } from '../../../hooks/useToast'

const NEGATIVE_FIELDS = [
  'energia_comp_spt_qt',
  'energia_comp_spt_val',
  'energia_comp_int_qt',
  'energia_comp_p_qt',
  'energia_comp_p_val',
  'energia_comp_fp_qt',
  'energia_comp_fp_val',
  'energia_comp_total_qt',
  'energia_comp_total_val',
]

export const FIELDS_TO_TRANSFORM = [
  'valor_total_fatura',
  'cosip',
  'consumo_spt_qt',
  'consumo_spt_tar',
  'consumo_int_qt',
  'consumo_int_tar',
  'consumo_spt_tar_crua',
  'consumo_spt_val',
  'consumo_p_qt',
  'consumo_p_tar',
  'consumo_p_tar_crua',
  'consumo_p_val',
  'consumo_fp_qt',
  'consumo_fp_tar',
  'consumo_fp_tar_crua',
  'consumo_fp_val',
  'consumo_total_faturado_val',
  'consumo_spt_tar_crua',
  'consumo_total_faturado_qt',
  'energia_comp_spt_qt',
  'energia_comp_spt_tar',
  'energia_comp_spt_val',
  'energia_comp_spt_crua',
  'energia_comp_total_qt',
  'energia_comp_total_tar',
  'energia_comp_total_val',
  'energia_comp_fp_qt',
  'energia_comp_fp_tar',
  'energia_comp_fp_val',
  'energia_comp_p_qt',
  'energia_comp_int_qt',
  'energia_comp_int_tar',
  'energia_comp_p_tar',
  'energia_comp_p_val',
  'creditos_recebidos',
  'creditos_estoque_tot',
  'creditos_estoque_p',
  'creditos_estoque_fp',
  'creditos_estoque_spt',
]

export const validationSchema = Yup.object({
  id: Yup.number().min(0).required("ID Necessario"),
  consumo_spt_te_tar: Yup.number().typeError('A tarifa TE do consumo deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_spt_tusd_tar: Yup.number().typeError('A tarifa TUSD do consumo deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  tot_trib_icms_aliq: Yup.number().typeError('A aliquota do ICMS deve ser um numero valido, Ex: 25')
    .test("isPercentage", "As aliquotas sao cadastradas em %, Ex: 0.12 seria cadastrado como 12", (item) => {
      const value = Number(item)
      if (!value || Number.isNaN(value) || value === 0) return true

      return value >= 1
    })
    .required("Aliquota do ICMS precisa ser informada"),
  tot_trib_cofins_aliq: Yup.number().typeError('A aliquota do COFINS deve ser um numero valido, Ex: 25')
    .test("isPercentage", "As aliquotas sao cadastradas em %, Ex: 0.25 seria cadastrado como 25", (item) => {
      const value = Number(item)
      if (!value || Number.isNaN(value) || value === 0) return true

      return value >= 0.1
    })
    .required("Aliquota do COFINS precisa ser informada"),
  tot_trib_pis_aliq: Yup.number().typeError('A aliquota do PIS deve ser um numero valido, Ex: 0.76')
    .test("isPercentage", "As aliquotas sao cadastradas em %, Ex: 0.0075 seria cadastrado como 0.75", (item) => {
      const value = Number(item)
      if (!value || Number.isNaN(value) || value === 0) return true

      return value >= 0.01
    })
    .required("Aliquota do PIS precisa ser informada"),
  tot_trib_icms_val: Yup.number().typeError('O valor do ICMS deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  tot_trib_icms_base: Yup.number().typeError('A base do ICMS deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  tot_trib_pis_val: Yup.number().typeError('O valor do PIS deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  tot_trib_cofins_val: Yup.number().typeError('O valor do COFINS deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  tot_trib_pis_base: Yup.number().typeError('A base do PIS deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  tot_trib_cofins_base: Yup.number().typeError('A base do COFINS deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  med_ger_spt_quant: Yup.number().typeError('A quantidade SPT de geracao deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  med_ger_fp_quant: Yup.number().typeError('A quantidade FP de geracao deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  med_ger_p_quant: Yup.number().typeError('A quantidade P de gerecao deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  med_ger_tot_quant: Yup.number().typeError('A quantidade total de geracao deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  dias_faturados: Yup.number().typeError('A quantidade de dias faturados deve ser um numero valido, Ex: 2500.25').required("A quantidade de dias faturados precisa ser informada"),
  fatura_pdf: Yup.array(Yup.mixed()).default([]).nullable(),
  mes_referencia: Yup.string().typeError('O mes de referencia deve ser um texto no formato mes/ano').required("O mes de referencia precisa ser informado")
    .test("isValidDate", "O formato do mes de referencia deve ser 01/2025 (MES/ANO)", (item) => {
      const regex = /^[0-9]{1,2}\/[0-9]{4}$/
      if (!item) return true

      return regex.test(item)
    }),
  uc: Yup.string().typeError('O codigo da UC deve ser um texto').required('O codigo da UC precisa ser informado'),
  data_vencimento: Yup.string().typeError('A data de vencimento deve ser um texto no formato dia/mes/ano').required('A data de vencimento precisa ser informado')
    .test("isValidDate", "O formato da data de vencimento deve ser 15/01/2025 (DIA/MES/ANO)", (item) => {
      const regex = /^[0-9]{1,2}\/[0-9]{2}\/[0-9]{4}$/
      if (!item) return true

      return regex.test(item)
    }),
  data_emissao: Yup.string().typeError('A data de emissao deve ser um texto no formato dia/mes/ano').required('A data de emissao e obrigatoria')
    .test("isValidDate", "O formato da data de emissao deve ser 15/01/2025 (DIA/MES/ANO)", (item) => {
      const regex = /^[0-9]{1,2}\/[0-9]{2}\/[0-9]{4}$/
      if (!item) return true

      return regex.test(item)
    }),
  data_leitura_anterior: Yup.string().typeError('A data de leitura anterior deve ser um texto no formato dia/mes/ano').required('A data de leitura anterior e obrigatoria')
    .test("isValidDate", "O formato da data de leitura anterior deve ser 15/01/2025 (DIA/MES/ANO)", (item) => {
      const regex = /^[0-9]{1,2}\/[0-9]{2}\/[0-9]{4}$/
      if (!item) return true

      return regex.test(item)
    }),
  data_leitura_atual: Yup.string().typeError('A data de leitura atual deve ser um texto no formato dia/mes/ano').required('A data de leitura atual e obrigatoria')
    .test("isValidDate", "O formato da data de leitura atual deve ser 15/01/2025 (DIA/MES/ANO)", (item) => {
      const regex = /^[0-9]{1,2}\/[0-9]{2}\/[0-9]{4}$/
      if (!item) return true

      return regex.test(item)
    }),
  data_proxima_leitura: Yup.string().typeError('A data da proxima leitura deve ser um texto no formato dia/mes/ano').default('').required('A data da proxima leitura e obrigatoria')
    .test("isValidDate", "O formato da data de proxima leitura deve ser 15/01/2025 (DIA/MES/ANO)", (item) => {
      const regex = /^[0-9]{1,2}\/[0-9]{2}\/[0-9]{4}$/
      if (!item) return true

      return regex.test(item)
    }),
  nome: Yup.string().typeError('O nome deve ser um texto valido').default('').nullable(),
  endereco: Yup.string().typeError('O endereco deve ser um texto valido').default('').nullable(),
  cep: Yup.string().typeError('O CEP deve ser um texto valido').default('').nullable(),
  bairro: Yup.string().typeError('O bairro deve ser um texto valido').default('').nullable(),
  cidade: Yup.string().typeError('A cidade deve ser um texto valido').default('').nullable(),
  doc: Yup.string().typeError('O documento deve ser um texto valido').default('').nullable(),
  uf: Yup.string().typeError('O UF deve ser um texto valido').default('').nullable(),
  numero: Yup.string().typeError('O numero deve ser um valor valido').default('').nullable(),
  grupo: Yup.string().typeError('O grupo deve ser uma letra valida').max(1, 'O grupo e indicado por apenas uma letra').default('').nullable(),
  subgrupo: Yup.string().typeError('O subgrupo deve ser um texto validonumero').max(3, 'O subgrupo e indicado por apenas 2 para 3 letras').default('').nullable(),
  classe: Yup.string().typeError('A classe deve ser um texto valido').default('').nullable(),
  inscricaoEstadual: Yup.string().typeError('O X deve ser um numero valido, Ex: 2500.25').default('').nullable(),
  demandaFP: Yup.string().typeError('A demanda FP deve ser um numero valido, Ex: 2500.25 valido').default('').nullable(),
  tensaoMin: Yup.string().typeError('A tensao minima deve ser um numero valido, Ex: 2500.25 valido').default('').nullable(),
  medidor: Yup.string().typeError('O medidor deve ser um valor valido').default('').nullable(),
  fases: Yup.string().typeError('O tipo de fases deve ser um texto').default('').nullable(),
  equipamento: Yup.string().typeError('O equipamento deve ser um texto').default('').nullable(),
  cosip: Yup.number().typeError('O COSIP deve ser um numero valido, Ex: 2500.25 valido').default(0).nullable(),
  consumo_spt_qt: Yup.number().typeError('A quantidade do consumo SPT deve ser um numero valido, Ex: 2500.25').default(0).nullable()
    .test("isPositive", "Os valores de consumo devem ser positivos", (item) => {
      if (!item || item === 0) return true

      return Number(item) >= 0
    }),
  consumo_spt_tar: Yup.number().typeError('A tarifa do consumo SPT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_spt_tar_crua: Yup.number().typeError('A tarifa crua do consumo SPT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_spt_val: Yup.number().typeError('O valor do consumo SPT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_p_qt: Yup.number().typeError('A quantidade do consumo P deve ser um numero valido, Ex: 2500.25').default(0).nullable()
    .test("isPositive", "Os valores de consumo devem ser positivos", (item) => {
      if (!item || item === 0) return true

      return Number(item) >= 0
    }),
  consumo_p_tar: Yup.number().typeError('A tarifa do consumo P deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_p_tar_crua: Yup.number().typeError('A tarifa crua do consumo P deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_p_val: Yup.number().typeError('O valor do consumo P deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_fp_qt: Yup.number().typeError('A quantidade do consumo FP deve ser um numero valido, Ex: 2500.25').default(0).nullable()
    .test("isPositive", "Os valores de consumo devem ser positivos", (item) => {
      if (!item || item === 0) return true

      return Number(item) >= 0
    }),
  consumo_fp_tar: Yup.number().typeError('A tarifa do consumo FP deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_fp_tar_crua: Yup.number().typeError('A tarifa crua do consumo FP deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_fp_val: Yup.number().typeError('O valor do consumo FP deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_spt_qt: Yup.number().typeError('A quantidade da energia compensada SPT deve ser um numero valido, Ex: 2500.25').default(0).nullable()
    .test("isNegative", "Os valores de compensado devem ser negativos", (item) => {
      if (!item || item === 0) return true

      return Number(item) <= 0
    })
    .test("smallerThanOrEquals", "O compensado SPT deve ser menor que o consumo SPT", (item, context) => {
      if (
        !item ||
        !context.parent.consumo_spt_qt
      ) return true

      const consumption = Number(context.parent.consumo_spt_qt)
      const compensated = Number(item)

      const isValid = compensated <= consumption
      return isValid
    }),
  energia_comp_spt_tar: Yup.number().typeError('A tarifa da energia compensada SPT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_spt_val: Yup.number().typeError('O valor da energia compensada SPT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_spt_crua: Yup.number().typeError('A tarifa crua da energia compensada SPT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_fp_qt: Yup.number().typeError('A quantidade da energia compensada FP deve ser um numero valido, Ex: 2500.25').default(0).nullable()
    .test("isNegative", "Os valores de compensado devem ser negativos", (item) => {
      if (!item || item === 0) return true

      return Number(item) <= 0
    })
    .test("smallerThanOrEquals", "O compensado FP deve ser menor que o consumo FP", (item, context) => {
      if (
        !item ||
        !context.parent.consumo_fp_qt
      ) return true

      const consumption = Number(context.parent.consumo_fp_qt)
      const compensated = Number(item)

      const isValid = compensated <= consumption
      return isValid
    }),
  energia_comp_fp_tar: Yup.number().typeError('A tarifa da energia compensada FP deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_fp_val: Yup.number().typeError('O valor da energia compensada FP deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_p_qt: Yup.number().typeError('A quantidade da energia compensada P deve ser um numero valido, Ex: 2500.25').default(0).nullable()
    .test("isNegative", "Os valores de compensado devem ser negativos", (item) => {
      if (!item || item === 0) return true

      return Number(item) <= 0
    })
    .test("smallerThanOrEquals", "O compensado P deve ser menor que o consumo P", (item, context) => {
      if (
        !item ||
        !context.parent.consumo_p_qt
      ) return true

      const consumption = Number(context.parent.consumo_p_qt)
      const compensated = Number(item)

      const isValid = compensated <= consumption
      return isValid
    }),
  energia_comp_p_tar: Yup.number().typeError('A tarifa da energia compensada P deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_p_val: Yup.number().typeError('O valor da energia compensada P deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  creditos_recebidos: Yup.number().typeError('Os creditos recebidos deve ser um numero valido, Ex: 2500.25 valido').default(0).nullable(),
  energia_comp_total_qt: Yup.number().typeError('A quantidade da energia compensada total deve ser um numero valido, Ex: 2500.25').default(0).nullable()
    .test("smallerThanOrEquals", "O compensado total deve ser menor que o consumo total", (item, context) => {
      if (
        !item ||
        !context.parent.consumo_total_faturado_qt
      ) return true

      const consumption = Number(context.parent.consumo_total_faturado_qt)
      const compensated = Number(item)

      const isValid = compensated <= consumption
      return isValid
    }),
  energia_comp_total_tar: Yup.number().typeError('A tarifa da energia compensada total deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_total_val: Yup.number().typeError('O valor da energia compensada total deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_total_faturado_val: Yup.number().typeError('O valor do consumo total faturado deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_total_faturado_tar: Yup.number().typeError('A tarifa do consumo total faturado deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_total_faturado_qt: Yup.number().typeError('A quantidade do consumo total faturado deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  valor_total_fatura: Yup.number().typeError('O valor total da fatura deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  creditos_estoque_p: Yup.number().typeError('Os creditos em estoque P devem ser um numero').default(0).nullable(),
  creditos_estoque_fp: Yup.number().typeError('Os creditos em estoque FP devem ser um numero').default(0).nullable(),
  creditos_estoque_spt: Yup.number().typeError('Os creditos em estoque SPT devem ser um numero').default(0).nullable(),
  creditos_estoque_tot: Yup.number().typeError('Os creditos em estoque totais devem ser um numero').default(0).nullable(),
  exist: Yup.boolean().typeError('O campo que identifica se existe deve ser um verdadeiro ou falso').default(false).nullable(),
  consumo_spt_te_tar_crua: Yup.number().typeError('A tarifa crua do consumo SPT TE deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_spt_tusd_tar_crua: Yup.number().typeError('A tarifa crua do consumo SPT TUSD deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_int_tar: Yup.number().typeError('A tarifa da energia compensada INT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_int_qt: Yup.number().typeError('A quantidade da energia compensada INT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  energia_comp_int_val: Yup.number().typeError('O valor da energia compensada INT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_int_tar: Yup.number().typeError('A tarifa do consumo INT deve ser um numero valido, Ex: 2500.25').default(0).nullable(),
  consumo_int_qt: Yup.number().typeError('A quantidade do consumo INT deve ser um numero valido, Ex: 2500.25').typeError('O quantidade do consumo deve ser um numero').default(0).nullable(),
  consumo_int_val: Yup.number().typeError('O valor do consumo INT deve ser um numero valido, Ex: 2500.25').typeError('O valor do consumo deve ser um numero').default(0).nullable(),
  tot_band_val: Yup.number().typeError('O valor da bandeira deve ser um numero valido, Ex: 2500.25').typeError('O valor da bandeira deve ser um numero').default(0).nullable(),
  tot_dev_band_val: Yup.number().typeError('O valor da devolucao da bandeira deve ser um numero valido, Ex: 2500.25').typeError('A devolucao da bandeira deve ser um numero').default(0).nullable()
    .test("isNegative", "Os valores de devolucao devem ser negativos", (item) => {
      if (!item || item === 0) return true

      return Number(item) <= 0
    })
})

export function ReportDefault({ setPage }: { setPage: (value: '') => void }) {
  const location = useLocation()
  const navigate = useNavigate()
  const { toast } = useToast()

  const [data, setData] = useState<ITests>({
    id: 0,
    consumo_spt_te_tar: 0,
    consumo_spt_tusd_tar: 0,
    tot_trib_icms_aliq: 0,
    tot_trib_cofins_aliq: 0,
    tot_trib_pis_aliq: 0,
    tot_trib_icms_val: 0,
    tot_trib_icms_base: 0,
    tot_trib_pis_val: 0,
    tot_trib_cofins_val: 0,
    tot_trib_pis_base: 0,
    tot_trib_cofins_base: 0,
    med_ger_spt_quant: 0,
    med_ger_tot_quant: 0,
    med_ger_fp_quant: 0,
    med_ger_p_quant: 0,
    dias_faturados: 0,
    fatura_pdf: [],
    mes_referencia: '',
    uc: '',
    data_vencimento: '',
    data_emissao: '',
    data_leitura_anterior: '',
    data_leitura_atual: '',
    data_proxima_leitura: '',
    nome: '',
    endereco: '',
    cep: '',
    bairro: '',
    cidade: '',
    doc: '',
    uf: '',
    numero: '',
    grupo: '',
    subgrupo: '',
    classe: '',
    inscricaoEstadual: '',
    demandaFP: '',
    tensaoMin: '',
    medidor: '',
    fases: '',
    equipamento: '',
    cosip: 0,
    consumo_spt_qt: 0,
    consumo_spt_tar: 0,
    consumo_spt_tar_crua: 0,
    consumo_spt_val: 0,
    consumo_p_qt: 0,
    consumo_p_tar: 0,
    consumo_p_tar_crua: 0,
    consumo_p_val: 0,
    consumo_fp_qt: 0,
    consumo_fp_tar: 0,
    consumo_fp_tar_crua: 0,
    consumo_fp_val: 0,
    energia_comp_spt_qt: 0,
    energia_comp_spt_tar: 0,
    energia_comp_spt_val: 0,
    energia_comp_spt_crua: 0,
    energia_comp_fp_qt: 0,
    energia_comp_fp_tar: 0,
    energia_comp_fp_val: 0,
    energia_comp_p_qt: 0,
    energia_comp_p_tar: 0,
    energia_comp_p_val: 0,
    creditos_recebidos: 0,
    energia_comp_total_qt: 0,
    energia_comp_total_tar: 0,
    energia_comp_total_val: 0,
    consumo_total_faturado_val: 0,
    consumo_total_faturado_tar: 0,
    consumo_total_faturado_qt: 0,
    valor_total_fatura: 0,
    creditos_estoque_p: 0,
    creditos_estoque_fp: 0,
    creditos_estoque_spt: 0,
    creditos_estoque_tot: 0,
    exist: false,
    consumo_spt_te_tar_crua: 0,
    consumo_spt_tusd_tar_crua: 0,
    energia_comp_int_tar: 0,
    energia_comp_int_qt: 0,
    energia_comp_int_val: 0,
    consumo_int_tar: 0,
    consumo_int_qt: 0,
    consumo_int_val: 0,
    tot_band_val: 0,
    tot_dev_band_val: 0,
  })

  function sendData() {
    const newData: any = {
      ...data
    }

    for (const [key, value] of Object.entries(newData)) {
      if (FIELDS_TO_TRANSFORM.includes(key)) {
        if (typeof value !== 'string' && typeof value !== 'number')
          continue

        let parsed: string | number = value?.toString()?.replace(',', '.')
        const isInvalidParse = Number.isNaN(parseFloat(parsed))

        if (isInvalidParse) {
          toast({
            type: 'error',
            message: `O campo ${key} contem um valor invalido`
          })

          return
        }

        parsed = parseFloat(parsed)
        newData[key] = parsed

        if (NEGATIVE_FIELDS.includes(key)) {
          newData[key] = parsed > 0 ?
            -parsed :
            parsed
        }
      }
    }

    const formattedValue = calculateDefault(newData)

    if (
      data.uc &&
      data.mes_referencia &&
      data.data_emissao &&
      data.data_leitura_anterior &&
      data.data_leitura_atual &&
      data.data_vencimento &&
      data.data_proxima_leitura &&
      (updateStatus || (file !== undefined && file !== null))
    ) {
      (async () => {
        setStatus('Enviando')

        if (updateStatus) {
          setStatus('Enviando')

          try {
            await changeManual(formattedValue)
              .catch(() => {
                toast({
                  type: 'error',
                  message: 'Ocorreu um erro ao tentar subir a fatura, por favor contate os desenvolvedores!',
                })
              })
              .finally(() => {
                setStatus('')
              })
          } catch {
            setStatus('')

            toast({ type: 'error', message: 'Ocorreu um erro ao enviar a fatura!' })
          }
        } else {
          if (file && formattedValue) {
            const formDatas = new FormData()
            formDatas.append('files', file)

            const result = await createManualPdf(formDatas).catch(() => {
              toast({
                type: 'error',
                message: 'Ocorreu um erro ao tentar subir o pdf, por favor contate os desenvolvedores!'
              })
            })

            await createManual({ ...formattedValue, drive_id: result.id })
              .then(() => reset())
              .catch(() => {
                toast({
                  type: 'error',
                  message: 'Ocorreu um erro ao tentar subir a fatura, por favor contate os desenvolvedores!',
                })
              })
              .finally(() => setStatus(''))
          } else {
            setStatus('')

            toast({
              type: 'error',
              message: 'Ocorreu um erro ao enviar os dados!'
            })
          }
        }
      })()
    } else {
      toast({
        type: 'warning',
        message: 'Certifique-se de adicionar o número de instalação, data emissão, conta mês, data de leitura anterior, data de leitura atual, data de vencimento e enviar a fatura',
      })
    }
  }

  const queryParams = new URLSearchParams(location.search)
  const updateStatus = queryParams.get('update')

  const [allUcs, setAllUcs] = useState<string[]>([])
  const [file, setFile] = useState<File>()
  const [status, setStatus] = useState('')

  useEffect(() => {
    async function getData() {
      if (updateStatus && !isNaN(parseInt(updateStatus))) {
        const fatura = await getOneInvoice(updateStatus)
        const [yearRef, monthRef, codigo, moreCode] = fatura.chave ? fatura.chave.split('-').reverse() : []
        const [yearVenc, monthVenc, dayVenc] = fatura.data_vencimento ? fatura.data_vencimento.split('-') : []
        const [yearEmi, monthEmi, dayEmi] = fatura.data_emissao ? fatura.data_emissao.split('-') : []

        let data_proxima_leitura = ''
        if (fatura.data_proxima_leitura) {
          const [yearNext, monthNext, dayNext] = fatura.data_proxima_leitura.split('-')
          data_proxima_leitura = `${dayNext}/${monthNext}/${yearNext}`
        }

        let data_leitura_anterior = ''
        if (fatura.data_leitura_anterior) {
          const [yearNext, monthNext, dayNext] = fatura.data_leitura_anterior.split('-')
          data_leitura_anterior = `${dayNext}/${monthNext}/${yearNext}`
        }

        let data_leitura_atual = ''
        if (fatura.data_leitura_atual) {
          const [yearNext, monthNext, dayNext] = fatura.data_leitura_atual.split('-')
          data_leitura_atual = `${dayNext}/${monthNext}/${yearNext}`
        }

        setData({
          ...data,
          ...fatura,
          id: fatura.id,
          mes_referencia: `${monthRef}/${yearRef}`,
          uc: moreCode ? `${moreCode}-${codigo}` : codigo,
          data_vencimento: `${dayVenc}/${monthVenc}/${yearVenc}`,
          data_emissao: `${dayEmi}/${monthEmi}/${yearEmi}`,
          data_leitura_anterior,
          data_leitura_atual,
          data_proxima_leitura,
          nome: fatura.nome,
          endereco: fatura.endereco,
          cep: fatura.cep,
          doc: fatura.doc,
          grupo: fatura.grupo,
          subgrupo: fatura.subgrupo,
          med_ger_spt_quant: +fatura.med_ger_spt_quant,
          med_ger_fp_quant: +fatura.med_ger_fp_quant,
          med_ger_p_quant: +fatura.med_ger_p_quant,
          classe: fatura.classe,
          fases: fatura.fases,
          cosip: +fatura.cosip,
          consumo_spt_qt: +fatura.consumo_spt_qt,
          consumo_fp_qt: +fatura.consumo_fp_qt,
          consumo_p_qt: +fatura.consumo_p_qt,
          energia_comp_fp_qt: +fatura.energia_comp_fp_qt,
          energia_comp_spt_qt: +fatura.energia_comp_spt_qt,
          energia_comp_p_qt: +fatura.energia_comp_p_qt,
          energia_comp_fp_tar: +fatura.energia_comp_fp_tar,
          energia_comp_spt_tar: +fatura.energia_comp_spt_tar,
          energia_comp_p_tar: +fatura.energia_comp_p_tar,
          valor_total_fatura: +fatura.valor_total_fatura,
          creditos_estoque_spt: +fatura.creditos_estoque_spt,
          creditos_estoque_p: +fatura.creditos_estoque_p,
          creditos_estoque_fp: +fatura.creditos_estoque_fp,
          tot_trib_pis_aliq: +fatura.tot_trib_pis_aliq,
          tot_trib_cofins_aliq: +fatura.tot_trib_cofins_aliq,
          tot_trib_icms_aliq: +fatura.tot_trib_icms_aliq,
          dias_faturados: fatura.dias_faturados,
          consumo_spt_te_tar_crua: formatNum(fatura.consumo_spt_te_tar_crua),
          consumo_spt_tusd_tar_crua: formatNum(fatura.consumo_spt_tusd_tar_crua),
          consumo_spt_tar_crua:
            formatNum(fatura.consumo_spt_tar_crua) > 0
              ? formatNum(fatura.consumo_spt_tar_crua)
              : formatNum(fatura.consumo_p_tar_crua) > 0 ||
                formatNum(fatura.consumo_fp_tar_crua) > 0
                ? formatNum(fatura.consumo_p_tar_crua) + formatNum(fatura.consumo_fp_tar_crua)
                : 0,
          exist: false,
        })
      }
    }

    getData()
  }, [updateStatus])

  function reset() {
    setData({
      id: 0,
      consumo_spt_te_tar: 0,
      consumo_spt_tusd_tar: 0,
      tot_trib_icms_aliq: 0,
      tot_trib_cofins_aliq: 0,
      tot_trib_icms_val: 0,
      tot_trib_icms_base: 0,
      tot_trib_pis_val: 0,
      tot_trib_cofins_val: 0,
      tot_trib_pis_base: 0,
      tot_trib_cofins_base: 0,
      med_ger_spt_quant: 0,
      med_ger_tot_quant: 0,
      med_ger_fp_quant: 0,
      med_ger_p_quant: 0,
      tot_trib_pis_aliq: 0,
      dias_faturados: 0,
      fatura_pdf: [],
      mes_referencia: '',
      uc: '',
      data_vencimento: '',
      data_emissao: '',
      data_leitura_anterior: '',
      data_leitura_atual: '',
      data_proxima_leitura: '',
      nome: '',
      endereco: '',
      cep: '',
      bairro: '',
      cidade: '',
      doc: '',
      uf: '',
      numero: '',
      grupo: '',
      subgrupo: '',
      classe: '',
      inscricaoEstadual: '',
      demandaFP: '',
      tensaoMin: '',
      medidor: '',
      fases: '',
      equipamento: '',
      cosip: 0,
      consumo_spt_qt: 0,
      consumo_spt_tar: 0,
      consumo_spt_tar_crua: 0,
      consumo_spt_val: 0,
      consumo_p_qt: 0,
      consumo_p_tar: 0,
      consumo_p_tar_crua: 0,
      consumo_p_val: 0,
      consumo_fp_qt: 0,
      consumo_fp_tar: 0,
      consumo_fp_tar_crua: 0,
      consumo_fp_val: 0,
      energia_comp_spt_qt: 0,
      energia_comp_spt_tar: 0,
      energia_comp_spt_val: 0,
      energia_comp_spt_crua: 0,
      energia_comp_fp_qt: 0,
      energia_comp_fp_tar: 0,
      energia_comp_fp_val: 0,
      energia_comp_p_qt: 0,
      energia_comp_p_tar: 0,
      energia_comp_p_val: 0,
      creditos_recebidos: 0,
      energia_comp_total_qt: 0,
      energia_comp_total_tar: 0,
      energia_comp_total_val: 0,
      consumo_total_faturado_val: 0,
      consumo_total_faturado_tar: 0,
      consumo_total_faturado_qt: 0,
      valor_total_fatura: 0,
      creditos_estoque_p: 0,
      creditos_estoque_fp: 0,
      creditos_estoque_spt: 0,
      creditos_estoque_tot: 0,
      exist: false,
      consumo_spt_te_tar_crua: 0,
      consumo_spt_tusd_tar_crua: 0,
      consumo_int_qt: 0,
      consumo_int_tar: 0,
      consumo_int_val: 0,
      energia_comp_int_qt: 0,
      energia_comp_int_tar: 0,
      energia_comp_int_val: 0,
      tot_band_val: 0,
      tot_dev_band_val: 0,
    })
  }

  useEffect(() => {
    (async () => {
      setStatus('Carregando Ucs')
      const data = await listAllUcsCodes().finally(() => setStatus(''))

      setAllUcs(data as unknown as string[])
    })()
  }, [])


  function fechamento() {
    (async () => {
      const formattedValue = calculateDefault(data)

      await getClosure(formattedValue)
        .catch(() => {
          toast({ type: 'error', message: 'Ocorreu um erro ao adquirir o fechamento' })
        })
        .then((res: any) => {
          if (res.error) {
            toast({ type: 'error', message: 'Ocorreu um erro ao adquirir o fechamento' })
          } else {
            let text = ''

            for (const r of Object.keys(res)) {
              text += `${r}: ${res[r]}
`
            }

            alert(text)
          }
        })
    })()
  }

  function autoPreencher() {
    (async () => {
      if (allUcs.length && allUcs.includes(data.uc) && data.mes_referencia.length > 5) {
        setStatus('Auto completando')

        const [month, year] = data.mes_referencia.split('/')
        const mes_referencia = `${year}-${month}-01`

        const replacements = (await getReportValues({ codigo: data.uc, mes_referencia }).finally(
          () => setStatus(''),
        )) as any

        setData({
          ...data,
          doc: replacements.documento_numero,
          cep: data.cep || replacements.cep,
          endereco: data.endereco || replacements.endereco,
          subgrupo: data.subgrupo || replacements.subgrupo,
          nome: replacements.nome_razao_social || replacements.nome,
          consumo_spt_te_tar_crua: data.consumo_spt_te_tar_crua || replacements.te,
          consumo_spt_tusd_tar_crua: data.consumo_spt_tusd_tar_crua || replacements.tusd,
          classe: data.classe || replacements.classe,
          fases: data.fases || replacements.fases,
          tot_trib_pis_aliq: data.tot_trib_pis_aliq || replacements.pis,
          tot_trib_cofins_aliq: data.tot_trib_cofins_aliq || replacements.cofins,
          tot_trib_icms_aliq: data.tot_trib_icms_aliq || replacements.icms,
          exist: replacements.existFat,
        })

        if (replacements.existFat) toast({ type: 'info', message: 'Essa fatura já existe' })
      } else {
        toast({ type: 'warning', message: 'Adicione o conta mês e número de inscrição válido para avaliar' })
      }
    })()
  }

  const sendDataForm = useFormik({
    onSubmit: sendData,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: data,
  })

  useEffect(() => {
    sendDataForm.setValues(data)
  }, [data])

  const handleUpdateInvoiceSubmit = async () => {
    await sendDataForm.submitForm().catch(_ => {
      const errors = sendDataForm.errors
      const hasErrors = Object.keys(errors)?.length > 0

      if (hasErrors) {
        const messages = Object.entries(errors).reduce((acc, item) => {
          const label = item?.at(0) as string
          const message = item?.at(1) as string

          acc.push(`${label}: ${message}`)

          return acc
        }, [] as string[])

        toast({
          type: 'error',
          message: `Ocorreu um erro nos campos: ${messages.join(', ')}`
        })
      }
    })
  }

  return (
    <Container>
      {!status ? (
        <Infos>
          <Return
            onClick={() => {
              if (updateStatus) {
                navigate('/report/edit')
              } else setPage('')
            }}
          >
            <ArrowBack fontSize='small' />
            <p>Voltar</p>
          </Return>

          <Buttons>
            {data.exist ?
              <Status>
                Esta fatura já existe
              </Status> :
              null
            }

            {updateStatus ?
              null :
              <Button
                onClick={reset}
              >
                Reiniciar
              </Button>
            }

            <Button
              onClick={autoPreencher}
            >
              Avaliar
            </Button>

            <Button
              onClick={fechamento}
            >
              Fechamento
            </Button>

            {updateStatus ?
              <Button type='submit' onClick={handleUpdateInvoiceSubmit}>
                Atualizar
              </Button> :
              <Button onClick={sendData}>
                Enviar
              </Button>
            }
          </Buttons>
        </Infos>
      ) : null}
      {!updateStatus ? <SendInvoice setFile={setFile} /> : null}
      {!status ? <DefaultTemplate form={sendDataForm} formData={data} setFormData={setData} /> : status}
      <InvoicePreviewer reader={readers.DEFAULT} />
    </Container>
  )
}
