import { FormControlLabel, FormControlLabelProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import Switch, { SwitchProps } from '@mui/material/Switch'

export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#023b21',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#023b21',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

interface Props extends Omit<FormControlLabelProps, 'control' | 'value' | 'onChange'> {
  value: boolean
  onChange: (value: boolean) => void
}

export default function SwitchToggle(props: Props) {
  const { onChange, value, style, ...rest } = props

  return (
    <FormControlLabel
      labelPlacement='top'
      style={{
        color: 'rgb(75, 75, 75)',
        display: 'flex',
        justifyContent: 'space-between',
        margin: 0,
        ...(style ? style : {})
      }}
      checked={value}
      onChange={(e: any) => onChange(e.target.checked)}
      control={<IOSSwitch sx={{ m: 0, marginTop: 1 }} defaultChecked />}
      {...rest}
    />
  )
}
