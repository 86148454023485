import styled from 'styled-components'

export const ErrorHeader = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
`

export const ErrorText = styled.p`
  font-size: 1.3rem;
  font-weight: 400;
  text-align: justify;
`