import { InputLabel, Stack } from "@mui/material"
import Modal from "../../../../components/ModalV2"
import { Button } from "../../../../components/Button"
import { ALERT_OPTIONS, ALERT_SEVERITY } from ".."
import { ChangeEvent, useEffect, useState } from "react"
import { SelectV2 } from "../../../../components/SelectV2"
import { useToast } from "../../../../hooks/useToast"
import { AlertResponse, alertsService } from "../../../../services/alerts"
import { Client, Supplier } from "./CreateAlertModal"
import { distributorListGet, getAllClients } from "../../../../services/requests/user-requests"
import { Textarea } from "../styles"

interface EditAlertModalProps {
  item: AlertResponse
  onSuccess: () => Promise<void>
  onClose: () => void
}

export const EditAlertModal = ({ onClose, onSuccess, item }: EditAlertModalProps) => {
  const { toast } = useToast()

  const [isLoading, setIsLoading] = useState(false)
  const [clients, setClients] = useState<Client[]>([])
  const [suppliers, setSuppliers] = useState<Supplier[]>([])
  const [alert, setAlert] = useState<Alert>(() => {
    return {
      option: item.option as Alert['option'] ?? 'GLOBAL',
      message: item.message ?? '',
      severity: item.severity ?? 2,
    }
  })

  useEffect(() => {
    const fetchDefaultValues = async () => {
      const clientsResponse = await getAllClients().then(item => item.data)
      setClients(clientsResponse)

      const suppliersResponse = await distributorListGet().then(item => item.data)
      setSuppliers(suppliersResponse)

      const client = clientsResponse?.find((clientBody: Record<'id', number>) => clientBody?.id === item?.client_id)
      const distributor = suppliersResponse?.find((distributorBody: Record<'id', number>) => distributorBody?.id === item?.distributor_id)

      setAlert(prev => ({
        ...prev,
        client: {
          id: client?.id ?? item.client_id,
          cliente_nome: client?.cliente_nome
        },
        distributor: {
          id: distributor?.id ?? item.distributor_id,
          nome: distributor?.nome
        }
      }))
    }


    fetchDefaultValues()
  }, [])

  const handleChangeAlert = <T extends string = 'option' | 'message' | 'severity' | 'client' | 'distributor'>(
    field: T,
    value: T extends 'option' ? keyof typeof ALERT_OPTIONS :
      T extends 'severity' ? keyof typeof ALERT_SEVERITY :
      string
  ) => {
    setAlert(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const selectTypeOptions = Object.entries(ALERT_OPTIONS).map(([key, value]) => ({
    id: key,
    displayName: value
  }))

  const selectSeverityOptions = Object.entries(ALERT_SEVERITY).map(([key, value]) => ({
    id: key,
    displayName: value
  }))

  const selectClientOptions = clients.map(client => ({
    id: String(client.id),
    displayName: client.cliente_nome
  }))

  const selectSupplierOptions = suppliers.map(supplier => ({
    id: String(supplier.id),
    displayName: supplier.nome
  }))

  const handleSubmit = async () => {
    try {
      setIsLoading(true)

      const message = {
        message: alert?.message,
        option: alert?.option,
        severity: alert?.severity ?? 2
      }

      if (alert.option === 'PER_USER') {
        if (!alert.client) {
          toast({
            type: 'warning',
            message: 'É necessário selecionar um cliente'
          })
          return
        }

        Object.assign(message, {
          client: alert?.client.id
        })
      }

      if (alert.option === 'PER_DISTRIBUTOR') {
        if (!alert.distributor) {
          toast({
            type: 'warning',
            message: 'É necessário selecionar uma distribuidora'
          })
          return
        }

        Object.assign(message, {
          distributor: alert.distributor.id
        })
      }

      if (!alert.message?.replaceAll(' ', '')?.length)
        throw new Error('O campo de mensagem tem que estar preenchido')

      await alertsService.edit(item.id, message)

      toast({
        type: 'success',
        message: 'O comunicado foi editado com sucesso'
      })

      await onSuccess()
    } catch {
      toast({
        type: 'error',
        message: 'Não foi possivel editar o comunicado'
      })
    } finally {
      onClose()
      setIsLoading(false)
    }
  }

  return (
    <Modal.Provider
      isOpen
      maxWidth={650}
      style={{ width: "100%" }}
      onClose={() => onClose()}
    >
      <Modal.Title>
        {'Editar comunicado'}
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      <Modal.Body>
        <Stack gap={2}>
          <Stack direction={"row"} width='100%' gap={2}>
            <Stack style={{ position: 'relative' }} gap={1} width={'50%'}>
              <InputLabel id='alert-option'>Tipo de comunicado</InputLabel>
              <SelectV2
                required
                formik={false}
                value={alert.option}
                items={selectTypeOptions}
                onChange={({ target }) => {
                  handleChangeAlert('option', target?.value as keyof typeof ALERT_OPTIONS)
                }}
              />
            </Stack>

            <Stack style={{ position: 'relative' }} gap={1} width='50%'>
              <InputLabel id='alert-option'>Gravidade do comunicado</InputLabel>
              <SelectV2
                required
                formik={false}
                value={alert.severity}
                items={selectSeverityOptions}
                onChange={({ target }) => {
                  handleChangeAlert('severity', target?.value as keyof typeof ALERT_SEVERITY)
                }}
              />
            </Stack>
          </Stack>

          {alert.option === 'PER_USER' && (
            <Stack style={{ position: 'relative' }} gap={1} width={'100%'}>
              <InputLabel id='alert-option'>Selecionar cliente</InputLabel>
              <SelectV2
                required
                formik={false}
                value={alert.client}
                items={selectClientOptions ?? []}
                onChange={({ target }) => {
                  handleChangeAlert('client', target?.value as string)
                }}
              />
            </Stack>
          )}

          {alert.option === 'PER_DISTRIBUTOR' && (
            <Stack style={{ position: 'relative' }} gap={1} width='100%'>
              <InputLabel id='alert-option'>Selecionar distribuidora</InputLabel>
              <SelectV2
                required
                formik={false}
                value={alert.distributor}
                items={selectSupplierOptions}
                onChange={({ target }) => {
                  handleChangeAlert('distributor', String(target?.value))
                }}
              />
            </Stack>
          )}

          <Stack style={{ position: 'relative' }} gap={1} width='100%'>
            <InputLabel id='alert-message'>Selecionar distribuidora</InputLabel>

            <Textarea
              required
              style={{
                minHeight: 200
              }}
              value={alert.message}
              onChange={({ target }: ChangeEvent<HTMLTextAreaElement>) => {
                handleChangeAlert('message', target?.value)
              }}
            />
          </Stack>
        </Stack>

        <Stack direction={'row'} mt={3}>
          <Button
            text='Cancelar'
            onClick={() => onClose()}
          />
          <Button
            text='Editar'
            disabled={isLoading}
            isLoading={isLoading}
            onClick={handleSubmit}
          />
        </Stack>
      </Modal.Body>
    </Modal.Provider>
  )
}

interface Alert {
  option: keyof typeof ALERT_OPTIONS
  message: string
  severity: number
  client?: {
    id: number
    cliente_nome: string
  },
  distributor?: {
    id: number
    nome: string
  }
}
