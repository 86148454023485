import { Stack, Typography } from '@mui/material'
import { InputV2 } from '../../../../../components/InputV2'

type ImageInfo = {
  fileName: string
  width: number
  uri: string
}

interface Props {
  value: ImageInfo
  onChange: (width: number) => void
  openImageGalleryModal: () => void
}

export default function ImageViewer(props: Props) {
  const { value, onChange, openImageGalleryModal } = props

  return (
    <>
      <Stack gap={2}>
        {value && (
          <Stack
            style={{
              backgroundColor: 'rgb(241, 241, 241)',
              borderRadius: '8px',
              width: 'fit-content',
              padding: '16px',
              cursor: 'pointer',
              margin: '0 auto',
            }}
          >
            <img
              src={value.uri}
              style={{ width: `${value.width}px`, height: 'auto', objectFit: 'contain' }}
            />
            <Typography textAlign='center' fontWeight='bold' mt={1}>
              {value.width}px
            </Typography>
          </Stack>
        )}
        <button
          style={{
            backgroundColor: '#f1f1f1',
            fontSize: '16px',
            height: '45px',
            borderRadius: '8px',
            fontWeight: '600',
            color: '#023b21',
          }}
          onClick={openImageGalleryModal}
        >
          Selecionar Imagem
        </button>
        <InputV2
          containerStyle={{ margin: 0 }}
          label='Largura'
          formik={false}
          value={value.width}
          onChange={(e: any) => onChange(Number(e.target.value.replace(/[^0-9]/g, '')))}
        />
      </Stack>
    </>
  )
}
