import { useEffect, useState } from 'react'
import { useToast } from '../../../../../hooks/useToast'
import { paymentApi, TemplateAsset } from '../../../../../services/payment-api'
import { IconButton, Stack } from '@mui/material'
import Modal from '../../../../../components/ModalV2'
import { ArrowBack } from '@mui/icons-material'
import { StickyFooter } from '../../../InvoiceValidation/components/InvoiceModal/styles'
import { Button } from '../../../../../components/Button'
import Loading from '../../../../../components/Loading'
import ImageGallery from './components/ImageGallery'
import UploadClientAssetModal from '../UploadClientAssetModal'

interface Props {
  managerId: number
  onClose: () => void
  goBack?: () => void
}

interface ImageGalleryItem extends TemplateAsset {
  url: string
  file?: File
}

export default function CustomerImageGalleryModal(props: Props) {
  const { managerId, onClose, goBack } = props

  const [imagesFromGallery, setImagesFromGallery] = useState<ImageGalleryItem[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isAddingNewImage, setAddingNewImage] = useState<boolean>(false)

  const { toast } = useToast()

  const fetchImageGalleryFromCustomer = async () => {
    try {
      setLoading(true)
      const customerImages = await paymentApi.getTemplateAssetsFromCustomer({
        managerId,
      })
      setImagesFromGallery(customerImages)
    } catch (error) {
      toast({
        message: error instanceof Error ? error?.message : 'Erro ao buscar imagens',
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchImageGalleryFromCustomer()
  }, [])

  if (isAddingNewImage) {
    return (
      <UploadClientAssetModal
        managerId={managerId}
        onSuccess={() => fetchImageGalleryFromCustomer()}
        goBack={() => setAddingNewImage(false)}
        onClose={() => setAddingNewImage(false)}
      />
    )
  }

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        width: '100%',
        position: 'relative',
        height: '100%',
        maxHeight: '750px',
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        {goBack && (
          <IconButton style={{ marginRight: '8px' }} onClick={goBack}>
            <ArrowBack />
          </IconButton>
        )}
        Galeria de Imagens
        <Modal.CloseButton alignLeft />
      </Modal.Title>
      <Modal.Body style={{ overflowY: 'auto' }}>
        {isLoading ? (
          <Stack mt={8}>
            <Loading />
          </Stack>
        ) : (
          <ImageGallery
            images={imagesFromGallery.map((image) => ({
              fileId: image.fileId,
              fileUrl: image.url,
              chipText: image.assetType,
              description: `${image.fileName}.${image.fileType}`,
            }))}
          />
        )}
      </Modal.Body>
      <StickyFooter style={{ padding: '16px', marginTop: 'auto' }}>
        <Stack direction='column' width='100%' gap={2}>
          {/* <Button containerStyle={{ margin: 0 }} text='Selecionar Imagem' /> */}
          <Button
            containerStyle={{ margin: 0 }}
            text='Upload Nova Imagem'
            onClick={() => setAddingNewImage(true)}
          />
        </Stack>
      </StickyFooter>
    </Modal.Provider>
  )
}
