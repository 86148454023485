import { useState, useRef, useEffect } from 'react'
import './styles.css'
import { Stack } from '@mui/material'
import { Label } from '../../../../../components/InputV2/styles'

interface Props {
  label?: string
  value: string
  variables: Record<string, any>
  onChange: (value: string) => void
}

export default function TextInput(props: Props) {
  const { label, value, variables, onChange } = props

  const variablesKeys = Object.keys(variables)

  const [autocompleteOpen, setAutocompleteOpen] = useState<boolean>(false)
  const [cursorPosition, setCursorPosition] = useState<number | null>(null)
  const inputRef = useRef<HTMLDivElement>(null)

  const handleTextChange = (e: React.FormEvent<HTMLDivElement>) => {
    const textContent = e.currentTarget.textContent || ''
    onChange(textContent)
    const selection = window.getSelection()
    const cursorPos =
      selection && selection.rangeCount > 0 ? getCursorPosition(selection, e.currentTarget) : null
    setCursorPosition(cursorPos)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.ctrlKey && e.code === 'Space') {
      e.preventDefault()
      if (shouldShowVariables()) {
        setAutocompleteOpen(true)
      }
    }
  }

  const shouldShowVariables = (): boolean => {
    if (cursorPosition === null) {
      return false
    }
    const textBeforeCursor = value.slice(0, cursorPosition)
    const textAfterCursor = value.slice(cursorPosition)
    return /{{[^}]*$/.test(textBeforeCursor) && !/}}/.test(textAfterCursor)
  }

  const insertVariable = (variable: string) => {
    if (cursorPosition === null) return

    let beforeCursor = value.slice(0, cursorPosition)
    let afterCursor = value.slice(cursorPosition)

    if (/{{[^}]*$/.test(beforeCursor) && !/}}/.test(afterCursor)) {
      beforeCursor = beforeCursor.replace(/{{[^}]*$/, '{{')
      afterCursor = afterCursor.replace(/^[^}]*}}/, '')
    }

    const newText = `${beforeCursor}${variable}}}${afterCursor}`
    const newCursorPosition = beforeCursor.length + variable.length + 3
    onChange(newText)
    setAutocompleteOpen(false)

    requestAnimationFrame(() => {
      setCursorPosition(newCursorPosition)
      setSelectionAtCursor(newCursorPosition)
    })
  }

  const setSelectionAtCursor = (position: number) => {
    const selection = window.getSelection()
    if (inputRef.current && selection) {
      const range = document.createRange()
      let node = inputRef.current.firstChild
      let offset = position

      while (node) {
        if (node.nodeType === Node.TEXT_NODE) {
          const nodeLength = node.textContent?.length || 0

          if (offset <= nodeLength) {
            range.setStart(node, offset)
            range.collapse(true)
            selection.removeAllRanges()
            selection.addRange(range)
            return
          } else {
            offset -= nodeLength
          }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          const textNode = node.firstChild
          if (textNode && textNode.nodeType === Node.TEXT_NODE) {
            const textLength = textNode.textContent?.length || 0
            if (offset <= textLength) {
              range.setStart(textNode, offset)
              range.collapse(true)
              selection.removeAllRanges()
              selection.addRange(range)
              return
            } else {
              offset -= textLength
            }
          }
        }
        node = node.nextSibling
      }

      range.selectNodeContents(inputRef.current)
      range.collapse(false)
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }

  const getCursorPosition = (selection: Selection, element: HTMLElement): number => {
    const range = selection.getRangeAt(0).cloneRange()
    range.setStart(element, 0)
    return range.toString().length
  }

  const renderHighlightedText = (): string => {
    const regex = /{{(.*?)}}/g
    let lastIndex = 0
    let result = ''
    value.replace(regex, (match, variableName, offset) => {
      result += value.substring(lastIndex, offset)
      if (variablesKeys.includes(variableName)) {
        result += `<span class="variable-highlight">{{${variableName}}}</span>`
      } else {
        result += match
      }
      lastIndex = offset + match.length
      return match
    })
    result += value.substring(lastIndex)
    return result
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.innerHTML = renderHighlightedText()
      if (cursorPosition) {
        setSelectionAtCursor(cursorPosition)
      }
    }
  }, [value, cursorPosition])

  return (
    <Stack>
      {label && (
        <div style={{ display: 'flex', gap: 8 }}>
          <Label>{label}</Label>
        </div>
      )}

      <div className='variable-input-wrapper'>
        <div
          className='variable-input'
          contentEditable
          spellCheck='false'
          ref={inputRef}
          onInput={handleTextChange}
          onKeyDown={handleKeyDown}
          style={{ outline: 'none', whiteSpace: 'pre-wrap' }}
        />
        {autocompleteOpen && (
          <div className='autocomplete'>
            {variablesKeys.map((variable) => (
              <div
                key={variable}
                className='autocomplete-item'
                onClick={() => insertVariable(variable)}
              >
                {variable}
              </div>
            ))}
          </div>
        )}
      </div>
    </Stack>
  )
}
