import React from 'react'
import Widget from '../Widget'
import {
  Container,
  ContainerLimits,
  IconContainer,
  InfoContainer,
  InfoTitle,
  InfoValue,
  ValueContainer,
} from './styles'
import { Skeleton } from '@mui/material'

interface WidgetProps {
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>
  title: string
  value: number | undefined
  isPercentage?: boolean
  iconColor?: string
  isLoading?: boolean
}

export function WidgetPayments({
  Icon,
  iconColor,
  title,
  value,
  isPercentage,
  isLoading,
}: WidgetProps) {
  const formattedNumber = !isPercentage
    ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(value))
    : `${Number(value && Math.floor(value))}%`

  if (isLoading) {
    return (
      <Widget style={{ height: '120px' }}>
        <ContainerLimits>
          <Container>
            <Skeleton variant='circular' width={56} height={56} style={{ marginRight: '24px' }} />
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
              <Skeleton
                variant='rectangular'
                width='40%'
                height={24}
                style={{ marginBottom: '8px' }}
              />
              <Skeleton variant='rectangular' width='60%' height={32} />
            </div>
          </Container>
        </ContainerLimits>
      </Widget>
    )
  }

  return (
    <Widget>
      <ContainerLimits>
        <Container>
          <IconContainer iconColor={iconColor}>
            <Icon className='colorPath' />
          </IconContainer>
          <InfoContainer>
            <InfoTitle>{title}</InfoTitle>
            <ValueContainer>
              <InfoValue>{formattedNumber}</InfoValue>
            </ValueContainer>
          </InfoContainer>
        </Container>
      </ContainerLimits>
    </Widget>
  )
}

export default WidgetPayments
