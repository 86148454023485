import styled from 'styled-components'

export const Card = styled.div`
  width: 100%;

  border: 1px solid #e4e4e7;
  border-radius: 12px;
  padding: 32px 20px 24px;
  position: relative;
  strong {
    position: absolute;
    top: -12px;
    left: 12px;
    background: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.darkishGreen};
    padding: 0 4px;
    font-weight: 600;
    font-size: 18px;
  }
`

export const CardActions = styled.div`
  position: absolute;
  top: -16px;
  right: 12px;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.darkishGreen};
  padding: 0 4px;
  font-weight: 600;
  font-size: 18px;
`
