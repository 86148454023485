import {
  Container,
  Content,
  Infos,
  InputDiv,
  ResponseDiv,
  ResponseTitle,
  Return,
  SendButton,
  SendDiv,
} from './styles'
import { useState } from 'react'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { cemigDemonstrative } from '../../../services/requests/user-requests'
import { InvoicePreviewer } from '../InvoicePreviewer'
import { readers } from '../invoicesUrlPerDistributor'

export function CemigDemonstrative({ setPage }: { setPage: (value: '') => void }) {
  const [status, setStatus] = useState(true)
  const [response, setResponse] = useState<any>({ success: [], errors: [] })
  const [files, setFiles] = useState<File[]>([])

  function sendData() {
    ;(async () => {
      const formData = new FormData()

      if (files.length) {
        setStatus(false)
        for (const file of files) {
          formData.append('files', file)
        }

        const result = await cemigDemonstrative(formData).finally(() => setStatus(true))

        setResponse(result)
      } else {
        alert('Adicione os arquivos xml')
      }
    })()
  }

  return (
    <Container>
      <Infos>
        <Return
          onClick={() => {
            setPage('')
          }}
        >
          <ArrowBack fontSize='small' />
          <p>Voltar</p>
        </Return>
      </Infos>
      {status ? (
        <Content>
          <SendDiv>
            <InputDiv>
              <input
                type='file'
                onChange={(event: any) => {
                  const { files } = event.target

                  if (files) {
                    setFiles(files)
                  }
                }}
              />
            </InputDiv>
            <SendButton onClick={sendData}>Enviar</SendButton>
          </SendDiv>
          <ResponseDiv>
            <div>
              <ResponseTitle>Sucesso</ResponseTitle>
              {response.success.map((val: any, i: number) => (
                <p key={i}>{val}</p>
              ))}
            </div>
            <div>
              <ResponseTitle>Erro</ResponseTitle>
              {response.errors.map((val: any, i: number) => (
                <p key={i}>{val}</p>
              ))}
            </div>
          </ResponseDiv>
          <InvoicePreviewer reader={readers.CEMIG_DEMONSTRATIVO}/>
        </Content>
      ) : (
        <p>Atualizando</p>
      )}
    </Container>
  )
}
