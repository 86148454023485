import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import { useState } from 'react'

interface Props {
  images: Array<{
    fileId: string
    fileUrl: string
    chipText?: string
    description?: string
  }>
}

export default function ImageGallery(props: Props) {
  const { images } = props

  const [selectedImages, setSelectedImages] = useState<string[]>([])

  const maxImageCount = 1

  const handleSelectImage = (imageId: string) => {
    if (selectedImages.includes(imageId)) {
      setSelectedImages(selectedImages.filter((id) => id !== imageId))
    } else if (selectedImages.length < maxImageCount) {
      setSelectedImages([...selectedImages, imageId])
    }
  }

  if (images.length === 0) {
    return (
      <Stack textAlign='center' mt={8}>
        <Typography>Nenhuma imagem foi encontrada.</Typography>
      </Stack>
    )
  }

  return (
    <Stack direction="row" gap={2}>
      {images.map((image) => (
        <Stack key={`image-gallery-${image.fileId}`} style={{ maxWidth: '160px', height: '100%' }} direction="column">
          <Card
            sx={{
              border: selectedImages.includes(image.fileId)
                ? '2px solid #023b21'
                : '2px solid #ddd',
              transition: 'border-color 0.3s',
              borderRadius: 2,
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <CardActionArea onClick={() => handleSelectImage(image.fileId)}>
              <CardMedia
                component='img'
                sx={{
                  height: 150,
                  width: 150,
                  objectFit: 'contain',
                  margin: 'auto',
                }}
                image={image.fileUrl}
              />
              <CardContent sx={{ textAlign: 'center' }}>
                {image.chipText && (
                  <Chip
                    label={image.chipText}
                    size='small'
                    sx={{
                      marginBottom: 1,
                      color: selectedImages.includes(image.fileId) ? '#ffff' : '#4B4B4B',
                      backgroundColor: selectedImages.includes(image.fileId) ? '#023b21' : '#ccc',
                    }}
                  />
                )}
                {image.description && (
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    style={{
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      WebkitLineClamp: 2,
                      textOverflow: 'ellipsis',
                    }}
                    title={image.description}
                  >
                    {image.description}
                  </Typography>
                )}
              </CardContent>
            </CardActionArea>
          </Card>
        </Stack>
      ))}
    </Stack>
  )
}
