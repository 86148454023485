import { useState } from 'react'
import { FloatingFilterWrapper } from '../../../../components/FloatingFilterWrapper'
import { OptionItem, OptionList } from '../../styles'

interface Props {
  value?: number
  onChange: (value?: number) => void
}

export default function PaymentStatusSelect(props: Props) {
  const { value, onChange } = props

  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false)

  return (
    <FloatingFilterWrapper
      checkbox
      isFilterActive={typeof value !== 'undefined'}
      label='Status'
      modalStyles={{ padding: 4 }}
      hideSubmitButton
    >
      <OptionList showDropDown={isDropdownOpen}>
        {[
          { value: 2, label: 'Não cobrado' },
          { value: 1, label: 'Cobrado' },
          { value: 3, label: 'Recebido' },
          { value: 4, label: 'Vencido' },
        ].map((item) => (
          <OptionItem
            active={value === item.value}
            key={item.value}
            onClick={() => {
              setDropdownOpen((previous) => !previous)
              onChange(value !== item.value ? item.value : undefined)
            }}
          >
            {item.label}
          </OptionItem>
        ))}
      </OptionList>
    </FloatingFilterWrapper>
  )
}
