import { useState, useEffect, CSSProperties, memo } from 'react'
import { Document, Page } from 'react-pdf'
import { CenteredContainer, CloseButton, DescriptionWrapper, InvoiceDescription, ModalContent, ModalOverlay, PDFCard, PDFGrid, Title, ViewerContainer } from './styles';
import { Distributor, DistributorUrls, invoicesUrlPerDistributor, Reader, readers } from '../invoicesUrlPerDistributor';
interface PDFViewerProps {
  data: Uint8Array;
  name: string;
  width: number;
  style?: CSSProperties;
}

type Props = {
  reader:Reader;
  distributor?:Distributor;
};
const PDFViewer = memo(({ data,  width, name, style = {} }: PDFViewerProps) => {
  return (
    <ViewerContainer style={style} >
      <Title>{name}</Title>
      <Document
        file={{ data }}
        onSourceError={(error) => console.error('source error', error)}
        onLoadError={(error) => console.error('load error', error)}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Page pageNumber={1} width={width} />
        </div>
      </Document>
    </ViewerContainer>
  );
});
PDFViewer.displayName = 'PDFViewer';

export const InvoicePreviewer = ({ reader, distributor }: Props) => {
  const [pdfData, setPdfData] = useState<{name:string,data:Uint8Array, description?:string}[] | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState<{name:string,data:Uint8Array} | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(reader){
      fetchPdf();
    }
  }, [reader]);

  async function getInvoiceAsBuffer({name, url, description}:DistributorUrls): Promise<{name:string,data:Uint8Array, description?:string}> {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Error fetching PDF: ${response.statusText}`);
    }
    const blob = await response.blob();
    const arrayBuffer = await blob.arrayBuffer();
    return {name,data:new Uint8Array(arrayBuffer), description};
  }

  const fetchPdf = async () => {
    try {
      let invoicesWithBufferPromises: Promise<{ name: string; data: Uint8Array; }>[] = [];
      
      if (reader === readers.AZURE && distributor) {
        
        const distributorInvoices = invoicesUrlPerDistributor[reader][distributor];
        if (distributorInvoices) {
          invoicesWithBufferPromises = distributorInvoices.map((invoice: DistributorUrls) => 
            getInvoiceAsBuffer(invoice)
          );
        }
      } 
      else if(reader !== readers.AZURE) {
        const invoices = invoicesUrlPerDistributor[reader];
        if (invoices) {
          invoicesWithBufferPromises = invoices.map((invoice: DistributorUrls) => 
            getInvoiceAsBuffer(invoice)
          );
        }
      } 
      
      if (invoicesWithBufferPromises.length > 0) {
        const invoices = await Promise.all(invoicesWithBufferPromises);
        setPdfData(invoices);
      } else {
        console.warn('No invoices found for the given reader and/or distributor.');
      }
    } catch (error) {
      console.error('Error loading PDF:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <CenteredContainer>
        <span>Carregando faturas aceitas por esse leitor...</span>
      </CenteredContainer>
    );
  }

  if (!pdfData) {
    return (
      <CenteredContainer>
        <span>Modelos de faturas ainda não adicionados.</span>
      </CenteredContainer>
    );
  }

  return (
    <>
      <div>
        <h1 style={{ textAlign: 'center', marginBottom: '16px' }}>Modelos aceitos pelo leitor</h1>

        <PDFGrid>
          {pdfData.map((invoice, index) => (
            <PDFCard
              key={index}
              onClick={() => {
                setSelectedPdf(invoice);
                setIsModalOpen(true);
              }}
            >
              <PDFViewer {...invoice} width={240} />
              <DescriptionWrapper>
                <InvoiceDescription>{invoice.description}</InvoiceDescription>
              </DescriptionWrapper>
            </PDFCard>
          ))}
        </PDFGrid>
      </div>

      {isModalOpen && selectedPdf && (
        <ModalOverlay onClick={() => setIsModalOpen(false)}>
          <CloseButton onClick={() => setIsModalOpen(false)}>&times;</CloseButton>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <PDFViewer {...selectedPdf} width={800} />
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};
