import styled from 'styled-components'
import { Theme } from '../../../styles/theme'

export const Return = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
`

export const Container = styled.div`
  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;

    button {
      &:first-child {
        background-color: ${Theme.colors.htmlGrey};
      }
      margin-top: 50px;
      font-size: 18px;
      color: ${Theme.colors.white};
      background-color: ${Theme.colors.darkGreen};
      padding: 4px 8px;
      border-radius: 4px;
      text-align: center;

      &:disabled {
        cursor: default;
      }
    }

    form {
      margin-top: 16px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      gap: 24px;
      flex: 1;
    }
    .success {
      p {
        font-size: 24px;
        text-align: center;
      }

      button {
        margin-top: 10px;
      }
    }
  }
`

export const Card = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  
  gap: 16px;

  border: 1px solid #e4e4e7;
  border-radius: 12px;
  padding: 32px 20px 24px;

  position: relative;

  strong {
    position: absolute;
    top: -12px;
    left: 12px;

    background: ${({ theme }) => theme.colors.background};
    padding: 0 4px;

    font-weight: 600;
    font-size: 18px;
  }
`
