export interface UC {
  id: number
  cliente_id_gestor: number
  cliente_id_consumidor: number
  documento_numero: string
  documento_tipo: string
  login?: string
  login_tipo: string
  senha_dist?: string
  distribuidora_id: number
  modalidade_de_compensacao: string
  metodo_pagamento_id: number
  remuneracao_regra: string
  codigo: string
  comissao?: number
  consultor?: string
  consumo_medio?: number
  endereco_bairro?: string
  endereco_cep?: string
  endereco_cidade?: string
  endereco_codigo_cidade?: string
  endereco_complemento?: string
  endereco_logradouro?: string
  endereco_num?: string
  expectativa_geracao?: number
  grupo: 'A' | 'B'
  nome?: string
  nome_razao_social?: string
  subgrupo?: string
  is_active: boolean
  // Remuneration Rule Fields
  isl_sengi?: number
  percentual_sobre_tarifa_consumo?: number
  percentual_por_bandeira_amar?: number
  percentual_por_bandeira_verde?: number
  percentual_por_bandeira_verm_p1?: number
  percentual_por_bandeira_verm_p2?: number
  percentual_sobre_bandeira_valor?: number
  percentual_sobre_compensado_valor?: number
  percentual_sobre_compensado_devolucao?: number
  piso_disponibilidade?: number
  tarifa_fixa_valor?: number
  valor_arrendamento?: number
  valor_fixo?: number
  desconto_fixo_valor?: number
  mes_operacao?: string
  compensado_usina_valor?: number
  vencimento_regra?: ExpiringRule
  vencimento_valor?: number
  vigencia_prevista_compensacao?: string
  cosip_fixo?: number
}

export enum ExpiringRule {
  DIA_FIXO = 'DIA_FIXO',
  BASE_VENCIMENTO = 'BASE_VENCIMENTO',
  BASE_LEITURA = 'BASE_LEITURA',
  DIA_FIXO_60 = 'DIA_FIXO_60',
}

export const expiringRuleMap = {
  [ExpiringRule.DIA_FIXO]: 'Dia fixo',
  [ExpiringRule.BASE_VENCIMENTO]: 'Base de vencimento da fatura',
  [ExpiringRule.BASE_LEITURA]: 'Base de leitura da fatura',
  [ExpiringRule.DIA_FIXO_60]: 'Dia fixo - 2 meses a partir do mês de referência',
}