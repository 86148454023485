import { Stack } from '@mui/material'
import { SelectV2 } from '../../../../../components/SelectV2'
import { SchemaFormatType, SchemaObject } from '../../../../../services/payment-api'
import _ from 'lodash'
import { InputV2 } from '../../../../../components/InputV2'
import SwitchToggle from '../Switch'

export const validationSchemaFormatDisplayNames: Record<SchemaFormatType, string> = {
  [SchemaFormatType.CURRENCY]: 'Monetário',
  [SchemaFormatType.DATE]: 'Data',
  [SchemaFormatType.DECIMAL]: 'Decimal',
  [SchemaFormatType.DOCUMENT]: 'Documento',
  [SchemaFormatType.PERCENTAGE]: 'Porcentagem',
  [SchemaFormatType.STRING]: 'Texto',
}

interface Props {
  value: SchemaObject
  setFieldValue: (key: string, value: unknown) => void
}

export default function ValidationSchemaObject(props: Props) {
  const { value: schema, setFieldValue } = props

  return (
    <Stack gap={2}>
      <SelectV2
        formik={false}
        label='Tipo de Campo'
        required
        value={schema.type}
        onChange={(e) => setFieldValue('type', e.target.value)}
        items={Object.values(SchemaFormatType).map((format) => ({
          id: format,
          displayName: validationSchemaFormatDisplayNames[format],
        }))}
      />
      {schema.type === SchemaFormatType.DATE && (
        <>
          <InputV2
            formik={false}
            label='Formato'
            value={schema.format}
            onChange={(e: any) => setFieldValue('format', e.target.value)}
          />
        </>
      )}
      {schema.type === SchemaFormatType.CURRENCY && (
        <Stack gap={2}>
          <InputV2
            formik={false}
            label='Casas Decimais'
            value={schema.decimalPlaces || ''}
            onChange={(e: any) =>
              setFieldValue('decimalPlaces', e.target.value.replace(/[^0-9]/g, ''))
            }
          />
        </Stack>
      )}
      {schema.type === SchemaFormatType.DECIMAL && (
        <Stack gap={2}>
          <InputV2
            formik={false}
            label='Separador Decimal'
            value={schema.decimalCaseSeparator || ''}
            onChange={(e: any) => setFieldValue('decimalCaseSeparator', e.target.value)}
          />
        </Stack>
      )}

      <Stack direction='row' gap={2}>
        <SwitchToggle
          value={Boolean(schema.required)}
          onChange={(e) => setFieldValue('required', e)}
          label='Obrigatório'
        />
        <SwitchToggle
          value={Boolean(schema.hide)}
          onChange={(e) => setFieldValue('hide', e)}
          label='Esconder'
        />
        {(schema.type === SchemaFormatType.CURRENCY ||
          schema.type === SchemaFormatType.DECIMAL) && (
          <SwitchToggle
            value={Boolean(schema.absolute)}
            onChange={(e) => setFieldValue('absolute', e)}
            label='Valor Absoluto'
          />
        )}

        {schema.type === SchemaFormatType.DECIMAL && (
          <SwitchToggle
            value={Boolean(schema.autoDecimalPlaces)}
            onChange={(e) => setFieldValue('autoDecimalPlaces', e)}
            label='Decimal Auto'
          />
        )}
      </Stack>
    </Stack>
  )
}
