import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded'
import { CircularProgress } from '@mui/material'
import { useContext, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button, Container, FileStatusMessage, FilesList, Text, UploadContainer, Wrapper } from './styles'
import { GlobalContext } from '../../../../../context/global/global'
import { uploadQuotas } from '../../../../../services/requests/user-requests'
import Modal from '../../../../../components/Modal'
import { ButtonsContainer } from '../../../../../components/Modal/PaymentExcel/styles'
import { useToast } from '../../../../../hooks/useToast'
import { CancelButton, SendButton } from '../../../../../components/Modal/Demonstrative/styles'
import ModalDelete from '../../../../../components/Modal/ModalDelete'

interface UploadQuotaModalProps {
  onRefresh: () => void
  openModal: boolean
  setOpenModal: (open: boolean) => void
  referenceMonth: string
}

export function UploadQuotaModal({
  referenceMonth,
  setOpenModal,
  openModal,
  onRefresh
}: UploadQuotaModalProps) {
  const { toast } = useToast()
  const { Theme } = useContext(GlobalContext)

  const [file, setFile] = useState<File | undefined>()
  const [sendQuotaLoading, setSendQuotaLoading] = useState<boolean>(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'text/html': ['.xlsx'],
    },
    onDrop: (files) => {
      setFile(files?.at(0))
    },
    maxFiles: 1
  })

  const handleDeleteAll = () => {
    setFile(undefined)
  }

  const handleUploadDemonstratives = async () => {
    if (!file) {
      toast({
        type: 'warning',
        message: 'Faltando arquivo para upload'
      })
      return
    }

    setSendQuotaLoading(true)

    uploadQuotas({
      file,
      referenceMonth
    })
      .then(() => {
        toast({
          message: 'Upload feito com sucesso!',
          type: 'success'
        })

        setOpenModal(false)
      })
      .catch((err) => {
        const error = err as Error

        toast({
          type: 'error',
          message: error?.message ?? "Ocorreu um erro ao fazer o upload das cotas"
        })
      }).finally(() => {
        setSendQuotaLoading(false)
        onRefresh()
      })
  }

  const handleToghgleConfirmAction = () => setIsConfirmModalOpen(prev => !prev)

  return (
    <>
      <ModalDelete
        openModal={isConfirmModalOpen}
        setModalOpen={handleToghgleConfirmAction}
        buttonColor='darkGreen'
        text='Tem certeza que deseja fazer o upload das cotas?'
        confirmButtonText='Confirmar'
        action={handleUploadDemonstratives}
      />

      <Modal
        title='Upload de cotas'
        openModal={openModal}
        handleOnClose={handleDeleteAll}
        setModalOpen={setOpenModal}
        defaultButtons={false}
      >
        <div>
          <Text>Para o upload de cotas é necessário baixar o Excel a seguir: <a href="https://docs.google.com/spreadsheets/d/1UTKz1Fc4-dpDuZxHEgRyOMeSq9njMqtF/edit?usp=sharing" target="_blank" rel="noreferrer">Link</a></Text>
        </div>

        <div>
          <UploadContainer {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
            <input {...getInputProps()} />
            <p>Arraste o arquivo ou clique para fazer o upload</p>
          </UploadContainer>
          <FilesList>
            {file && (
              <Wrapper>
                <Container>
                  <DescriptionRoundedIcon />
                  {file.name}
                </Container>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {sendQuotaLoading && <FileStatusMessage color={Theme.colors.white} bgColor={Theme.colors.orange}>
                    Enviando...
                  </FileStatusMessage>}
                  <Button onClick={() => handleDeleteAll()}>
                    <DeleteRoundedIcon />
                  </Button>
                </div>
              </Wrapper>
            )}
          </FilesList>
        </div>

        <ButtonsContainer>
          <CancelButton
            disabled={sendQuotaLoading}
            type='button'
            onClick={() => {
              setOpenModal(false)
              handleDeleteAll()
            }}
          >
            Fechar
          </CancelButton>

          <SendButton disabled={sendQuotaLoading} onClick={handleToghgleConfirmAction}>
            {sendQuotaLoading ? <CircularProgress size={20} /> : 'Enviar'}
          </SendButton>
        </ButtonsContainer>
      </Modal>
    </>
  )
}
