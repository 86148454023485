import { useState } from 'react'
import { useToast } from '../../../../../hooks/useToast'
import { paymentApi } from '../../../../../services/payment-api'
import { useInvoiceTemplate } from '../../hooks/useInvoiceTemplate'
import { CircularProgress, IconButton, IconButtonProps, Tooltip } from '@mui/material'
import { PictureAsPdf } from '@mui/icons-material'

type Props = IconButtonProps

export default function DownloadTemplatePreviewButton(props: Props) {
  const { disabled, ...rest } = props;

  const { templateConfig, templateData } = useInvoiceTemplate()

  const [isLoading, setLoading] = useState<boolean>(false)

  const { toast } = useToast()

  const downloadPreview = async () => {
    if (!templateConfig || !templateData) {
      return
    }
    try {
      setLoading(true)
      const templateBuffer = await paymentApi.downloadTemplatePreview({
        managerId: templateConfig.clientId,
        templateConfig,
        templateData,
      })
      const blob = new Blob([templateBuffer])
      const element = document.createElement('a')
      element.href = URL.createObjectURL(blob)
      element.download = `preview.pdf`
      document.body.appendChild(element)
      element.click()
    } catch (error) {
      toast({
        type: 'error',
        message: (error as Error)?.message ?? 'Erro ao baixar template',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Tooltip title={<p style={{ fontSize: 15 }}>Baixar PDF</p>} placement='bottom'>
      <IconButton onClick={downloadPreview} disabled={disabled} {...rest}>
        {!isLoading ? (
          <PictureAsPdf />
        ) : (
          <CircularProgress
            style={{ width: '24px', height: '24px', color: 'rgba(0, 0, 0, 0.54)' }}
          />
        )}
      </IconButton>
    </Tooltip>
  )
}
