import { api } from './api'

export interface GetManagers {
  result: {
    id: number
    cliente_nome: string
  }[]
}
export interface CreateClient {
  params: {
    name: string
    documentNumber: string
    email: string
    managerId?: number
  },
  result: void
}
export interface ListClientsFromManager {
  params: {
    managerId: number
  },
  result: {
    id: number
    clientName: string
    documentNumber: string
  }[]
}

export type ListClientsResult = {
  id: number
  email: string
}
export interface ListClients {
  params: {
    email: string
    take?: number
  }
  result: ListClientsResult[]
}

class ClientService {
  async getManagers(): Promise<GetManagers['result']> {
    const response = await api.get('/super/client-managers')
    return response.data
  }
  async createClient(params: CreateClient['params']): Promise<CreateClient['result']> {
    await api.post('/super/clients', params)
  }
  async listClientsFromManager(params: ListClientsFromManager['params']): Promise<ListClientsFromManager['result']> {
    const { managerId } = params
    const response = await api.get('/super/clients', {
      params: {
        managerId,
      }
    })
    return response.data
  }
  async listClients(params: ListClients['params']): Promise<ListClients['result']> {
    const { email, take } = params

    const clients = await api.get('/super/clients/search', {
      params: {
        email,
        take
      }
    })
    return clients.data
  }

  async updateStatus(params: { clientId: number, status: 'ACTIVE' | 'INACTIVE' | 'OVERDUE' }) {
    await api.put(`/super/clients/${params.clientId}/status`, {
      status: params.status
    })
  }
}

export const clientService = new ClientService()
