import { Box, TextareaAutosize, Typography } from '@mui/material'
import { useState } from 'react'
import Modal from '../../../../../components/ModalV2'
import { Button } from '../../../../../components/Button'

function isValidSvg(svgString: string) {
  try {
    const parser = new DOMParser()
    const doc = parser.parseFromString(svgString, 'image/svg+xml')

    const parserError = doc.querySelector('parsererror')
    if (parserError) {
      return false
    }

    const rootElement = doc.documentElement
    if (
      rootElement.nodeName !== 'svg' ||
      rootElement.namespaceURI !== 'http://www.w3.org/2000/svg'
    ) {
      return false
    }

    return true
  } catch (error) {
    return false
  }
}

interface Props {
  value: string
  onChange: (value: string) => void
}

export default function SvgViewer(props: Props) {
  const { value, onChange } = props

  const [isModalOpen, setModalOpen] = useState(false)
  const [inputValue, setInputValue] = useState(value)

  const saveInputValue = () => {
    onChange(inputValue)
    setModalOpen(false)
  }

  const openModal = () => {
    setModalOpen(true)
    if (!isValidSvg(inputValue)) {
      setInputValue(value)
    }
  }

  const svgToDisplay = isValidSvg(inputValue) ? inputValue : value
  const isSvgToDisplayValid = isValidSvg(svgToDisplay)

  return (
    <>
      {isModalOpen && (
        <Modal.Provider
          style={{
            display: 'flex',
            width: '100%',
            position: 'relative',
          }}
          maxWidth={500}
          isOpen
          onClose={() => setModalOpen(false)}
        >
          <Modal.Title>
            Editar SVG
            <Modal.CloseButton alignLeft />
          </Modal.Title>
          <Modal.Body>
            <TextareaAutosize
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              style={{
                width: '100%',
                height: '350px',
                marginBottom: '16px',
                padding: '8px',
                fontFamily: 'monospace',
                fontSize: '14px',
                resize: 'none',
                overflowY: 'auto',
              }}
            />

            <Button text='Concluido' onClick={saveInputValue} />
          </Modal.Body>
        </Modal.Provider>
      )}

      {!isSvgToDisplayValid ? (
        <Typography variant='body1' color='red'>
          SVG Inválido
        </Typography>
      ) : (
        <Box style={{ maxWidth: '120px', cursor: 'pointer', border: '1px solid #023b21', borderRadius: '4px' }} onClick={openModal}>
          <div dangerouslySetInnerHTML={{ __html: svgToDisplay }} />
        </Box>
      )}
    </>
  )
}
