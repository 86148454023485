import axios, { AxiosError } from 'axios'
import { LocalStorageKeys } from '../interfaces/local-storage'
import { TemplateData } from '../pages/Superuser/InvoiceTemplate/hooks/useInvoiceTemplate'

export enum SchemaFormatType {
  STRING = 'string',
  DOCUMENT = 'document',
  DECIMAL = 'decimal',
  PERCENTAGE = 'percentage',
  DATE = 'date',
  CURRENCY = 'currency',
}

export interface BaseSchemaObject {
  type: SchemaFormatType
  required?: boolean
  hide?: boolean
}

export type SchemaObject =
  | StringSchema
  | DocumentSchema
  | DecimalSchema
  | PercentageSchema
  | CurrencySchema
  | DateSchema

export interface DateSchema extends BaseSchemaObject {
  type: SchemaFormatType.DATE
  format: string
}

export interface DecimalSchema extends BaseSchemaObject {
  type: SchemaFormatType.DECIMAL
  decimalPlaces?: number
  absolute?: boolean
  autoDecimalPlaces?: boolean
  decimalCaseSeparator?: string
}

export interface CurrencySchema extends BaseSchemaObject {
  type: SchemaFormatType.CURRENCY
  decimalPlaces?: number
  absolute?: boolean
}

export interface StringSchema extends BaseSchemaObject {
  type: SchemaFormatType.STRING
}

export interface DocumentSchema extends BaseSchemaObject {
  type: SchemaFormatType.DOCUMENT
}

export interface PercentageSchema extends BaseSchemaObject {
  type: SchemaFormatType.PERCENTAGE
}

export type SchemaFormatTypeMap = {
  [SchemaFormatType.STRING]: StringSchema
  [SchemaFormatType.DOCUMENT]: DocumentSchema
  [SchemaFormatType.DECIMAL]: DecimalSchema
  [SchemaFormatType.PERCENTAGE]: PercentageSchema
  [SchemaFormatType.CURRENCY]: CurrencySchema
  [SchemaFormatType.DATE]: DateSchema
}

export interface GetTemplateConfigResponse {
  templateConfig: Record<string, any>
  validationSchema: Record<string, SchemaObject>
}

export interface GetTemplateConfigsParams {
  managerId: number
}

export interface TemplateConfig {
  id: number
  clientId: number
  configs: Record<string, any>
  validationSchema: Record<string, SchemaObject>
}

export interface GetTemplateConfigsResponse {
  templateConfigs: TemplateConfig[]
}

export interface GetLatestInvoiceClosingParams {
  managerId: number
}

export interface GetLatestInvoiceClosingResponse {
  id: number
}

export type GetParentTemplatesResponse = TemplateConfig[]

export interface GetTemplateAssetsFromCustomerParams {
  managerId: number
}

export interface TemplateAsset {
  id: number
  fileId: string
  fileType: string
  fileName: string
  assetType: string
}

export type GetTemplateAssetsFromCustomerResponse = Array<
  TemplateAsset & {
    url: string
  }
>

export interface GetTemplatePreviewParams {
  managerId: number
  templateConfig: Pick<TemplateConfig, 'configs' | 'validationSchema'>
  templateData: {
    charts: Record<string, any>
    templateVariables: Record<string, any>
  }
}

export interface TemplateInfo {
  templateConfig: TemplateConfig
  templateData: TemplateData
}

export interface GetTemplateData {
  invoiceClosingId?: number
  templateConfigId: number
  managerId: number
}

export interface CreateTemplateParams {
  managerId: number
  parentConfigId: number
}

export interface UpdateTemplateParams {
  templateConfigId: number
  templateConfig: TemplateConfig
}

export interface UpdateTemplateResponse {
  templateConfig: TemplateConfig
}

export interface UploadClientAssetParams {
  managerId: number
  assetType: string
  file: File
}

export interface DownloadTemplatePreviewParams {
  managerId: number
  templateConfig: Pick<TemplateConfig, 'configs' | 'validationSchema'>
  templateData: {
    charts: Record<string, any>
    templateVariables: Record<string, any>
  }
}

const api = axios.create({
  baseURL: process.env.REACT_APP_PAYMENTS_API,
})

api.interceptors.request.use((config) => {
  const auth = JSON.parse(localStorage.getItem(LocalStorageKeys.AUTH) ?? '{}')
  if (auth?.token) {
    config.headers = {
      Authorization: `Bearer ${auth.token}`,
      Accept: 'application/json',
    }
  }
  return config
})

api.interceptors.response.use(
  (response: any) => {
    return response
  },
  async (error: any) => {
    if (error instanceof AxiosError) {
      if (error.response && error.response.data.message) {
        return Promise.reject(new Error(error.response.data.message))
      }
      if (error.request) {
        return Promise.reject(new Error(error.request))
      }
    }
    if (error instanceof Error) {
      return Promise.reject(error)
    }
    return Promise.reject(new Error('Erro inesperado'))
  },
)

export class PaymentApi {
  async getTemplateConfigs(params: GetTemplateConfigsParams): Promise<GetTemplateConfigsResponse> {
    const { managerId } = params
    const response = await api.get(`/invoice-template-config/manager/${managerId}/templates`)
    return response.data
  }
  async getLatestClosingFromManager(params: GetLatestInvoiceClosingParams) {
    const { managerId } = params
    const response = await api.get(`/invoice-closing/manager/${managerId}/latest`)
    return response.data
  }
  async getParentTemplateConfigs(): Promise<GetParentTemplatesResponse> {
    const response = await api.get('/invoice-template-config/parent')
    return response.data
  }
  async getTemplateAssetsFromCustomer(
    params: GetTemplateAssetsFromCustomerParams,
  ): Promise<GetTemplateAssetsFromCustomerResponse> {
    const { managerId } = params
    const response = await api.get(`/template-asset/customer/${managerId}/assets`)
    return response.data
  }
  async getTemplatePreview(params: GetTemplatePreviewParams): Promise<string> {
    const { templateConfig, templateData, managerId } = params
    const response = await api.post('/invoices/templates/preview', {
      templateConfig,
      templateData,
      managerId,
    })
    return response.data
  }
  async getTemplateData(params: GetTemplateData): Promise<TemplateInfo> {
    const { invoiceClosingId, templateConfigId, managerId } = params
    const response = await api.post('/invoices/templates/data', {
      invoiceClosingId,
      templateConfigId,
      managerId,
    })
    return response.data
  }
  async createTemplate(params: CreateTemplateParams): Promise<TemplateInfo> {
    const { managerId, parentConfigId } = params
    const response = await api.post('/invoice-template-config/new', {
      managerId,
      parentConfigId,
    })
    return response.data
  }
  async uploadClientAsset(params: UploadClientAssetParams): Promise<void> {
    const { assetType, file, managerId } = params

    const formData = new FormData()
    formData.append('assetType', assetType)
    formData.append('file', file)

    const response = await api.post(`/template-asset/customer/${managerId}/assets/upload`, formData)
    return response.data
  }
  async updateTemplate(params: UpdateTemplateParams): Promise<void> {
    const { templateConfig, templateConfigId } = params
    await api.put(`/invoice-template-config/${templateConfigId}`, { templateConfig })
  }
  async downloadTemplatePreview(params: DownloadTemplatePreviewParams): Promise<Buffer> {
    const { templateConfig, managerId, templateData } = params
    const response = await api.post(
      `/invoices/templates/preview/download`,
      {
        templateConfig,
        managerId,
        templateData,
      },
      {
        responseType: 'arraybuffer',
      },
    )
    return response.data
  }
}

export const paymentApi = new PaymentApi()
