import { IconButton, Stack } from '@mui/material'
import Modal from '../../../../../components/ModalV2'
import { ArrowBack } from '@mui/icons-material'
import { InputV2 } from '../../../../../components/InputV2'
import { useState } from 'react'
import _ from 'lodash'
import { StickyFooter } from '../../../InvoiceValidation/components/InvoiceModal/styles'
import { Button } from '../../../../../components/Button'

interface FormData {
  key: string
  value: unknown
}

interface Props {
  onClose: () => void
  goBack?: () => void
  onCreate: (data: FormData) => void
}

export default function CreateTemplateFieldModal(props: Props) {
  const { onClose, goBack, onCreate } = props

  const [formData, setFormData] = useState<FormData>({
    key: '',
    value: '',
  })

  const setFieldValue = (key: string, value: unknown) => {
    setFormData((previous) => {
      const updatedData = { ...previous }
      _.set(updatedData, key, value)
      return updatedData
    })
  }

  const createTemplateField = () => {
    onCreate(formData)
    onClose()
  }

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        width: '100%',
        position: 'relative',
        height: '100%',
        maxHeight: '750px',
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        {goBack && (
          <IconButton style={{ marginRight: '8px' }} onClick={goBack}>
            <ArrowBack />
          </IconButton>
        )}
        Adicionar Propriedade
        <Modal.CloseButton alignLeft />
      </Modal.Title>
      <Modal.Body style={{ overflowY: 'auto' }}>
        <Stack gap={2}>
          <InputV2
            formik={false}
            label='Nome da variável'
            required
            value={formData.key}
            onChange={(e: any) =>
              setFieldValue(
                'key',
                e.target.value.replace(/[^a-zA-Z0-9_]/g, '').replace(/^[^a-z]/, ''),
              )
            }
          />
          <InputV2
            formik={false}
            label='Valor'
            required
            value={formData.value}
            onChange={(e: any) => setFieldValue('value', e.target.value)}
          />
        </Stack>
      </Modal.Body>
      <StickyFooter style={{ padding: '16px', marginTop: 'auto' }}>
        <Button
          containerStyle={{ margin: 0 }}
          text='Adicionar Propriedade'
          onClick={createTemplateField}
        />
      </StickyFooter>
    </Modal.Provider>
  )
}
