import { useEffect, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { ContainerPreviewInvoice } from './styles'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
import { SendButton } from '../../../../components/Modal/Payments/EditPaymentModal/styles'
import { useToast } from '../../../../hooks/useToast'
import { getInvoiceData } from '../../../../services/requests/user-requests'
import Modal from '../../../../components/ModalV2'
import { StickyFooter } from '../../../Superuser/InvoiceValidation/components/InvoiceModal/styles'
import Loading from '../../../../components/Loading'
import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material'
import PreviewInvoiceSkeleton from './components/PreviewInvoiceSkeleton'

interface InvoicePreviewData {
  type: 'html' | 'pdf'
  toRender: string | { type: string; data: Buffer }
  toDownload: {
    filename: string
    content: { type: string; data: Buffer }
  }
}

const PreviewHtmlInvoice = ({ toRender }: Pick<InvoicePreviewData, 'toRender'>) => {
  const ContainerPreviewInvoiceRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (ContainerPreviewInvoiceRef.current) {
      ContainerPreviewInvoiceRef.current.innerHTML = toRender as string
    }
  }, [ContainerPreviewInvoiceRef])

  return <div style={{ maxWidth: '780px' }} ref={ContainerPreviewInvoiceRef} />
}

interface Props {
  invoiceClosingId: number
  dateSelected: {
    month: number
    year: number
  }
  onClose: () => void
}

export function PreviewInvoiceModal(props: Props) {
  const { invoiceClosingId, dateSelected, onClose } = props

  const [isDownloading, setDownloading] = useState<boolean>(false)

  const [previewData, setPreviewData] = useState<InvoicePreviewData>()

  const { toast } = useToast()

  const downloadPreview = async (pdf: Buffer, fileName: string) => {
    if (!previewData) {
      toast({
        message: 'Preview não encontrada',
        type: 'error',
      })
      return
    }
    setDownloading(true)
    const file = new Blob([new Uint8Array(pdf).buffer])
    const element = document.createElement('a')
    element.href = URL.createObjectURL(file)
    element.download = fileName
    document.body.appendChild(element)
    element.click()
    setDownloading(false)
  }

  const PreviewType = ({ type, toRender }: Omit<InvoicePreviewData, 'toDownload'>) => {
    switch (type) {
      case 'html':
        return <PreviewHtmlInvoice toRender={toRender as string} />

      case 'pdf':
        return (() => {
          const myBuffer = (toRender as { data: Buffer }).data
          return (
            <Document
              file={{ data: new Uint8Array(myBuffer) }}
              onSourceError={(error) => console.log('source error', error)}
              loading={<PreviewInvoiceSkeleton />}
              onLoadError={(error) => console.log('load error', error)}
            >
              <Page pageNumber={1} width={720} />
            </Document>
          )
        })()
    }
  }

  const fetchInvoicePreview = async () => {
    try {
      const {
        data: { data },
      } = await getInvoiceData({ ...dateSelected, fechamento_id: invoiceClosingId })
      setPreviewData(data)
    } catch (err) {
      onClose()
      toast({
        type: 'error',
        message: 'Não foi possivel localizar os dados da cobrança.',
      })
    }
  }

  useEffect(() => {
    fetchInvoicePreview()
  }, [])

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        maxHeight: '750px',
        position: 'relative',
      }}
      maxWidth={760}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        Pré-visualização da cobrança
        <Modal.CloseButton alignLeft />
      </Modal.Title>
      <Modal.Body style={{ overflowY: 'auto' }}>
        <ContainerPreviewInvoice>
          {previewData?.type && previewData?.toRender && (
            <PreviewType type={previewData.type} toRender={previewData.toRender} />
          )}
          {/* {!previewData && <Loading />} */}
          {!previewData && <PreviewInvoiceSkeleton />}
        </ContainerPreviewInvoice>
      </Modal.Body>
      <StickyFooter style={{ padding: '16px', marginTop: 'auto' }}>
        {previewData?.toDownload && (
          <SendButton
            onClick={() =>
              downloadPreview(previewData.toDownload.content.data, previewData.toDownload.filename)
            }
            style={{ width: '100%' }}
            disabled={isDownloading}
          >
            {isDownloading ? 'Carregando..' : 'Baixar PDF'}
          </SendButton>
        )}
      </StickyFooter>
    </Modal.Provider>
  )
}
