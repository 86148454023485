import { CircularProgress } from '@mui/material'
import React, { FormEvent } from 'react'
import logo from '../../../../assets/logos/serra-logo.png'
import { ResetPasswordModal } from '../../../../components/Modal/Login/ResetPassword'
import LanguageIcon from '@mui/icons-material/Language';
import {
  ButtonEnter,
  ForgotPasswordText,
  ImageContainer,
  InputContainer,
  InvalidInputText,
  LoginInfoContainer,
  LoginPageContainer,
} from './styles'
import { Input } from './components/custom-input'
import { useLogin } from '../../../../hooks/useLogin'
import { OverdueModal } from '../../components/OverdueModal';

const validateEmail = (value: string) => /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
  value,
)

export const LoginSerraDoAmolar = () => {
  const { handleLogin, formState } = useLogin()

  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')

  const [passwordOnblur, setPasswordOnblur] = React.useState(false)
  const [emailOnblur, setEmailOnblur] = React.useState(false)
  const [resetPasswordModal, setResetPasswordModal] = React.useState(false)
  const [isOverdueModalOpen, setIsOverdueModalOpen] = React.useState(false)

  const isMailValid = validateEmail(email)

  async function handleSubmitLogin(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!isMailValid || !password) return

    const result = await handleLogin({
      email,
      password
    })

    if (result?.error === 'status' && result?.type === 'OVERDUE') {
      setIsOverdueModalOpen(true)
    }
  }

  function handleOverdueModal() {
    setIsOverdueModalOpen(prev => !prev)
  }

  return (
    <LoginPageContainer>
      {resetPasswordModal && (
        <ResetPasswordModal openModal={resetPasswordModal} setModalOpen={setResetPasswordModal} />
      )}
      <ImageContainer>
        <LoginInfoContainer>
          <section>
            <img id="logo" src={logo.toString()} />
          </section>
          <form onSubmit={handleSubmitLogin}>
            <InputContainer>
              <Input
                label='E-mail'
                type='email'
                placeholder='emai.email@gmail.com'
                onChange={(e: any) => setEmail(e.target.value)}
                value={email}
                onBlur={() => setEmailOnblur(true)}
              />
              {!isMailValid && emailOnblur && <InvalidInputText>Email inválido*</InvalidInputText>}

              <Input
                label='Senha'
                type='password'
                placeholder='* * * * * * * * *'
                onChange={(e: any) => setPassword(e.target.value)}
                value={password}
                onBlur={() => {
                  setPasswordOnblur(true)
                }}
              />
              {!password && passwordOnblur && <InvalidInputText>Senha obrigatória*</InvalidInputText>}
              <ForgotPasswordText onClick={() => setResetPasswordModal(true)}>
                Esqueci minha senha
              </ForgotPasswordText>
            </InputContainer>
            <ButtonEnter disabled={!isMailValid || !password}>
              {formState.isLoading ? <CircularProgress size={30} /> : 'Entrar'}
            </ButtonEnter>

            <footer>
              <span>
                <LanguageIcon sx={{
                  fontSize: 36,
                }} />
                <a href="https://serradoamolar.co">serradoamolar.co</a>
              </span>
            </footer>
          </form>
        </LoginInfoContainer>
      </ImageContainer>
      {
        isOverdueModalOpen && (
          <OverdueModal onClose={handleOverdueModal} />
        )
      }
    </LoginPageContainer>
  )
}
