import { useEffect, useState } from 'react'
import { FloatingCheckboxes } from '../../../../components/FloatingCheckboxes'
import { useToast } from '../../../../hooks/useToast'
import { distributorListGet } from '../../../../services/requests/user-requests'
import { CheckboxType } from '../../../../interfaces/checkbox'

interface Props {
  value: number[]
  onChange: (value: number[]) => void
}

export default function DistributorSelect(props: Props) {
  const { value, onChange } = props

  const [distributors, setDistributors] = useState<CheckboxType[]>([])

  const { toast } = useToast()

  const fetchDistributors = async () => {
    try {
      const response = await distributorListGet()
      const distribuitorPayload = response.data.map(
        (distributor: { id: number; nome: string }) => ({
          id: distributor.id,
          label: distributor.nome,
          checked: value.includes(distributor.id),
        }),
      )
      setDistributors(distribuitorPayload)
    } catch (error: any) {
      toast({
        type: 'error',
        message: error.message ?? 'Erro ao buscar distribuidoras',
      })
    }
  }

  useEffect(() => {
    fetchDistributors()
  }, [])

  return (
    <FloatingCheckboxes
      isFilterActive={distributors.some(({ checked }) => checked === true)}
      label='Distribuidoras'
      options={distributors}
      selectableAll
      searchable
      submitAction={(checkTypes) => {
        onChange(
          checkTypes
            .filter((distributor) => distributor.checked)
            .map((distributor) => Number(distributor.id)),
        )
      }}
    />
  )
}
