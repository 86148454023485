import styled from 'styled-components'
import { Theme } from '../../../../styles/theme'


export const Container = styled.div`
  position: relative;

  button {
    font-size: 18px;
    padding: 4px 8px;
    border-radius: 4px;
    text-align: center;

    &:disabled {
      cursor: default;
    }
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px 12px 32px;
    
    max-height: 85dvh;
    overflow-y: scroll;
  }
`

export const Card = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  
  gap: 16px;

  border: 1px solid #e4e4e7;
  border-radius: 12px;
  padding: 32px 20px 24px;

  position: relative;

  strong {
    position: absolute;
    top: -12px;
    left: 12px;

    background: ${({ theme }) => theme.colors.background};
    padding: 0 4px;

    font-weight: 600;
    font-size: 18px;
  }
`
