import styled from 'styled-components';


export const ViewerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled.h2`
  text-align: center;
`;

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

export const PDFGrid = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  flex-wrap: wrap;
`;

export const PDFCard = styled.div`
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 8px;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 600;
  overflow: auto;
  padding: 20px;
`;

export const ModalContent = styled.div`
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 80%;
  max-width: 800px;
  max-height: 90vh;
  overflow: auto;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  font-size: 50px;
  cursor: pointer;
  color: white;
  z-index: 1000;
`;
export const InvoiceDescription = styled.p`
  font-size: 1rem;
  text-align: center;
  margin-top: 4px;
  width: 240px;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
