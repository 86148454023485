import { useState } from 'react'
import { InputV2 } from '../../../../../components/InputV2'
import { SchemaFormatType, SchemaObject } from '../../../../../services/payment-api'
import ValidationSchemaObject from '../ValidationSchemaObject'
import _ from 'lodash'
import Modal from '../../../../../components/ModalV2'
import { StickyFooter } from '../../../InvoiceValidation/components/InvoiceModal/styles'
import { Stack } from '@mui/material'
import { Button } from '../../../../../components/Button'

interface FormData {
  key: string
  schema: SchemaObject
}

interface Props {
  onCreate: (data: FormData) => void
  onClose: () => void
}

export default function CreateValidationSchemaObjectModal(props: Props) {
  const { onCreate, onClose } = props

  const [formData, setFormData] = useState<FormData>({
    key: '',
    schema: {
      type: SchemaFormatType.STRING,
    },
  })

  const setFieldValue = (key: string, value: unknown) => {
    setFormData((previous) => {
      const updatedData = { ...previous }
      _.set(updatedData, key, value)
      return updatedData
    })
  }

  const createObject = () => {
    onCreate(formData)
    onClose()
  }

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        width: '100%',
        position: 'relative',
        height: '100%',
        maxHeight: '750px',
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        Novo Campo
        <Modal.CloseButton alignLeft />
      </Modal.Title>
      <Modal.Body
        style={{ overflowY: 'auto', display: 'flex', flexDirection: 'column', gap: '16px' }}
      >
        <InputV2
          formik={false}
          label='Nome da variável'
          required
          value={formData.key}
          onChange={(e: any) =>
            setFieldValue(
              'key',
              e.target.value.replace(/[^a-zA-Z0-9_]/g, '').replace(/^[^a-z]/, ''),
            )
          }
        />
        <ValidationSchemaObject
          value={formData.schema}
          setFieldValue={(key, value) => setFieldValue(`schema.${key}`, value)}
        />
      </Modal.Body>
      <StickyFooter
        style={{
          padding: '16px',
          marginTop: 'auto',
        }}
      >
        <Stack direction='row' gap={2} width='100%'>
          <Button
            containerStyle={{ margin: 0, border: '2px solid #023b21' }}
            mode='white'
            text='Voltar'
            onClick={onClose}
          />
          <Button containerStyle={{ margin: 0 }} text='Criar Campo' onClick={createObject} />
        </Stack>
      </StickyFooter>
    </Modal.Provider>
  )
}
