import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { DashboardLayout } from './layouts/dashboard'
import {
  DashboardConsumer,
  DashboardGenerator,
  PowerPlant,
  ConsumerUnit,
  Registers,
  Invoices,
  MyUnitsTable,
  Greeter,
} from './pages'
import { Login, LoginSerraDoAmolar } from './pages/Login/variations'
import { Consumer } from './pages/MyUnits/MyUnitsTables/Consumer'
import InvoiceHistory from './pages/Invoices/History'
import CreditsBalance from './pages/Credits/Balance'
import CreditsAlocation from './pages/Credits/Allocation'
import CreditsAuditory from './pages/Credits/Auditory'

import Settings from './pages/Settings'
import { Payments } from './pages/Payments'
import { Measurements } from './pages/Measurements'
import { BillingControl } from './pages/Superuser/BillingControl'
import { Tickets } from './pages/Tickets'
import { InvoicesLocation } from './pages/Invoices/Location'
import { Report } from './pages/Report'
import { ReportEdit } from './pages/Report/Edit'
import { DemonstrativeToInvoice } from './pages/DemonstrativeToInvoice'
import { Fees } from './pages/Fees'
import ConferencePage from './pages/Superuser/Conference'
import ConferenceChartsPage from './pages/Superuser/ConferenceCharts'
import InvoiceParserPage from './pages/Superuser/InvoiceParser'
import PlantsInvoicing from './pages/PlantsInvoicing'
import BulkUpdatePage from './pages/Superuser/BulkUpdate'
import InvoicesConsumerPage from './pages/Invoices/Consumer'
import InvoiceValidationPage from './pages/Superuser/InvoiceValidation'
import PermissionsPage from './pages/Superuser/Permissions/Users'
import RolesPage from './pages/Superuser/Permissions/Roles'
import OrchestratorPage from './pages/Superuser/Orchestrator'
import { SocketProvider } from './hooks/useSocket'
import { AggregatedPaymentsPage } from './pages/AggregatedPayments'
import PaymentMethodsPage from './pages/Superuser/PaymentMethods'
import InvoiceErrorsPage from './pages/Superuser/InvoiceErrors'
import AdminDashboard from './pages/Superuser/Dashboard'
import AlertsPage from './pages/Superuser/Alerts'
import PanicControlSystem from './pages/Superuser/PanicControlSystem'
import InvoiceTemplatePage from './pages/Superuser/InvoiceTemplate'
import { InvoiceTemplateProvider } from './pages/Superuser/InvoiceTemplate/hooks/useInvoiceTemplate'

export function Router() {
  return (
    <Routes>
      <Route path='/' element={<Greeter />} >
        <Route path='/sign-in' element={<Login />} />
        <Route path='/sign-in/serra-do-amolar' element={<LoginSerraDoAmolar />} />
      </Route>
      <Route path='/dashboard' element={<DashboardLayout />}>
        <Route path='/dashboard/generator' element={<DashboardGenerator />} />
        <Route path='/dashboard/consumer' element={<DashboardConsumer />} />
      </Route>
      <Route path='/measurements' element={<DashboardLayout />}>
        <Route path='/measurements' element={<Measurements />} />
      </Route>
      <Route path='/credits' element={<DashboardLayout />}>
        <Route path='/credits/balance' element={<CreditsBalance />} />
        <Route path='/credits/allocation' element={<CreditsAlocation />} />
        <Route path='/credits/auditory' element={<CreditsAuditory />} />
      </Route>
      <Route path='/invoices' element={<DashboardLayout />}>
        <Route path='/invoices/distributor' element={<Invoices />} />
        <Route path='/invoices/location' element={<InvoicesLocation />} />
        <Route path='/invoices/history' element={<InvoiceHistory />} />
        <Route path='/invoices/consumer/history' element={<InvoicesConsumerPage />} />
      </Route>
      <Route path='/my-units' element={<DashboardLayout />}>
        <Route path='/my-units' element={<MyUnitsTable />}>
          <Route path='/my-units/power-plant' element={<PowerPlant />} />
          <Route path='/my-units/consumer-units' element={<ConsumerUnit />} />
          <Route path='/my-units/consumer' element={<Consumer />} />
        </Route>
        <Route path='/my-units/register' element={<Registers />} />
      </Route>
      <Route path='/settings' element={<DashboardLayout />}>
        <Route path='/settings' element={<Settings />} />
      </Route>
      <Route path='/report' element={<DashboardLayout />}>
        <Route path='/report' element={<Report />} />
      </Route>
      <Route path='/report/edit' element={<DashboardLayout />}>
        <Route path='/report/edit' element={<ReportEdit />} />
      </Route>
      <Route path='/demonstrative' element={<DashboardLayout />}>
        <Route path='/demonstrative' element={<DemonstrativeToInvoice />} />
      </Route>
      <Route path='/fees' element={<DashboardLayout />}>
        <Route path='/fees' element={<Fees />} />
      </Route>
      <Route path='/dashboard/super' element={<DashboardLayout />}>
        <Route path='/dashboard/super' element={<AdminDashboard />} />
      </Route>
      <Route path='/tickets' element={<DashboardLayout />}>
        <Route path='/tickets' element={<Tickets />} />
      </Route>
      <Route path='/alerts' element={<DashboardLayout />}>
        <Route path='/alerts' element={<AlertsPage />} />
      </Route>
      <Route path='/payments' element={<DashboardLayout />}>
        <Route path='/payments' element={<SocketProvider><Payments /></SocketProvider>} />
      </Route>
      <Route path='/payments/aggregated' element={<DashboardLayout />}>
        <Route path='/payments/aggregated' element={<AggregatedPaymentsPage />} />
      </Route>
      <Route path='/payments/financial' element={<DashboardLayout />}>
        <Route path='/payments/financial' element={<PlantsInvoicing />} />
      </Route>
      <Route path='/billingcontrol' element={<DashboardLayout />}>
        <Route path='/billingcontrol' element={<BillingControl />} />
      </Route>
      <Route path='/conferences' element={<DashboardLayout />}>
        <Route path='/conferences' element={<ConferencePage />} />
      </Route>
      <Route path='/conferences/charts' element={<DashboardLayout />}>
        <Route path='/conferences/charts' element={<ConferenceChartsPage />} />
      </Route>
      <Route path='/invoice-parser' element={<DashboardLayout />}>
        <Route path='/invoice-parser' element={<InvoiceParserPage />} />
      </Route>
      <Route path='/invoices/validation' element={<DashboardLayout />}>
        <Route path='/invoices/validation' element={<InvoiceValidationPage />} />
      </Route>
      <Route path='/invoices/errors' element={<DashboardLayout />}>
        <Route path='/invoices/errors' element={<InvoiceErrorsPage />} />
      </Route>
      <Route path='/invoices/custom' element={<DashboardLayout />}>
        <Route path='/invoices/custom' element={<InvoiceTemplateProvider><InvoiceTemplatePage /></InvoiceTemplateProvider>} />
      </Route>
      <Route path='/orchestrator' element={<DashboardLayout />}>
        <Route path='/orchestrator' element={<OrchestratorPage />} />
      </Route>
      <Route path='/payment-methods' element={<DashboardLayout />}>
        <Route path='/payment-methods' element={<PaymentMethodsPage />} />
      </Route>
      <Route path='/bulk-update' element={<DashboardLayout />}>
        <Route path='/bulk-update' element={<BulkUpdatePage />} />
      </Route>
      <Route path='/permissions/users' element={<DashboardLayout />}>
        <Route path='/permissions/users' element={<PermissionsPage />} />
      </Route>
      <Route path='/permissions/roles' element={<DashboardLayout />}>
        <Route path='/permissions/roles' element={<RolesPage />} />
      </Route>
      <Route path='/pcs' element={<DashboardLayout />}>
        <Route path='/pcs' element={<PanicControlSystem />} />
      </Route>
    </Routes>
  )
}
