import { GridColDef } from '@mui/x-data-grid'
import { Box, DataGrid } from './styles'
import SortedAscIcon from '../../assets/arrow-drop-up.svg'
import SortedDescIcon from '../../assets/arrow-drop-down.svg'
import UnsortedIcon from '../../assets/arrow-drop.svg'
import { SxProps, Theme } from '@mui/material/styles'
import { createTheme, Skeleton, ThemeProvider } from '@mui/material'
import { DefaultTheme } from 'styled-components'
import { GlobalContext } from '../../context/global/global'
import { Sort } from '../../interfaces/conference'
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

interface Props {
  columns: GridColDef[]
  rows: Record<string, unknown>[]
  styles?: SxProps<Theme> | undefined
  alignText?: 'center' | 'left' | 'right'
  isSelectable?: boolean
  selectedItems?: number[]
  setSelectedItems?: (items: number[]) => void
  messageNoRow?: string
  sort?: Sort
  onSort?: (params?: Sort) => void
  changeHeaderColor?: string
  serverSorting?: boolean
}

export const getHiddenColumns = (cols: GridColDef[]) => cols.reduce((acc, item) => {
  const isHidden = item.hide
  if (isHidden) {
    acc[item.field] = false
  }

  return acc
}, [] as unknown as Record<string, boolean>)

const getNextSortingValue = (value: string | undefined | null): Sort['order'] | undefined => {
  if (value === undefined || value === null) {
    return 'asc'
  }
  if (value === 'asc') {
    return 'desc'
  }

  if (value === 'desc') {
    return undefined
  }
}

interface Props {
  columns: GridColDef[]
  rows: Record<string, unknown>[]
  styles?: SxProps<Theme> | undefined
  alignText?: 'center' | 'left' | 'right'
  isSelectable?: boolean
  selectedItems?: number[]
  setSelectedItems?: (items: number[]) => void
  messageNoRow?: string
  sort?: Sort
  onSort?: (params?: Sort) => void
  changeHeaderColor?: string
  serverSorting?: boolean
  loading?: boolean
}

const muiTheme = (Theme: DefaultTheme) =>
  createTheme({
    palette: {
      primary: {
        main: Theme.colors.green,
      },
    },
  })

export function TableV2(props: PropsWithChildren<Props>) {
  const {
    columns,
    rows,
    styles,
    alignText = 'left',
    isSelectable,
    setSelectedItems,
    changeHeaderColor,
    messageNoRow,
    sort,
    onSort,
    serverSorting,
    children,
    selectedItems,
    loading
  } = props
  const tableRef = useRef<HTMLDivElement>(null)

  const { Theme } = useContext(GlobalContext)

  const [formattedColumns, setFormattedColumns] = useState<GridColDef<any, any, any>[]>([])

  useEffect(() => {
    if (!columns?.length) return

    const cols = columns.map((col) => ({
      ...col,
      align: alignText,
      headerAlign: alignText,
      ...(!col.width ? { flex: 1 } : {})
    })) as GridColDef<any, any, any>[]

    setFormattedColumns(cols)
  }, [columns])

  const sortModel = useMemo(
    () =>
      formattedColumns.map((column) => ({
        field: column.field,
        sort: sort && sort.field === column.field ? sort.order : undefined,
      })),
    [formattedColumns, sort],
  )

  const hiddenColumns = useMemo(() => getHiddenColumns(columns), [columns])

  if (loading) {
    const visibleColumns = formattedColumns.filter(col => !col.hide);
    return (
      <Box
        style={{
          borderRadius: '8px',
          overflow: 'hidden',
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: Theme.colors.darkishGreen,
            height: '56px',
            width: '100%',
            padding: '0 16px',
          }}
        >
          {visibleColumns.map((_, colIndex) => (
            <Skeleton
              key={`header-${colIndex}`}
              variant='text'
              width='100%'
              height='42px'
              style={{
                flex: 1,
                marginLeft: colIndex === 0 ? 0 : '16px',
              }}
            />
          ))}
        </Box>

        {Array.from({ length: 8 }).map((_, rowIndex) => (
          <Box
            key={`row-${rowIndex}`}
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '52px',
              width: '100%',
              padding: '0 16px',
              borderTop: '1px solid rgba(224, 224, 224, 1)',
              backgroundColor: rowIndex % 2 === 0 ? '#ffffff' : '#f1f1f1',
            }}
          >
            {visibleColumns.map((_, colIndex) => (
              <Skeleton
                key={`row-${rowIndex}-col-${colIndex}`}
                variant='rectangular'
                style={{
                  flex: 1,
                  height: '24px',
                  marginLeft: colIndex === 0 ? 0 : '16px',
                }}
              />
            ))}
          </Box>
        ))}
      </Box>
    )
  }

  return (
    <ThemeProvider theme={muiTheme(Theme)}>
      <Box sx={styles}>
        <DataGrid
          columnVisibilityModel={hiddenColumns}
          ref={tableRef}
          changeHeaderColor={changeHeaderColor ?? ''}
          rows={rows}
          columns={formattedColumns}
          checkboxSelection={isSelectable}
          disableSelectionOnClick
          sortModel={sortModel}
          style={{
            border: 0,
          }}
          sortingMode={serverSorting ? 'server' : 'client'}
          experimentalFeatures={{ newEditingApi: true }}
          disableColumnMenu={true}
          autoHeight
          components={{
            ColumnSortedDescendingIcon: () => <img src={SortedDescIcon.toString()} />,
            ColumnSortedAscendingIcon: () => <img src={SortedAscIcon.toString()} />,
            ColumnUnsortedIcon: () => <img src={UnsortedIcon.toString()} />,
            NoRowsOverlay: () => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <p>{messageNoRow ? messageNoRow : 'No rows'}</p>
              </div>
            ),
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'RowEven' : 'RowOdd'
          }
          onColumnHeaderClick={(headerParams) => {
            if (!onSort) {
              return
            }
            const columnFound = columns.find((col) => col.field === headerParams.field)
            if (!columnFound || !columnFound.sortable) {
              return
            }
            const currentSortOrder =
              sort && sort.field === headerParams.field ? sort.order : undefined
            const order = getNextSortingValue(currentSortOrder)
            onSort(
              order
                ? {
                  field: headerParams.field,
                  order,
                }
                : undefined,
            )
          }}
          hideFooterSelectedRowCount
          hideFooterPagination
          selectionModel={selectedItems}
          onSelectionModelChange={(selectedItems) => {
            setSelectedItems && setSelectedItems(selectedItems as number[])
          }}
        />

        {children}
      </Box>
    </ThemeProvider>
  )
}