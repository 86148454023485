import { useEffect, useState } from 'react'
import { useToast } from '../../../../hooks/useToast'
import { StickyFooter } from '../../../Superuser/InvoiceValidation/components/InvoiceModal/styles'
import Modal from '../../../../components/ModalV2'
import {
  ContainerList,
  PaymentButton,
  SendButton,
  CancelButton,
} from '../../../../components/Modal/PaymentExcel/styles'
import { CircularProgress } from '@mui/material'
import { Label } from '../../../../components/Input/styles'

const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
]

interface Props {
  dateSelected: {
    month: number
    year: number
  }
  onDownload: (referenceMonths: string[]) => Promise<Buffer>
  onClose: () => void
}

export default function DownloadExcelModal(props: Props) {
  const { dateSelected, onClose, onDownload } = props

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selected, setSelected] = useState<string[]>([getPreviousMonths(dateSelected).reverse()[0]])
  const [dates, setDates] = useState<string[]>(getPreviousMonths(dateSelected))

  const { toast } = useToast()

  function getPreviousMonths({ month, year }: { month: number; year: number }): string[] {
    const result: string[] = []

    for (let i = 0; i < 12; i++) {
      if (month - i <= 0) {
        result.unshift(`${months[month - i + 11]} - ${year - 1}`)
      } else {
        result.unshift(`${months[month - i - 1]} - ${year}`)
      }
    }

    return result
  }

  async function sendData() {
    setIsLoading(true)

    const monthRefs = selected.map((date) => {
      const [month, year] = date.split(' - ')
      const monthIndex = String(months.indexOf(month) + 1).padStart(2, '0')
      return `${year}-${monthIndex}-01`
    })

    try {
      const buffer = await onDownload(monthRefs)
      const blob = new Blob([buffer])
      const element = document.createElement('a')
      element.href = URL.createObjectURL(blob)
      element.download = `pagamentos_${dateSelected.month + '_' + dateSelected.year}.xlsx`
      document.body.appendChild(element)
      element.click()
      onClose()
    } catch (error) {
      toast({
        message: 'Erro ao baixar planilha',
        type: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setSelected([])
    if (dateSelected) {
      setDates(getPreviousMonths(dateSelected))

      setSelected([getPreviousMonths(dateSelected).reverse()[0]])
    }
  }, [dateSelected])

  function selectDate(val: string) {
    if (selected.includes(val)) {
      setSelected((old) => old.filter((filtered) => filtered !== val))
    } else {
      setSelected((old: string[]) => [...old, val])
    }
  }

  return (
    <>
      <Modal.Provider
        style={{
          display: 'flex',
          width: '100%',
          maxHeight: '750px',
          position: 'relative',
        }}
        maxWidth={500}
        isOpen
        onClose={onClose}
      >
        <Modal.Title>
          Baixar planilha de Pagamentos
          <Modal.CloseButton alignLeft />
        </Modal.Title>
        <Modal.Body style={{ overflowY: 'auto' }}>
          <Label mode style={{ marginBottom: '32px', marginTop: '16px' }}>
            Selecione os meses dos pagamentos em que deseja adicionar a planilha
          </Label>

          <ContainerList>
            {dates.map((val) => (
              <PaymentButton
                onClick={() => selectDate(val)}
                active={selected.includes(val)}
                key={val}
              >
                {val}
              </PaymentButton>
            ))}
          </ContainerList>
        </Modal.Body>

        <StickyFooter style={{ gap: '16px', padding: '16px', marginTop: 'auto' }}>
          <CancelButton style={{ width: '100%' }} disabled={false} type='button' onClick={onClose}>
            Cancelar
          </CancelButton>
          <SendButton style={{ width: '100%' }} onClick={sendData} disabled={isLoading}>
            {isLoading ? <CircularProgress size={20} /> : `Baixar`}
          </SendButton>
        </StickyFooter>
      </Modal.Provider>
    </>
  )
}
