import { FilterDrama, Shuffle } from '@mui/icons-material'
import { Box, IconButton, Stack, Tooltip } from '@mui/material'
import { useState } from 'react'
import Modal from '../../../../../components/ModalV2'
import { StickyFooter } from '../../../InvoiceParser/components/InvoiceModal/styles'
import { Button } from '../../../../../components/Button'
import ManagerSelect from '../../../../../components/ManagerSelect'
import { InputV2 } from '../../../../../components/InputV2'
import { paymentApi, TemplateConfig, TemplateInfo } from '../../../../../services/payment-api'
import { useToast } from '../../../../../hooks/useToast'
import { SelectV2 } from '../../../../../components/SelectV2'
import { format } from 'date-fns'
import { Card } from '../TemplateFields/styles'
import { Label } from '../../../../../components/InputV2/styles'

interface Props {
  onLoadTemplate: (params: TemplateInfo) => void
  onClose: () => void
}

export default function LoadTemplateModal(props: Props) {
  const { onLoadTemplate, onClose } = props

  const [generateFakeData, setGenerateFakeData] = useState<boolean>(false)
  const [managerId, setManagerId] = useState<number>()
  const [invoiceClosingId, setInvoiceClosingId] = useState<number>()

  const [templateConfigs, setTemplateConfigs] = useState<TemplateConfig[]>([])
  const [selectedTemplateConfigId, setSelectedTemplateConfigId] = useState<number>()

  const [isLoadingTemplate, setLoadingTemplate] = useState<boolean>(false)

  const { toast } = useToast()

  const fetchManagerTemplates = async (managerId: number) => {
    try {
      const { templateConfigs } = await paymentApi.getTemplateConfigs({ managerId })
      setTemplateConfigs(templateConfigs)
    } catch (error) {
      toast({
        message: error instanceof Error ? error?.message : 'Erro ao buscar templates',
        type: 'error',
      })
    }
  }

  const fetchLatestClosing = async () => {
    if (!managerId) {
      return
    }
    try {
      const latestClosing = await paymentApi.getLatestClosingFromManager({ managerId })
      setInvoiceClosingId(latestClosing.id)
    } catch (error) {
      toast({
        message: error instanceof Error ? error?.message : 'Erro ao buscar ultimo fechamento',
        type: 'error',
      })
    }
  }

  const selectManager = async (managerId: number) => {
    setManagerId(managerId)
    setGenerateFakeData(false)
    setInvoiceClosingId(undefined)
    setSelectedTemplateConfigId(undefined)
    setTemplateConfigs([])

    if (managerId) {
      await fetchManagerTemplates(managerId)
    }
  }

  const loadTemplate = async () => {
    if (!selectedTemplateConfigId || !managerId || isLoadingTemplate) {
      return
    }
    try {
      setLoadingTemplate(true)
      const templateInfo = await paymentApi.getTemplateData({
        invoiceClosingId,
        templateConfigId: selectedTemplateConfigId,
        managerId,
      })
      onLoadTemplate(templateInfo)
      onClose()
    } catch (error) {
      toast({
        message: error instanceof Error ? error?.message : 'Erro ao carregar template',
        type: 'error',
      })
    } finally {
      setLoadingTemplate(false)
    }
  }

  const selectedTemplateConfig = templateConfigs.find(
    (config) => config.id === selectedTemplateConfigId,
  )

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        width: '100%',
        position: 'relative',
        height: '100%',
        maxHeight: '750px',
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        Carregar Template
        <Modal.CloseButton alignLeft />
      </Modal.Title>
      <Modal.Body style={{ overflowY: 'auto' }}>
        <ManagerSelect
          onChange={(_, val) => selectManager(val ? val : 0)}
          renderInput={(params) => <InputV2 {...params} required formik={false} label='Gestor' />}
        />
        <Stack style={templateConfigs.length === 0 ? { opacity: 0.6, pointerEvents: 'none' } : {}}>
          <SelectV2
            label='Template'
            formik={false}
            value={selectedTemplateConfigId}
            required
            onChange={(e) => setSelectedTemplateConfigId(Number(e.target.value))}
            items={templateConfigs.map((templateConfig, index) => ({
              id: (templateConfig as any).id,
              displayName: `Template #${index + 1} (${format(new Date(), 'dd/MM/yyyy')})`,
            }))}
          />
          <Stack direction='column' mt={2}>
            <Label>Fechamento</Label>
            <Stack direction='row' alignItems='center' justifyContent='center'>
              <InputV2
                formik={false}
                inputContainerStyle={{ paddingRight: '8px' }}
                value={invoiceClosingId || ''}
                onChange={(e: any) => setInvoiceClosingId(e.target.value.replace(/[^0-9]/g, ''))}
                leftIcon={
                  <Stack direction='row'>
                    <Tooltip
                      title={<p style={{ fontSize: 15 }}>Ultimo Fechamento</p>}
                      placement='bottom'
                    >
                      <IconButton onClick={fetchLatestClosing}>
                        <Shuffle />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={<p style={{ fontSize: 15 }}>Dados Fake</p>} placement='bottom'>
                      <IconButton
                        onClick={() => {
                          setInvoiceClosingId(190109)
                          setGenerateFakeData((previous) => !previous)
                        }}
                      >
                        <FilterDrama style={generateFakeData ? { color: '#023b21' } : {}} />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                }
              />
            </Stack>
          </Stack>
        </Stack>

        {selectedTemplateConfig && (
          <>
            <Card style={{ marginTop: '32px' }}>
              <strong>Configs</strong>
              <Box
                component='pre'
                sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', fontSize: '16px' }}
              >
                {JSON.stringify(selectedTemplateConfig.configs, null, 2)}
              </Box>
            </Card>
            <Card style={{ marginTop: '32px' }}>
              <strong>Schema</strong>
              <Box
                component='pre'
                sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', fontSize: '16px' }}
              >
                {JSON.stringify(selectedTemplateConfig.validationSchema, null, 2)}
              </Box>
            </Card>
          </>
        )}
      </Modal.Body>
      <StickyFooter style={{ padding: '16px', marginTop: 'auto' }}>
        <Button containerStyle={{ margin: 0 }} text='Carregar' onClick={loadTemplate} isLoading={isLoadingTemplate} />
      </StickyFooter>
    </Modal.Provider>
  )
}
