import { Box, Chip, CircularProgress, Divider, Icon, List, ListItem, Paper, Stack, Tooltip, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useContext, useEffect, useState } from 'react'
import {
  invoiceClosePut,
  recalculateBilling,
  resendInvoicesPost,
  sendInvoicesPost,
} from '../../../../services/requests/user-requests'
import { FormikInput } from '../../../FormikComponents'
import { GenericTooltip } from '../../../Tooltip'
import {
  ButtonContainer,
  ButtonsContainer,
  CancelButton,
  EmailsListContainer,
  EmailsListWrapper,
  SendButton,
} from './styles'

import InfoIcon from '@mui/icons-material/Info'
import { GlobalContext } from '../../../../context/global/global'
import { ActionTableButton } from '../../../Table/ActionTableButton'
import { EditPaymentModalProps, SubmitData } from './types'
import { useToast } from '../../../../hooks/useToast'
import PaymentStatusModal from '../PaymentStatusModal'
import { useSocket } from '../../../../hooks/useSocket'
import { utcDateToTz } from '../../../../utils/date-utils'
import { format } from 'date-fns'
import Modal from '../../../ModalV2'
import { InputV2 } from '../../../InputV2'
import { Email } from '@mui/icons-material'

function getDisabledUpdateDueDateStatus({
  canUpdatePayment,
  status,
}: {
  canUpdatePayment: boolean
  status: string
}) {
  switch (canUpdatePayment) {
    case true: {
      if (status === 'Recebido') {
        return true
      }
      return false
    }
    default:
      if (status === 'Recebido' || status === 'Cobrado') {
        return true
      }
      return false
  }
}

export function EditPaymentModal({
  openModal,
  setModalOpen,
  confirmCharge,
  receiveChargeId,
  receivePaymentData,
  postAction,
  sendAll,
  selectedIds,
  emailsList,
  selectedDate,
}: EditPaymentModalProps) {
  const { Theme, state } = useContext(GlobalContext)

  const { socket } = useSocket()

  const [opacity, setOpacity] = useState(1)
  const [modalSuccess, setModalSuccess] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false)
  const [isRecalculateLoading, setIsRecalculateLoading] = useState(false)
  const [invoicesBeingSent, setInvoicesBeingSent] = useState<
    {
      invoiceClosingId: number
      uc?: {
        name: string
        code: string
      }
    }[]
  >([])
  const [selectedCharge, setSelectedCharge] = useState<any>()

  const isDisabledUpdateDueDate = getDisabledUpdateDueDateStatus({
    canUpdatePayment: state.auth?.customer?.canUpdatePayment
      ? state.auth?.customer?.canUpdatePayment
      : false,
    status: selectedCharge?.status,
  })

  const { toast } = useToast()

  function handleCloseModal(value: boolean) {
    setModalSuccess(value)
    setOpacity(1)
    setModalOpen(value)
    postAction && postAction()
  }

  function formattedDate(value: string) {
    const date = value
    const day = date.split('/')[0]
    const month = date.split('/')[1]
    const year = date.split('/')[2]

    return year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2)
  }

  function recalculateCharge() {
    recalculateBilling({
      fatura_id: selectedCharge.fatura_id,
    })
      .then(() => setIsRecalculateLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsRecalculateLoading(false))
  }

  async function handleSubmit(values: { remuneracao_geracao: string; vencimento: string }) {
    try {
      setIsLoading(true)
      const payload: SubmitData = {
        fechamento_id: receiveChargeId!,
        remuneracao_geracao: Number(
          values.remuneracao_geracao.replace('.', '').replace(',', '.').trimStart(),
        ),
        vencimento: values.vencimento ? formattedDate(values.vencimento) : 'undefined',
      }

      if (confirmCharge) {
        // if (!socket || !socket.connected) {
        //   throw new Error('É necessário estar conectado ao servidor de pagamentos para realizar o envio')
        // }

        if (selectedCharge.email_enviado) {
          const response = await resendInvoicesPost({
            ...payload,
            ...selectedDate,
            fechamentos_id: [payload.fechamento_id!],
          })
          if(response.data.length === 0) {
            throw new Error('Erro ao receber resposta do servidor')
          }
          setInvoicesBeingSent(response.data)
          return
        }

        const response = await sendInvoicesPost({
          ...payload,
          ...selectedDate,
          fechamentos_id: [payload.fechamento_id!],
        })
        if(response.data.length === 0) {
          throw new Error('Erro ao receber resposta do servidor')
        }
        setInvoicesBeingSent(response.data)
      } else {
        await invoiceClosePut({
          ...payload,
          remuneracao_geracao: payload.remuneracao_geracao!,
          id: receiveChargeId!,
        })

        toast({
          message: 'Cobrança atualizada com sucesso',
          type: 'success',
        })

        setModalOpen(false)
        setOpacity(0)
        setIsLoading(false)
        setModalOpen(false)
        setModalSuccess(false)
        if(postAction) {
          postAction()
        }
      }
    } catch (err: any) {
      toast({
        message: "Não foi possivel enviar a cobrança",
        type: 'error',
      })
      setOpacity(1)
      setIsLoading(false)
      setModalOpen(false)
    }
  }

  async function handleSendAll() {
    try {
      setIsLoading(true)
      const response = await sendInvoicesPost({
        ...selectedDate,
        fechamentos_id: selectedIds!,
      })
      if(response.data.length === 0) {
        throw new Error('Erro ao receber resposta do servidor')
      }
      setInvoicesBeingSent(response.data)
      // setModalOpen(false)
      // setIsLoading(false)
      // setOpacity(0)
      // setModalOpen(true)
      // setModalSuccess(true)
    } catch (err) {
      console.log((err as Error).message)
      toast({
        message: (err as Error).message,
        type: 'error',
      })
      setOpacity(1)
      setIsLoading(false)
      setModalOpen(false)
    }
  }

  function getChargeInfo() {
    const charge =
      receivePaymentData &&
      receivePaymentData.find((item) => item.fechamento_id === receiveChargeId)
    setSelectedCharge(charge)
  }

  useEffect(() => {
    getChargeInfo()
  }, [receivePaymentData, receiveChargeId])

  return (
    <>
      <PaymentStatusModal
        isOpen={invoicesBeingSent.length > 0}
        invoicesToListen={invoicesBeingSent}
        onClose={() => setModalOpen(false)}
      />

      {invoicesBeingSent.length === 0 && openModal && (
        <Modal.Provider
          style={{
            display: 'flex',
            width: '100%',
            position: 'relative',
          }}
          maxWidth={500}
          isOpen
          onClose={() => setModalOpen(false)}
        >
          <Modal.Title>
            {`${confirmCharge || sendAll ? 'Confirmar dados' : 'Editar informações'} de cobrança`}
            <Modal.CloseButton alignLeft />
          </Modal.Title>
          <Modal.Body style={{ overflowY: 'auto' }}>
            {!(confirmCharge || sendAll) && selectedCharge && (
              <ButtonContainer>
                {isRecalculateLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <ActionTableButton
                    icon
                    onClick={recalculateCharge}
                    disabled={
                      selectedCharge.status === 'Recebido' ||
                      (selectedCharge.status === 'Cobrado' &&
                        selectedCharge.email_status !== 'Erro') ||
                      (selectedCharge.status === 'Vencido' && selectedCharge.email_status !== 'Erro')
                    }
                  >
                    Recalcular Cobrança
                  </ActionTableButton>
                )}
              </ButtonContainer>
            )}

            {confirmCharge || sendAll
                ? 
                <Typography sx={{ marginBottom: 2 }} color={Theme.colors.quartzGrey}>
                  {`Confirme os dados de cobrança.`}
                  <br />
                  {`A cobrança será ${
                    selectedCharge && selectedCharge.email_enviado ? 'reenviada' : 'enviada'
                  } para o e-mail cadastrado no sistema.`}
                </Typography>
                : ''}

            {selectedCharge && selectedCharge.versao_leitor === 'demonstrativo'
              ? <Typography color="red" sx={{ marginBottom: 2 }}>Esta cobrança é feita baseada nos dados do demonstrativo!</Typography> : ''}

            {selectedCharge && !sendAll && (
              <Formik
                initialValues={{
                  remuneracao_geracao:
                    selectedCharge &&
                    new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                      .format(selectedCharge?.remuneracao_geracao)
                      .replace('R$', ''),
                  vencimento:
                    selectedCharge &&
                    new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                      new Date(selectedCharge?.data_vencimento),
                    ),
                }}
                onSubmit={(values) => handleSubmit(values)}
                validateOnChange={true}
                validateOnBlur={true}
              >
                {() => (
                  <Form>
                    <Stack gap={2}>
                      <InputV2 
                        id='remuneracao_geracao'
                        label="Valor devido (R$)"
                        required
                        disabled={
                          (confirmCharge || sendAll) || (
                          selectedCharge.status === 'Recebido' ||
                          (selectedCharge.status === 'Cobrado' &&
                            selectedCharge.email_status !== 'Erro') ||
                          (selectedCharge.status === 'Vencido' &&
                            selectedCharge.email_status !== 'Erro'))
                        }
                      />
                      <InputV2 
                        id='vencimento'
                        label='Vencimento (dd/mm/aa)'
                        required
                        disabled={confirmCharge || sendAll || isDisabledUpdateDueDate}
                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      />
                      {emailsList && emailsList.filter((email) => email && email.length > 0) && (
                        <Box 
                          sx={{ 
                            backgroundColor: 'transparent', 
                            maxWidth: '600px', 
                          }}
                        >
                          <Box 
                            sx={{ 
                              display: 'flex', 
                              alignItems: 'center', 
                              marginBottom: 2, 
                              gap: 1,
                              borderBottom: '1px solid #e0e0e0',
                              paddingBottom: 1,
                            }}
                          >
                            <Tooltip title="A cobrança será enviada para os e-mails listados abaixo." placement="top">
                              <Icon>
                                <InfoIcon sx={{ color: Theme.colors.darkishGreen, fontSize: 20 }} />
                              </Icon>
                            </Tooltip>
                            <Typography variant="body1" component="h3" sx={{ fontWeight: '500', color: '#333' }}>
                              Emails de Recebimento
                            </Typography>
                          </Box>

                          <Box 
                        >
                          <List 
                            sx={{ 
                              padding: 0, 
                              maxHeight: '300px', 
                              overflowY: 'auto', 
                            }}
                          >
                            {emailsList.map((email, index) => (
                              <ListItem 
                                key={index} 
                                sx={{ 
                                  padding: 1.5, 
                                  backgroundColor: '#f1f1f1', 
                                  borderRadius: '4px', 
                                  marginBottom: 1,
                                  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 2,
                                }}
                              >
                                <Email sx={{ color: '#888' }} />
                                <Typography variant="body2" component="span" sx={{ color: '#444', flexGrow: 1 }}>
                                  {email}
                                </Typography>
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      </Box>
                    )}
                  </Stack>
                  <ButtonsContainer>
                    <CancelButton
                      style={{ width: '100%' }}
                      disabled={isLoading}
                      type='button'
                      onClick={() => {
                        setModalOpen(false)
                      }}
                    >
                      Cancelar
                    </CancelButton>
                    <SendButton style={{ width: '100%' }} type='submit' disabled={isLoading}>
                      {isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        `${
                          confirmCharge
                            ? `${selectedCharge.email_enviado ? 'Reenviar' : 'Enviar'}`
                            : sendAll
                            ? 'Confirmar'
                            : 'Salvar'
                        }`
                      )}
                    </SendButton>
                  </ButtonsContainer>
                </Form>
              )}
            </Formik>
          )}
          {sendAll && (
            <ButtonsContainer>
              <CancelButton
                disabled={isLoading || isRecalculateLoading}
                onClick={() => {
                  setModalOpen(false)
                }}
              >
                Cancelar
              </CancelButton>
              <SendButton onClick={handleSendAll} disabled={isLoading || isRecalculateLoading}>
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  `${confirmCharge || sendAll ? 'Enviar' : 'Salvar'}`
                )}
              </SendButton>
            </ButtonsContainer>
          )}
          </Modal.Body>
        </Modal.Provider>
      )}
    </>
  )
}
