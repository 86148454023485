import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import Modal from '../../../../../components/ModalV2'
import { ArrowBack, Delete, InsertPhoto, WarningAmber } from '@mui/icons-material'
import { StickyFooter } from '../../../InvoiceValidation/components/InvoiceModal/styles'
import { Button } from '../../../../../components/Button'
import { useRef, useState } from 'react'
import { SelectV2 } from '../../../../../components/SelectV2'
import { useFormik } from 'formik'
import { useToast } from '../../../../../hooks/useToast'
import { paymentApi } from '../../../../../services/payment-api'

interface FormData {
  assetType: string
  file: File
}

interface Props {
  managerId: number
  onSuccess?: () => void
  goBack?: () => void
  onClose: () => void
}

export default function UploadClientAssetModal(props: Props) {
  const { managerId, onClose, onSuccess, goBack } = props

  const [isSubmitting, setSubmitting] = useState<boolean>(false)

  const { toast } = useToast()

  const handleSubmit = async (values: FormData) => {
    if (isSubmitting) {
      return
    }
    try {
      setSubmitting(true)
      await paymentApi.uploadClientAsset({
        assetType: values.assetType,
        file: values.file,
        managerId,
      })
      if (onSuccess) {
        onSuccess()
      }
      onClose()
    } catch (error) {
      toast({
        message: error instanceof Error ? error?.message : 'Erro ao buscar imagens',
        type: 'error',
      })
    } finally {
      setSubmitting(false)
    }
  }

  const formik = useFormik<FormData>({
    initialValues: {} as FormData,
    onSubmit: handleSubmit,
  })

  const file = formik.values.file

  const inputFileRef = useRef<HTMLInputElement>(null)

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        width: '100%',
        position: 'relative',
        height: '100%',
        maxHeight: '750px',
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        {goBack && (
          <IconButton style={{ marginRight: '8px' }} onClick={goBack}>
            <ArrowBack />
          </IconButton>
        )}
        Upload de Imagem
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      <Modal.Body style={{ overflowY: 'auto' }}>
        <Stack gap={2}>
          <Stack
            direction='row'
            alignItems='center'
            spacing={2}
            style={{
              backgroundColor: '#fff3e0',
              borderRadius: '8px',
              padding: '16px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            <WarningAmber style={{ color: '#ffa726', fontSize: '32px', marginBottom: 'auto' }} />
            <Typography style={{ color: '#d84315', fontWeight: 'bold' }}>
              Ao enviar uma nova logo, a logo antiga desse mesmo tipo cadastrada para o cliente será
              substituída imediatamente pela nova, sem possibilidade de recuperação.
            </Typography>
          </Stack>

          <SelectV2
            label='Tipo de Imagem'
            required
            formik={false}
            value={formik.values.assetType}
            onChange={(e) => formik.setFieldValue('assetType', e.target.value)}
            items={[
              { id: 'LOGO_MEDIUM', displayName: 'Logo Média' },
              { id: 'LOGO_LARGE', displayName: 'Logo Grande' },
            ]}
          />

          <Stack alignItems='center' justifyContent='center'>
            {file && (
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  borderRadius: 2,
                  padding: '32px',
                  backgroundColor: '#f1f1f1',
                }}
              >
                <img
                  src={URL.createObjectURL(file)}
                  style={{
                    width: '250px',
                    height: 'auto',
                    display: 'block',
                    margin: '0 auto',
                    border: '1px solid red',
                  }}
                />
                <Tooltip title={<p style={{ fontSize: 15 }}>Remover Imagem</p>} placement='bottom'>
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      backgroundColor: '#ff4d4f',
                      color: '#fff',
                      '&:hover': { backgroundColor: '#e63946' },
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                    }}
                    size='small'
                    onClick={() => formik.setFieldValue('file', undefined)}
                  >
                    <Delete fontSize='small' />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Stack>

          <input
            ref={inputFileRef}
            onChange={(e) => formik.setFieldValue('file', e.target.files?.[0])}
            type='file'
            accept='image/*'
            style={{ display: 'none' }}
          />

          <button
            style={{
              backgroundColor: '#f1f1f1',
              fontSize: '16px',
              height: '45px',
              borderRadius: '8px',
              fontWeight: '600',
              color: '#023b21',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
            }}
            onClick={() => inputFileRef?.current?.click()}
          >
            <InsertPhoto />
            {!file ? 'Selecionar Imagem' : 'Trocar Imagem'}
          </button>
        </Stack>
      </Modal.Body>
      <StickyFooter style={{ padding: '16px', marginTop: 'auto' }}>
        <Button
          containerStyle={{ margin: 0 }}
          text='Enviar'
          isLoading={isSubmitting}
          onClick={() => formik.submitForm()}
        />
      </StickyFooter>
    </Modal.Provider>
  )
}
