import {
  CSSProperties,
  ChangeEvent,
  ForwardRefExoticComponent,
  InputHTMLAttributes,
  ReactNode,
  RefAttributes,
  RefObject,
  forwardRef,
} from 'react'
import { CustomInput, InputDiv, Label } from './styles'
import { Field, FieldProps } from 'formik'
import { Box, TextFieldProps, Typography } from '@mui/material'
import TextMask, { MaskedInputProps } from 'react-text-mask'

type InputElementProps = TextFieldProps
type TextAreaElementProps = InputHTMLAttributes<HTMLTextAreaElement>

type InputAttributes = InputElementProps | (TextAreaElementProps & { rows?: number })

type InputProps = InputAttributes & {
  containerStyle?: CSSProperties
  inputContainerStyle?: CSSProperties
  labelStyle?: CSSProperties
  label?: string
  textarea?: boolean
  formik?: boolean
  autoGrow?: boolean
  mask?: Array<string | RegExp> | Omit<MaskedInputProps, keyof React.InputHTMLAttributes<HTMLInputElement>>
  rightIcon?: ReactNode
  leftIcon?: ReactNode
}

interface InputSubComponents {
  Container?: any
}

/* eslint-disable react/display-name */
export const InputV2 = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(
  (props, ref) => {
    const { containerStyle, labelStyle, id, inputContainerStyle, textarea, formik = true, label, rightIcon, leftIcon, mask, ...rest } = props

    function buildInput(fieldProps?: FieldProps) {
      const hasError = !!(fieldProps && fieldProps.meta.touched &&!!fieldProps.meta.error)
      return (
        <div style={{ width: '100%', ...containerStyle }}>
          {label && (
            <div style={{ display: 'flex', gap: 8, ...(labelStyle ? labelStyle : {}) }}>
              <Label required={rest.required} htmlFor={id}>
                {label}
              </Label>
              {/* {tooltip && <Tooltip title='Random message!' />} */}
            </div>
          )}
          <InputDiv hasError={hasError} style={inputContainerStyle}>
            {rightIcon && (
              <Box
                sx={{
                  paddingRight: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'rgba(0, 0, 0, 0.54)',
                }}
              >
                {rightIcon}
              </Box>
            )}
            {!textarea && mask && (
              <CustomInput
                id={id}
                {...(rest as InputElementProps)}
                {...((fieldProps?.field ?? {}) as InputElementProps)}
                onChange={(e: any) => {
                  const restInputProps = (rest as InputElementProps)

                  if(fieldProps?.field) {
                    fieldProps?.field.onChange(e)
                  }

                  if(restInputProps.onChange) {
                    restInputProps.onChange(e)
                  }
                }}
                error={hasError}
                // helperText={formikField?.meta?.error}
                InputProps={{
                  inputComponent: TextMask as any,
                  inputProps: {
                    ...(Array.isArray(mask) ? { mask } : { ...mask }),
                    ...((fieldProps?.field as any) ?? {}),
                  },
                  }}
              />
            )}

            {!textarea && !mask && (
              <CustomInput
                // ref={ref as RefObject<HTMLInputElement>}
                id={id}
                error={hasError}
                // helperText={formikField?.meta?.error}
                {...(rest as InputElementProps)}
                {...((fieldProps?.field ?? {}) as InputElementProps)}
                onChange={(event: ChangeEvent<any>) => {
                  const restInputProps = rest as InputElementProps

                  if(fieldProps?.field) {
                    fieldProps?.field.onChange(event)
                  }

                  if(restInputProps.onChange) {
                    restInputProps.onChange(event)
                  }
                }}
              />
            )}

            {textarea && (
              <textarea
                ref={ref as RefObject<HTMLTextAreaElement>}
                id={id}
                rows={1}
                {...(props as TextAreaElementProps)}
                {...((fieldProps?.field ?? {}) as TextAreaElementProps)}
              />
            )}

            {leftIcon && (
              <Box
                sx={{
                  paddingRight: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'rgba(0, 0, 0, 0.54)',
                }}
              >
                {leftIcon}
              </Box>
            )}
          </InputDiv>
          {hasError && (
            <Typography variant='subtitle2' color='red'>
              {fieldProps?.meta?.error}
            </Typography>
          )}
        </div>
      )
    }

    if (formik) {
      return (
        <Field name={id}>
          {(fieldProps: FieldProps) => buildInput(fieldProps)}
        </Field>
      )
    }
    return buildInput()
  },
) as ForwardRefExoticComponent<InputProps & RefAttributes<HTMLInputElement | HTMLTextAreaElement>> &
  InputSubComponents
